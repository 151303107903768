/* React modules */
import { useCallback, useEffect, useState } from 'react';

/* Our modules */
import {
  ActiveWithdrawal,
  WithdrawalError,
  WithdrawalErrors,
  WithdrawalStatuses,
} from 'modules/payment/payment.types';
import { BettingPlace } from 'modules/betting-places/betting-places.types';
import useStores from 'common/hooks/useStores';
import loaderStore from 'components/Loader/loader.store';
import PaymentService from 'modules/payment/payment.service';
import { Button, Input, Select } from 'components';
import { LoaderWrapper } from 'components/Loader';
import CreditDebitInfoBar from 'modules/user/ui/UserCreditDebit/CreditDebitInfoBar';
import CreditDebitAmountPicker from 'modules/user/ui/UserCreditDebit/CreditDebitAmountPicker';
import CancelWithdrawalConfirm from 'modules/payment/ui/CancelWithdrawalConfirm';
import overlayStore from 'libs/overlay-store';
import { logger } from 'libs/common-helpers';
import '../Credit.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const WithdrawModal = observer(
  ({
    onSuccess,
    selectedAmount,
    minCreditBettingPlace,
    maxCreditBettingPlace,
  }: {
    onSuccess: () => void;
    selectedAmount: number;
    minCreditBettingPlace: number;
    maxCreditBettingPlace: number;
  }) => {
    const { t } = useTranslation();
    const { userStore, bettingPlacesStore } = useStores();
    const paymentService = new PaymentService();

    const [amount, setAmount] = useState(selectedAmount);
    const [amountError, setAmountError] = useState<string | null>(null);
    const [bettingPlaces, setBettingPlaces] = useState<any>({});
    const [addresses, setAddresses] = useState<any[]>([]);
    const [selectedCity, setSelectedCity] = useState<any>(null);
    const [selectedBettingPlace, setSelectedBettingPlace] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const { username, first_name, last_name } = userStore.user || {};

    const sortBettingPlaces = (bettingPlaces: any) => {
      return Object.keys(bettingPlaces)
        .sort()
        .reduce((result: any, key) => {
          result[key] = bettingPlaces[key];
          return result;
        }, {});
    };

    useEffect(() => {
      const bettingPlacesOptions =
        bettingPlacesStore.getBettingPlacesByMunicipalities();
      const sortedBettingPlaces = sortBettingPlaces(bettingPlacesOptions);
      setBettingPlaces(sortedBettingPlaces);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (selectedCity) {
        setSelectedBettingPlace(null);
        setAddresses(
          bettingPlaces[selectedCity.value].filter(
            (address: any) => !address.isTesting
          )
        );
      }
    }, [selectedCity, bettingPlaces]);

    useEffect(() => {
      if (Number(amount) < minCreditBettingPlace) {
        setAmountError(
          t('validations.min-credit', { min: minCreditBettingPlace })
        );
      } else if (Number(amount) > maxCreditBettingPlace) {
        setAmountError(
          t('validations.max-credit', { max: maxCreditBettingPlace })
        );
      } else {
        setAmountError(null);
      }
    }, [amount]);

    const getCitiesOptions = () => {
      return Object.keys(bettingPlaces).map((place: string) => ({
        label: place,
        value: place,
      }));
    };

    const sortAdresses = (adressOptions: any) => {
      return adressOptions.sort((prevAdress: any, nextAdress: any) => {
        // use localeCompare to sort combined strings and numbers
        return prevAdress.label.localeCompare(nextAdress.label, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
    };

    const formatName = (name: string) => {
      if (name.substr(-1) === 'A') {
        name = name.slice(0, -1);
      }
      const extractDigit = name.replace(/[^0-9]/g, '');
      if (extractDigit.length < 2) {
        name = name.replace(/[0-9]/g, '');
        name = name + '0' + extractDigit;
      }

      return name;
    };

    const getAddressesOptions = () => {
      const adressOptions = addresses.map(({ id, address, name }: any) => ({
        value: id,
        label: `${formatName(name)} - ${address}`,
      }));
      return sortAdresses(adressOptions);
    };

    const setAmountHandler = (e: any) => {
      setAmount(Number(e.target.value));
    };

    const handleWithdrawalError = (error: string | WithdrawalError) => {
      if (error?.includes('ERR_AMOUNT_OVER_EXCEEDED:')) {
        setError(
          t('errors.withdrawal-max-amount-exceeded', { a: error.split(':')[1] })
        );
      } else if (error?.includes('ERR_AMOUNT_UNDER_ALLOWED:')) {
        setError(
          t('errors.withdrawal-min-amount-exceeded', { a: error.split(':')[1] })
        );
      } else if (error === WithdrawalErrors.NOT_ENOUGH_FUNDS) {
        setError(t('validations.validation-balance'));
      } else if (error === WithdrawalErrors.USER_BLOCKED) {
        setError(t('errors.user-blocked-error'));
      } else if (error === WithdrawalErrors.USER_NOT_VALIDATED) {
        setError(t('validations.validation-not-validated'));
      } else if (error === WithdrawalErrors.TEST_USERS_CANNOT_WITHDRAW_MONEY) {
        setError(
          t(`errors.${WithdrawalErrors.TEST_USERS_CANNOT_WITHDRAW_MONEY}`)
        );
      } else {
        setError(t('errors.system-error'));
      }
    };

    const submitWithdrawal = async () => {
      loaderStore.addLoader('withdraw-betting-place');
      setError(null);

      try {
        await paymentService.submitWithdrawal({
          amount,
          betting_place_id: selectedBettingPlace.value,
          username: username,
          first_name,
          last_name,
        });
        toast.success(t('payments.withdrawal-success'));
        onSuccess();
        overlayStore.closeModal();
      } catch (exception: any) {
        handleWithdrawalError(exception.detail);

        logger('WithdrawModal -> submitWithdrawal -> exception', exception);
      } finally {
        loaderStore.removeLoader('withdraw-betting-place');
      }
    };

    /* const selectInputValue = (e: any) => {
    // @ts-ignore
    document.getElementById("amount").select();
  } */

    return (
      <div className="bg-black-400 pb-10">
        <section className="py-6 bg-black">
          <h2 className="text-center sb-heading-large">
            {t('payments.withdraw-on-the-betting-place')}
          </h2>
        </section>
        <LoaderWrapper name="withdraw-betting-place">
          <section>
            <div className="text-center py-4">
              <h5 className="heading-m">
                {t('payments.funds-available-for-payment')}
              </h5>
              <p className="sb-heading-large text-green-200 mt-1">
                {userStore.standardWallet?.availableFunds}
              </p>
            </div>
          </section>
          <section>
            <div className="mx-12">
              <Select
                className="mb-1"
                options={getCitiesOptions()}
                bg="grey"
                size="regular"
                placeholder={t('globals.choose-a-city')}
                value={selectedCity}
                shape="squared"
                onChange={setSelectedCity}
              />
            </div>
            <div className="mx-12 mt-8">
              <Select
                className="mb-1"
                options={getAddressesOptions()}
                bg="grey"
                size="regular"
                placeholder={t('globals.choose-a-betting-place')}
                value={selectedBettingPlace}
                shape="squared"
                onChange={setSelectedBettingPlace}
              />
            </div>
            <div className="mx-12 mt-8">
              <Input
                className="w-100 custom_amount__input mt-1 mr-1"
                type="number"
                name="amount-custom-input"
                height="large"
                bg="light"
                onChange={setAmountHandler}
                selectOnFocus
                error={amountError || undefined}
                value={amount}
              />
            </div>

            <div className="d-flex mx-12 mt-3">
              <span className="text-red">{error}</span>
            </div>

            <div className="text-center mt-8">
              <Button
                className="__btn mt-1 w-40"
                style={{ color: 'black' }}
                size="large"
                bg="warning"
                disabled={!!amountError || !selectedBettingPlace}
                onClick={submitWithdrawal}
              >
                {t('globals.submit')}
              </Button>
            </div>
          </section>
        </LoaderWrapper>
      </div>
    );
  }
);

const OfficeCredit = observer(() => {
  const { t } = useTranslation();
  const { overlayStore, userStore, bettingPlacesStore } = useStores();
  const paymentService = new PaymentService();

  const [activeWithdrawalBettingPlace, setActiveWithdrawalBettingPlace] =
    useState<BettingPlace | null>(null);
  const [activeWithdrawal, setActiveWithdrawal] =
    useState<ActiveWithdrawal | null>(null);
  const [maxCreditBettingPlace, setMaxCreditBettingPlace] = useState<any>(null);
  const [minCreditBettingPlace, setMinCreditBettingPlace] = useState<any>(null);

  const getActiveWithdrawal = () => {
    paymentService.getWithdrawalReservation().then(({ data }: any) => {
      setActiveWithdrawal(data);
    });
  };

  const getMinMaxWithdrawal = async () => {
    const { data } = await paymentService.getMinMaxWithdrawal();
    if (data) {
      setMinCreditBettingPlace(data.min_lb_payment);
      setMaxCreditBettingPlace(data.max_lb_payment);
    }
  };

  useEffect(() => {
    getMinMaxWithdrawal();
  }, []);

  const onWithdrawalSuccess = () => {
    userStore.getUserData();
    getActiveWithdrawal();
  };

  const withdrawModal = (
    amount: number,
    minCreditBettingPlace: number,
    maxCreditBettingPlace: number
  ) => {
    overlayStore.openModal(
      <WithdrawModal
        selectedAmount={amount}
        onSuccess={onWithdrawalSuccess}
        minCreditBettingPlace={minCreditBettingPlace}
        maxCreditBettingPlace={maxCreditBettingPlace}
      />,
      {
        width: 'medium',
      }
    );
  };

  const init = useCallback(async () => {
    await bettingPlacesStore.getBettingPlaces();
    getActiveWithdrawal();
  }, [bettingPlacesStore, getActiveWithdrawal]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeWithdrawal && bettingPlacesStore.bettingPlaces?.length) {
      const bettingPlace = bettingPlacesStore.getBettingPlace(
        activeWithdrawal.betting_place_id
      );
      setActiveWithdrawalBettingPlace(bettingPlace);
    }
  }, [activeWithdrawal, bettingPlacesStore, bettingPlacesStore.bettingPlaces]);

  const handleCancelWithdrawalError = (error: any) => {
    if (error?.detail === WithdrawalErrors.NOT_FOUND) {
      toast.error(t('errors.withdrawal-canceled-or-paid'));
    } else {
      toast.error(t('errors.system-error'));
    }
  };

  const confirmCancelWithdrawal = async () => {
    try {
      await paymentService.cancelWithdrawal();
      toast.success(t('payments.cancel-withdrawal-success'));
      setActiveWithdrawal(null);
      userStore.getUserData();
    } catch (exception: any) {
      handleCancelWithdrawalError(exception);

      logger('WithdrawModal -> submitWithdrawal -> exception', exception);
    }
  };

  const cancelWithdrawal = () => {
    overlayStore.openModal(
      <CancelWithdrawalConfirm onConfirm={confirmCancelWithdrawal} />,
      { width: 'medium' }
    );
  };

  const getStatusString = () => {
    if (!activeWithdrawal) return '';

    if (
      [WithdrawalStatuses.APPROVED, WithdrawalStatuses.PENDING].includes(
        activeWithdrawal.status
      )
    ) {
      return t('payments.withdrawal-pending').toUpperCase();
    }

    return t('payments.withdrawal-ready').toUpperCase();
  };

  return (
    <div>
      <div className="mt-8">
        <CreditDebitInfoBar
          min={minCreditBettingPlace}
          max={maxCreditBettingPlace}
          isDebit={false}
        />
      </div>
      <div className="d-flex justify-end">
        <div className="credit__amount px-10 py-10">
          <p className="text-white d-flex justify-end">
            {t('payments.funds-that-can-be-raised')}
          </p>
          <p className="text-green-200 d-flex justify-end mt-3">
            {userStore.standardWallet?.availableFunds}
          </p>
        </div>
      </div>

      {activeWithdrawal && (
        <div className="w-100 bg-grey-800 py-5 d-flex justify-center">
          <div className="text-white text-regular" style={{ width: '350px' }}>
            <h3 className="heading-m mb-4">
              {t('payments.withdrawal-reserved').toUpperCase()}
            </h3>

            <div className="d-flex mb-4">
              <span className="w-50">{t('slips.status')}:</span>
              <span className="text-yellow text-strong">
                {getStatusString()}
              </span>
            </div>

            <div className="d-flex mb-4">
              <span className="w-50">{t('userData.place')}:</span>
              <span>{activeWithdrawalBettingPlace?.municipalityName}</span>
            </div>

            <div className="d-flex mb-4">
              <span className="w-50">{t('userData.address')}:</span>
              <span>{activeWithdrawalBettingPlace?.address}</span>
            </div>

            <div className="d-flex mb-4">
              <span className="w-50">{t('userData.code')}:</span>
              <span>{activeWithdrawal?.code}</span>
            </div>

            <div className="d-flex mb-4">
              <span className="w-50">{t('payments.amount')}:</span>
              <span className="text-yellow text-strong">
                {activeWithdrawal?.amount.toFixed(2)}
              </span>
            </div>

            <Button bg="success" onClick={cancelWithdrawal}>
              {t('payments.cancel-withdrawal').toUpperCase()}
            </Button>
          </div>
        </div>
      )}

      {!activeWithdrawal && (
        <div>
          <CreditDebitAmountPicker
            min={minCreditBettingPlace}
            max={maxCreditBettingPlace}
            isDebit={false}
            onSubmit={(amount: number) =>
              withdrawModal(
                amount,
                minCreditBettingPlace,
                maxCreditBettingPlace
              )
            }
            buttonText={t('payments.withdraw')}
          />
        </div>
      )}
    </div>
  );
});

export default OfficeCredit;
