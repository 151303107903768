/* React modules */
import { useState, createContext, useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { EventsSort } from 'modules/offer/store/offer.sort';
import {
  EVENT_FILTER,
  EVENT_FILTER_OPTIONS,
  TIME_FILTER_OPTIONS,
} from 'modules/offer/store/offer.filters';
import { appService } from 'app/app-service';
import SportNode from 'modules/sports/services/sport-node.service';
import offerEvents from 'modules/offer/services/offer-events.service';
import { Icon, Sidebar, ButtonGroup } from 'components';
import PromoBanner from 'components/PromoBanner';
import EventAllOddsOverlay from 'pages/sport/EventAllOddsOverlay';
import { Option } from 'components/ButtonGroup/ButtonGroup';
import { LoaderWrapper } from 'components/Loader';
import Responsive from 'components/Responsive';
import { Devices } from 'components/Responsive/Responsive';
import SportsSidebar from 'pages/sport/SportsSidebar';
import SportsFilterMobile from 'pages/sport/SportsFilterMoblie';
import TimeFilter from 'pages/sport/TimeFilter';
import EventFilter from 'pages/sport/EventFilter';
import EventSearchModal from 'pages/sport/EventSearchModal';
import TicketSidebar from 'modules/ticket/ui/TicketSidebar';
import TicketStatus from 'modules/ticket/ui/TicketStatus';
import OfferMobile from 'modules/offer/ui/OfferMobile';
import Offer from 'modules/offer/ui/Offer';
import useQuery from 'libs/useQuery';
// import 'flag-icon-css/css/flag-icons.min.css';
import './Sport.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const OverlayContext = createContext<any>(null);
export const TicketPreviewContext = createContext<any>(null);
export const QuickTicketPreviewContext = createContext<any>(null);

const Sport = observer(() => {
  const { t } = useTranslation();

  const {
    offerStore,
    offerFilters,
    offerSort,
    overlayStore,
    sportsStore,
    ticketsStore: { getSharedTicket },
  } = useStores();

  const [allOddsVisibility, setAllOddsVisibility] = useState(false);
  const [allOddsData, setAllOddsData] = useState<any>(null);

  const navigate = useNavigate();
  let queryParams = useQuery();

  const sortOptions: Option[] = [
    {
      tooltip: t('sportsPage.sort-by-time'),
      value: EventsSort.TIME,
      icon: 'clock',
    },
    {
      tooltip: t('sportsPage.sort-by-competition'),
      value: EventsSort.COMPETITION,
      icon: 'trophy',
    },
  ];

  const openTicketPreview = () => {
    overlayStore.openModal(<TicketStatus />, {
      width: 'large',
      className: 'visibe-tablet-down',
      wrapClassName: 'ticket-preview-modal-wrap',
      name: 'ticket-preview',
    });
  };

  const getEventTypeQuery = () => {
    const eventFilter = queryParams.get('event_type');
    return eventFilter ? `&event_type=${eventFilter}` : '';
  };

  const openAllOddsOverlay = (event: any) => {
    navigate({
      pathname: '/sport',
      search: `?event=${event?.event?.id}${getEventTypeQuery()}`,
    });
    setAllOddsVisibility(true);
    setAllOddsData(event);
  };

  const closeAllOddsOverlay = () => {
    navigate({
      pathname: '/sport',
      search: getEventTypeQuery(),
    });
    setAllOddsVisibility(false);
    setAllOddsData(null);
  };

  const openAllOddsFromParams = (eventId: string) => {
    sportsStore.sportsData?.sportsList.every((sportNode: SportNode) => {
      const [event] = sportNode.getEvents((event) => {
        return eventId === event.id;
      });
      if (event) {
        openAllOddsOverlay({ event: event });
        return false;
      }
      return true;
    });
  };

  const onEventFilterChange = (filter: any) => {
    offerStore.setActiveGroup(null);
    const eventParam = queryParams.get('event');
    const eventQuery = eventParam ? `&event=${eventParam}` : '';

    navigate({
      pathname: '/sport',
      search: `?event_type=${filter}${eventQuery}`,
    });
  };

  useEffect(() => {
    if (!offerStore.isAppSetup) {
      offerStore.increaseAppSetupCount();
      appService.setup();
    }
  });

  useEffect(() => {
    if (sportsStore.allEventsLoaded && sportsStore.sportsLoaded) {
      const eventId = queryParams.get('event');

      if (eventId) {
        openAllOddsFromParams(eventId);
      }

      const ticketId = queryParams.get('ticket-share');

      if (ticketId) {
        getSharedTicket(ticketId).then(() => {
          overlayStore.openModal(
            <TicketStatus isSharedTicket allTicketsButton={false} />,
            {
              width: 'large',
              className: 'visibe-tablet-down',
              wrapClassName: 'ticket-preview-modal-wrap',
              name: 'ticket-preview',
              onClose: () => navigate('/sport'),
            }
          );
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsStore.allEventsLoaded, sportsStore.sportsLoaded]);

  useEffect(() => {
    const eventFilter = queryParams.get('event_type') as EVENT_FILTER;

    if (eventFilter && EVENT_FILTER_OPTIONS.includes(eventFilter)) {
      offerFilters.setEventFilter(eventFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const allEvents: any = [];
  offerStore.offer.forEach((types: any, i: number) => {
    types.events.forEach((event: any) => {
      allEvents.push(event);
    });
  });

  const openSearchModal = () => {
    overlayStore.openModal(
      <OverlayContext.Provider value={openAllOddsOverlay}>
        <EventSearchModal />
      </OverlayContext.Provider>,
      {
        className: '',
        wrapClassName: '',
        width: 'medium',
      }
    );
  };

  return (
    <div className="sport">
      <div className="bordered-right">
        <Responsive
          className="sidebar-sports d-flex flex-column"
          visibleFrom={Devices.DESKTOP}
        >
          <TimeFilter
            filters={TIME_FILTER_OPTIONS}
            selected={offerFilters.timeFilter}
            onChange={offerFilters.setTimeFilter}
          />
          <SportsSidebar />
        </Responsive>
      </div>

      <div className="sport__content">
        <PromoBanner />
        <div className="sport__filter-bar">
          <Responsive
            display="block"
            className="sport__filter-bar-items"
            visibleTo={Devices.LAPTOP}
          >
            <div className="d-flex align-items space-between">
              <SportsFilterMobile />

              {offerFilters.eventFilter !== 'antepost' && (
                <div className="ml-auto mr-5">
                  <Icon name="search" size="medium" onClick={openSearchModal} />
                </div>
              )}

              <div>
                <ButtonGroup
                  size="regular"
                  testId="event-sort"
                  selected={String(offerSort.activeSort)}
                  options={sortOptions}
                  type="icon"
                  onSelect={offerSort.setSort}
                />
              </div>
              {/* <Icon
                className="ml-2"
                name="configuration"
                container="sbgrey"
                isDisabled={true}
                onClick={openEventMarkets}
              /> */}
            </div>
          </Responsive>
          <div className="d-flex space-between align-items sport__filter-wrapper">
            <OverlayContext.Provider value={openAllOddsOverlay}>
              <Responsive
                fromDesktop={
                  <EventFilter
                    filters={EVENT_FILTER_OPTIONS}
                    selected={offerFilters.eventFilter}
                    onChange={onEventFilterChange}
                    eventsCount={offerEvents.getEventsCount(
                      offerFilters.activeSportNodes,
                      offerStore.offerState
                    )}
                  />
                }
                toLaptop={
                  <EventFilter
                    filters={EVENT_FILTER_OPTIONS}
                    selected={offerFilters.eventFilter}
                    onChange={onEventFilterChange}
                    eventsCount={offerEvents.getEventsCount(
                      offerFilters.selectedSport
                        ? [offerFilters.selectedSport]
                        : [],
                      offerStore.offerState
                    )}
                  />
                }
              />
              {offerFilters.eventFilter !== 'antepost' && (
                <div className="sport__search mr-5">
                  <Icon name="search" size="medium" onClick={openSearchModal} />
                </div>
              )}

              <Responsive className="sport__sort" visibleFrom={Devices.DESKTOP}>
                <ButtonGroup
                  size="regular"
                  testId="event-sort"
                  selected={String(offerSort.activeSort)}
                  options={sortOptions}
                  type="icon"
                  onSelect={offerSort.setSort}
                />
              </Responsive>
            </OverlayContext.Provider>
          </div>
        </div>

        <div className="sport__table-wrapper">
          <OverlayContext.Provider value={openAllOddsOverlay}>
            <LoaderWrapper name="sports">
              <Responsive
                containerClassName="max-height"
                className="max-height"
                toLaptop={<OfferMobile />}
                fromDesktop={<Offer tables={offerStore.offer} />}
              />

              <EventAllOddsOverlay
                visible={allOddsVisibility}
                data={allOddsData}
                close={closeAllOddsOverlay}
              />
            </LoaderWrapper>
          </OverlayContext.Provider>
        </div>
      </div>

      <TicketPreviewContext.Provider value={openTicketPreview}>
        <div className="bordered-left">
          <OverlayContext.Provider value={openAllOddsFromParams}>
            <Responsive
              fromTablet={
                <Sidebar>
                  <TicketSidebar />
                </Sidebar>
              }
            />
          </OverlayContext.Provider>
        </div>
      </TicketPreviewContext.Provider>
    </div>
  );
});

export { Sport };
