/**
 * @fileoverview gRPC-Web generated client stub for web_odds
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_odds_web_odds_pb from '../../proto/odds/web_odds_pb';


export class WebOddsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetInitialData = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.InitialDataResponse,
    (request: proto_odds_web_odds_pb.InitialDataRequest) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.InitialDataResponse.deserializeBinary
  );

  getInitialData(
    request: proto_odds_web_odds_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.InitialDataResponse>;

  getInitialData(
    request: proto_odds_web_odds_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.InitialDataResponse) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.InitialDataResponse>;

  getInitialData(
    request: proto_odds_web_odds_pb.InitialDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.InitialDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetInitialData',
        request,
        metadata || {},
        this.methodInfoGetInitialData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetInitialData',
    request,
    metadata || {},
    this.methodInfoGetInitialData);
  }

  methodInfoEventsStream = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.EventStream,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.EventStream.deserializeBinary
  );

  eventsStream(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/web_odds.WebOddsService/EventsStream',
      request,
      metadata || {},
      this.methodInfoEventsStream);
  }

  methodInfoEventsStreamString = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.EventStreamString,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.EventStreamString.deserializeBinary
  );

  eventsStreamString(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata) {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/web_odds.WebOddsService/EventsStreamString',
      request,
      metadata || {},
      this.methodInfoEventsStreamString);
  }

  methodInfoGetSingleEvent = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.WebStreamEvent,
    (request: proto_odds_web_odds_pb.WebStreamEvent) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.WebStreamEvent.deserializeBinary
  );

  getSingleEvent(
    request: proto_odds_web_odds_pb.WebStreamEvent,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.WebStreamEvent>;

  getSingleEvent(
    request: proto_odds_web_odds_pb.WebStreamEvent,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.WebStreamEvent) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.WebStreamEvent>;

  getSingleEvent(
    request: proto_odds_web_odds_pb.WebStreamEvent,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.WebStreamEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetSingleEvent',
        request,
        metadata || {},
        this.methodInfoGetSingleEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetSingleEvent',
    request,
    metadata || {},
    this.methodInfoGetSingleEvent);
  }

  methodInfoGetLiveDisabled = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.LiveDisabled,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.LiveDisabled.deserializeBinary
  );

  getLiveDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.LiveDisabled>;

  getLiveDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.LiveDisabled) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.LiveDisabled>;

  getLiveDisabled(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.LiveDisabled) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetLiveDisabled',
        request,
        metadata || {},
        this.methodInfoGetLiveDisabled,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetLiveDisabled',
    request,
    metadata || {},
    this.methodInfoGetLiveDisabled);
  }

  methodInfoGetPlayerOutrightMarkets = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.PlayerOutrightMarkets,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.PlayerOutrightMarkets.deserializeBinary
  );

  getPlayerOutrightMarkets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.PlayerOutrightMarkets>;

  getPlayerOutrightMarkets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.PlayerOutrightMarkets) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.PlayerOutrightMarkets>;

  getPlayerOutrightMarkets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.PlayerOutrightMarkets) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetPlayerOutrightMarkets',
        request,
        metadata || {},
        this.methodInfoGetPlayerOutrightMarkets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetPlayerOutrightMarkets',
    request,
    metadata || {},
    this.methodInfoGetPlayerOutrightMarkets);
  }

  methodInfoGetMissingCompetition = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.Competition,
    (request: proto_odds_web_odds_pb.CompetitionId) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.Competition.deserializeBinary
  );

  getMissingCompetition(
    request: proto_odds_web_odds_pb.CompetitionId,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.Competition>;

  getMissingCompetition(
    request: proto_odds_web_odds_pb.CompetitionId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.Competition) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.Competition>;

  getMissingCompetition(
    request: proto_odds_web_odds_pb.CompetitionId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.Competition) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetMissingCompetition',
        request,
        metadata || {},
        this.methodInfoGetMissingCompetition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetMissingCompetition',
    request,
    metadata || {},
    this.methodInfoGetMissingCompetition);
  }

  methodInfoGetLandingPage = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.LandingPage,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.LandingPage.deserializeBinary
  );

  getLandingPage(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.LandingPage>;

  getLandingPage(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.LandingPage) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.LandingPage>;

  getLandingPage(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.LandingPage) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetLandingPage',
        request,
        metadata || {},
        this.methodInfoGetLandingPage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetLandingPage',
    request,
    metadata || {},
    this.methodInfoGetLandingPage);
  }

  methodInfoGetFeaturedEvents = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_odds_web_odds_pb.FeaturedEvents,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_odds_web_odds_pb.FeaturedEvents.deserializeBinary
  );

  getFeaturedEvents(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_odds_web_odds_pb.FeaturedEvents>;

  getFeaturedEvents(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.FeaturedEvents) => void): grpcWeb.ClientReadableStream<proto_odds_web_odds_pb.FeaturedEvents>;

  getFeaturedEvents(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_odds_web_odds_pb.FeaturedEvents) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/web_odds.WebOddsService/GetFeaturedEvents',
        request,
        metadata || {},
        this.methodInfoGetFeaturedEvents,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/web_odds.WebOddsService/GetFeaturedEvents',
    request,
    metadata || {},
    this.methodInfoGetFeaturedEvents);
  }

}

