/* React modules */

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import Event from 'modules/sports/store/event.store';
import Result from 'modules/offer/ui/Offer/Result';
import { Icon } from 'components';
import { formatDate, toDate } from 'libs/datetime';
import './EventHeader.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface EventHeaderProps {
  event: Event;
  close: () => void;
  openMatchStats: () => void;
}

const EventHeader = ({ event, close, openMatchStats }: EventHeaderProps) => {
  const {
    sportName,
    home,
    away,
    type,
    periods,
    eventTime,
    day,
    englishDay,
    start,
    competitionName,
    currentResult,
    betradarId,
  } = event;
  const { i18n } = useTranslation();
  const { feedStore, ticketBuilder } = useStores();
  const { events: slipEvents } = ticketBuilder;

  const closeEventAllOddsOverlay = () => {
    const targetEvent = slipEvents.find((e: any) => e.id === event.id);
    if (!targetEvent) {
      feedStore.unsubscribeEvent(event.id);
    }
    close();
  };

  return (
    <div
      className={`event-header event-header__background-${sportName
        .trim()
        .replace(' ', '_')
        .toLowerCase()}`}
    >
      <div className="w-32">
        <div className="event-header__row">
          <div className="text-ellipsis sb-text-small text-orange-200">
            {competitionName}
          </div>
        </div>

        <div className="event-header__row home">
          <div className="text-ellipsis">{home}</div>
        </div>

        <div className="event-header__row">
          <div className="text-ellipsis">{away}</div>
        </div>
      </div>
      {type === 'live' && event.sportId !== 28 && (
        <div className="w-40 event-header__full-result">
          <Result current={currentResult} periods={periods} displayHeader />
        </div>
      )}

      {type === 'live' && event.sportId === 28 && (
        <div className="w-48 event-header__full-result">
          <Result current={currentResult} periods={periods} displayHeader />
        </div>
      )}
      <div className="d-flex align-start justify-end w-20 ml-auto pl-5 event-header__time">
        {type === EVENT_TYPE.UPCOMING ? (
          <div className="d-flex space-between flex-column">
            <span />
            <span>
              {i18n.language === 'me' ? day : englishDay}{' '}
              {formatDate(toDate(start.seconds))}
            </span>
            <span>{eventTime}</span>
          </div>
        ) : (
          <div className="d-flex event-header__time-live">{eventTime}</div>
        )}

        <div className="ml-5">
          <Icon
            className="mr-1"
            onClick={closeEventAllOddsOverlay}
            name="cancel"
          />
          {/*betradar statistic */}
          {betradarId && betradarId !== '' && (
            <Icon
              className="mt-7 ml-1 visible-desktop-down"
              onClick={openMatchStats}
              size="regular"
              name="stats"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(EventHeader);
