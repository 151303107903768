/* React modules */
import { useCallback, useEffect, useState } from 'react';

/* Our modules */
import { UserStatuses } from 'modules/user/user.models';
import { PaymentMethod, PaymentMethods } from 'modules/payment/payment.types';
import useStores from 'common/hooks/useStores';
import PaymentService from 'modules/payment/payment.service';
import PaymentResponse from 'modules/payment/payment-response.service';
import SectionHeader from 'modules/user/ui/SectionHeader';
import CreditDebitFilter from 'modules/user/ui/UserCreditDebit/CreditDebitFilter';
import CreditCard from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Debit/CreditCard';
import VerificationModal from 'modules/user/ui/VerificationModal';
import { initMonriForm } from 'modules/payment/ui/monri';
import { setGoogleAnalyticsEvent } from 'libs/analytics';
import useQuery from 'libs/useQuery';
import { parseJSONString } from 'libs/common-helpers';
import '../CreditDebitBody.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Debit = observer(() => {
  const { t } = useTranslation();
  const paymentService = new PaymentService();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [depositMethod, setDepositMethod] = useState<PaymentMethod | null>(
    null
  );
  const [depositData, setDepositData] = useState<any>(null);

  const navigate = useNavigate();
  let queryParams = useQuery();

  const onSelect = (tabId: number) => {
    setActiveTab(tabId);
  };

  const { authStore, userStore, overlayStore } = useStores();

  const tabs = [{ label: t('payments.credit-card'), value: 0 }];

  const initDeposit = async (amount: number, method?: PaymentMethod) => {
    const depositData = await paymentService.initDeposit(amount, method);

    if (depositData) {
      setDepositMethod(method || null);
      setDepositData(depositData);
    }

    return depositData;
  };

  const getResponseData = useCallback(() => {
    const transactionResponse = queryParams.get('transaction_response');

    if (transactionResponse) {
      setGoogleAnalyticsEvent('Payment success', 'Payment success');

      return parseJSONString(transactionResponse);
    }
  }, [queryParams]);

  const onResponseHandled = useCallback(() => {
    queryParams.delete('transaction_response');
    navigate({ search: queryParams.toString() });
  }, [navigate, queryParams]);

  useEffect(() => {
    if (
      authStore.isLoggedIn &&
      UserStatuses.ACTIVATED === userStore.user?.status
    ) {
      overlayStore.openModal(<VerificationModal />, { width: 'small' });
    }
  }, []);

  useEffect(() => {
    if (queryParams) {
      const responseData = getResponseData();

      if (responseData) {
        const responseHandler = new PaymentResponse(
          PaymentMethods.MONRI,
          responseData
        );
        responseHandler.handle(
          (messageKey: string) => toast.success(t(messageKey)),
          (messageKey: string) => toast.error(t(messageKey))
        );
        onResponseHandled();
      }
    }
  }, [queryParams, getResponseData, onResponseHandled, t]);

  useEffect(() => {
    if (depositMethod && depositData) {
      initMonriForm(document.getElementById('monri-payment-form'), depositData);
    }
  }, [depositMethod, depositData]);

  return (
    <div className="user-credit-debit">
      <SectionHeader title={t('payments.debit')} />

      <div className="user-credit-debit__header">
        <CreditDebitFilter tabs={tabs} onTabSelected={onSelect} />
      </div>

      <div className="user-credit-debit__body w-100">
        {activeTab === 0 && <CreditCard onDepositInit={initDeposit} />}
      </div>
    </div>
  );
});

export default Debit;
