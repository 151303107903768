/* React modules */
import { useCallback, useEffect, useRef, useState } from 'react';

/* Our modules */
import 'react-toastify/dist/ReactToastify.css';
import 'flag-icon-css/css/flag-icons.min.css';
import 'rc-tooltip/assets/bootstrap.css';
import './App.scss';
import 'app/localization/i18n';
import { offerRoutes, mobileFooterRoutes } from 'Routes';
import useStores from 'common/hooks/useStores';
import { appService } from 'app/app-service';
import Header from 'components/Header';
import Nav from 'components/Nav';
import Drawer from 'components/Drawer';
import NewPassword from 'modules/auth/ui/ResetPassword/NewPassword';
import Modal from 'components/Modal';
import HeaderControls from 'components/HeaderControls';
import Sport from 'pages/sport';
import UserProfile from 'pages/user-profile';
import Loader from 'components/Loader';
import Gambling from 'pages/gambling';
import GamblingGamePlaceholder from 'pages/gambling-game-placeholder';
import QuickGames from 'pages/quick-games';
import Aviator from 'pages/aviator';
import QuickGamesPlaceholder from 'pages/quick-games-placeholder';
import BonusActivation from 'modules/wallet/ui/BonusActivation';
import TicketStatus from 'modules/ticket/ui/TicketStatus';
import MobileFooter from 'components/MobileFooter';
import VerificationSuccessModal from 'modules/user/ui/VerificationSuccessModal';
import PaymentMonriPlaceholder from 'modules/payment/ui/PaymentMonriPlaceholder';
import BankCreditPlaceholder from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Credit/BankCreditPlaceholder';
import CookiesBanner from 'components/CookiesBanner';
import feedStore from 'modules/feed/feed.store';
import AppIdle from 'components/AppIdle';
import LogateChat from 'components/LogateChat/LogateChat';
import CashDrop from 'modules/user/ui/CashDrop';
import { CashDropStatus } from 'modules/user/cash-drop.types';
import Register from 'modules/auth/ui/Register';
import Login from 'modules/auth/ui/Login/Login';
import { Button, Icon } from 'components';
import Home from 'pages/home/Home';
import { profileRoutes } from 'pages/user-profile/UserProfile';
import {
  TicketOverview,
  UserPromoCode,
  UserTransactions,
  UserSettings,
} from 'modules/user/ui';
import UserMarketHistory from 'modules/user/ui/UserMarket/OrderHistory/OrderHistory';
import UserMarket from 'modules/user/ui/UserMarket/Market/Market';
import MarketCart from 'modules/user/ui/UserMarket/Market/Cart/Cart';
import ChangePassword from 'modules/user/ui/UserSettings/ChangePassword';
import ResponsibleGambling from 'modules/user/ui/UserSettings/ResponsibleGambling/ResponsibleGambling';
import Credit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Credit/Credit';
import Debit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Debit/Debit';
import PokerDebit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Debit/PokerDebit';
import PokerCredit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Credit/PokerCredit';
import Verification from 'modules/user/ui/UserSettings/Verification';
import useWindowSize from 'libs/viewport';
import { initializeGoogleAnalytics } from 'libs/analytics';
import useQuery from 'libs/useQuery';
import { isNumber } from 'libs/common-helpers';

/* 3rd Party modules */
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { SpinnerCircularSplit } from 'spinners-react';

const GamblingLive = () => <Gambling live />;
const GamblingMobile = () => <Gambling mobile />;
const GamblingMobileLive = () => <Gambling mobile live />;
const QuickGamesListMobile = () => <QuickGames mobile />;

const App = observer(() => {
  const { t } = useTranslation();

  const {
    loaderStore: { isActive },
    authStore: { isLoggedIn, loginSuccess, activateAccount },
    marketStore: { fetchLoyalty, fetchLoyaltyConfig, getLoyalty },
    cashDropStore: { getCashDrops, getCashDropStatus, cashDropStatus },
    userStore,
    overlayStore,
    ticketsStore,
    sportsStore,
  } = useStores();

  const [cashDropLoading, setCashDropLoading] = useState(false);

  const { width } = useWindowSize();

  const disabledMobileFooterRoutes = [
    '/gambling-game',
    '/quick-games-mobile',
    '/monri-payment',
    '/bank-credit-mobile',
    '/gambling-mobile',
    '/gambling-mobile-live',
    '/quick-games-list-mobile',
  ];

  const disabledMobileHeaderRoutes = ['/zendesk-chat'];

  const disabledMobileSidebarRoutes = [
    '/gambling-mobile',
    '/gambling-mobile-live',
    '/quick-games-list-mobile',
  ];

  const navigate = useNavigate();
  const { pathname } = useLocation();
  let queryParams = useQuery();
  const [cookiesBanner, setCookiesBanner] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const handleCookiesBanner = () => {
    const cookiesAccepted = localStorage.getItem('cookies_accepted');
    if (cookiesAccepted !== 'true') {
      setCookiesBanner(true);
    }
  };

  const acceptCookies = () => {
    setCookiesBanner(false);
    localStorage.setItem('cookies_accepted', 'true');
  };

  const previewTicket = useCallback(
    async (slipId: string) => {
      ticketsStore.getTicket(slipId).then(() => {
        overlayStore.openModal(<TicketStatus />, {
          width: 'large',
          className: 'visibe-tablet-down',
          wrapClassName: 'ticket-preview-modal-wrap',
          name: 'ticket-preview',
        });
      });
    },
    [overlayStore, ticketsStore]
  );

  const onTicketApproved = () => {
    ticketsStore.manuallyApprovedTickets.forEach(
      async (approvedTicket: any) => {
        const { modalProps } = overlayStore;
        const { ticket } = ticketsStore;
        const isPreviewModalOpen = modalProps?.name === 'ticket-preview';
        const isApprovedTicketOpen =
          isPreviewModalOpen &&
          ticket &&
          ticket.slipId === approvedTicket.slip_id;

        ticketsStore.removeTicketFromApproval(approvedTicket.slip_id);

        if (isApprovedTicketOpen) {
          await previewTicket(approvedTicket.short_uuid);
        }

        toast.success(t('verification.ticket-approved'), {
          onClick: () => previewTicket(approvedTicket.short_uuid),
        });
      }
    );
  };

  const onTicketDenied = () => {
    ticketsStore.manuallyDeniedTickets.forEach((deniedTicket: any) => {
      const { modalProps } = overlayStore;
      const { ticket } = ticketsStore;
      const isPreviewModalOpen = modalProps?.name === 'ticket-preview';
      const isDeniedTicketOpen =
        isPreviewModalOpen && ticket && ticket.slipId === deniedTicket.slip_id;

      ticketsStore.removeTicketFromApproval(deniedTicket.slip_id);

      if (isDeniedTicketOpen) {
        previewTicket(deniedTicket.short_uuid);
      }

      toast.error(t('errors.ticket-denied'), {
        onClick: () => previewTicket(deniedTicket.short_uuid),
      });
    });
  };

  const onTicketChanged = () => {
    ticketsStore.manuallyChangedTickets.forEach(async (ticket: any) => {
      ticketsStore.removeTicketFromApproval(ticket.slip_id);
      toast.info(t('errors.ticket-changed'));
      await previewTicket(ticket.short_uuid);
    });
  };

  const openSideNav = () => {
    overlayStore.openDrawer(<Nav offerRoutes={offerRoutes} />, {
      width: '250px',
      className: 'app-nav-mobile visible-desktop-down',
    });
  };

  const [pauseIdle, setPauseIdle] = useState(false);
  const openRegisterModal = () => {
    setPauseIdle(true);
    overlayStore.openModal(
      <Register continueIdle={() => setPauseIdle(false)} />,
      {
        className: 'register-modal',
        wrapClassName: 'register-modal-wrap',
        width: 'medium',
      }
    );
  };

  const openLoginModal = () => {
    const modalContent = (
      <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
    );

    overlayStore.openModal(modalContent, {
      width: 'small',
    });
  };

  const openCahsDropModal = async () => {
    setCashDropLoading(true);
    await getCashDrops().then(() => {
      const modalContent = <CashDrop />;

      overlayStore.openModal(modalContent, {
        width: 'medium',
        closable: true,
        title: 'CASH DROP',
      });
    });
    setCashDropLoading(false);
  };

  const openVerificationSuccessModal = () => {
    const verificationSuccessModal = <VerificationSuccessModal />;

    overlayStore.openModal(verificationSuccessModal, {
      className: 'register-modal',
      wrapClassName: 'register-modal-wrap',
      width: 'medium',
    });
  };

  useEffect(() => {
    if (isLoggedIn) getCashDropStatus();

    if (disabledMobileSidebarRoutes.includes(pathname)) {
      setIsMobile(true);
    }

    handleCookiesBanner();

    initializeGoogleAnalytics();

    const token = queryParams.get('activation_link') || '';

    if (token) {
      activateAccount(token, () => {
        openVerificationSuccessModal();
      });
    }

    sportsStore.loadPokerConfiguration();

    const handleOffline = () => {
      toast.error(t('errors.connection-offline'));

      feedStore.close();

      appService.disconnectSystemSocket();
    };

    const handleOnline = () => {
      toast.info(t('verification.connection-online'));

      appService.setup();
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  useEffect(() => {
    if (!getLoyalty) {
      fetchLoyalty();
      fetchLoyaltyConfig();
    }
  });

  /*
  useEffect(() => {
    if (sportsStore.forceReload) {
      overlayStore.openModal(<ForceReloadPopup />, {
        width: 'medium',
        closable: false,
      });
    }
  }, [sportsStore.forceReload]);
  */

  useEffect(onTicketApproved, [
    ticketsStore,
    ticketsStore.manuallyApprovedTickets,
    previewTicket,
    overlayStore,
    t,
  ]);

  useEffect(onTicketDenied, [
    ticketsStore,
    ticketsStore.manuallyDeniedTickets,
    previewTicket,
    overlayStore,
    t,
  ]);

  useEffect(onTicketChanged, [
    ticketsStore,
    ticketsStore.manuallyChangedTickets,
    previewTicket,
    t,
  ]);

  useEffect(() => {
    if (loginSuccess) {
      toast.info(t('globals.welcome'));
    }
  }, [loginSuccess, t]);

  useEffect(() => {
    if (userStore.shouldActivateBonus) {
      overlayStore.openModal(<BonusActivation />, {
        closable: false,
        width: 'medium',
      });
    }
  }, [
    userStore.user,
    userStore.wallets,
    userStore.shouldActivateBonus,
    overlayStore,
    isLoggedIn,
  ]);

  const appEl = useRef(null);

  useEffect(() => {
    if (appEl?.current && pathname === '/sport' && !pauseIdle) {
      let idleTime = 0;
      const timerIncrement = () => {
        idleTime = idleTime + 1;
        if (idleTime > 9) {
          feedStore.close();
          overlayStore.openModal(<AppIdle />, {
            width: 'small',
            className: 'visibe-tablet-down',
            wrapClassName: 'ticket-preview-modal-wrap',
            name: 'ticket-preview',
            closable: false,
          });
          idleTime = 0;
        }
      };

      const interval = setInterval(timerIncrement, 60000); // 1 minute

      // @ts-ignore
      appEl.current.onmousemove = function () {
        idleTime = 0;
      };
      // @ts-ignore
      appEl.current.onkeypress = function () {
        idleTime = 0;
      };

      return () => {
        clearInterval(interval);
      };
    }
  }, [appEl, pathname, pauseIdle]);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      if (width > 0) {
        if (width < 1200 && pathname === '/') {
          navigate('/sport');
        }

        const chatElement = document.querySelector('logate-chat');

        if (chatElement && chatElement.shadowRoot) {
          const styleSheet = new CSSStyleSheet();

          styleSheet.replaceSync(
            `.logate-chat-chat-position { display: ${
              width >= 992 ? 'block' : 'none'
            }; }`
          );

          chatElement.shadowRoot.adoptedStyleSheets.push(styleSheet);
        }
      }
    }, 0);

    return () => {
      if (isNumber(timeoutId)) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [width]);

  useEffect(() => {
    if (isLoggedIn) {
      appService.onLogin();
    } else {
      ticketsStore.closeApprovalSocket();

      userStore.clearUser();
    }
  }, [isLoggedIn]);

  return (
    <div className="App" ref={appEl}>
      {!disabledMobileHeaderRoutes.includes(pathname) && (
        <Header
          mobile={isMobile}
          topBarControls={
            isLoggedIn ? (
              <div className="app-header__user-control">
                <HeaderControls />
              </div>
            ) : (
              <>
                <div className="app-header__auth visible-tablet-up">
                  <Button bg="green" onClick={openLoginModal}>
                    <div className="d-flex align-items">
                      <Icon name="signIn" />
                      <span className="ml-2 text-regular text-bold">
                        {t('login.login')}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className="ml-3"
                    bg="orange"
                    onClick={openRegisterModal}
                  >
                    <span className="text-regular text-bold">
                      {t('globals.register')}
                    </span>
                  </Button>
                </div>
              </>
            )
          }
          onBurgerClick={openSideNav}
        >
          <div className="app-header">
            <div className="visible-desktop-up">
              <Nav offerRoutes={offerRoutes} />
            </div>
            {/* Loyalty progress bar */}
            {/*{!pathname.includes('profil') && pointsStatus === 'enabled' && !isDisabledLoyalty && isLoggedIn &&*/}
            {/*  <div className="ml-auto" style={{ width: '208px' }}>*/}
            {/*    <UserProgress />*/}
            {/*  </div>*/}
            {/*}*/}
            {isLoggedIn && cashDropStatus === CashDropStatus.ENABLED && (
              <div
                className="ml-auto d-flex align-items justify-center"
                style={{ width: '210px' }}
              >
                {cashDropLoading && (
                  <div
                    className="mr-2"
                    style={{ height: '20px', width: '20px' }}
                  >
                    <SpinnerCircularSplit
                      enabled={true}
                      color="#acf439"
                      size="100%"
                    />
                  </div>
                )}
                <button className="cash-drop-btn" onClick={openCahsDropModal}>
                  <div className="d-flex align-items"></div>
                </button>
              </div>
            )}
          </div>
        </Header>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sport" element={<Sport />} />

        <Route path="/gambling" element={<Gambling />} />
        <Route path="/gambling-mobile" element={<GamblingMobile />} />
        <Route path="/gambling-mobile-live" element={<GamblingMobileLive />} />
        <Route
          path="/quick-games-list-mobile"
          element={<QuickGamesListMobile />}
        />
        <Route path="/zendesk-chat" element={<LogateChat />} />
        <Route path="/gambling-live" element={<GamblingLive />} />
        <Route path="/gambling-game" element={<GamblingGamePlaceholder />} />
        <Route path="/monri-payment" element={<PaymentMonriPlaceholder />} />
        <Route path="/reset-password" element={<NewPassword />} />
        <Route path="/quick-games" element={<QuickGames mobile={false} />} />
        <Route path="/aviator" element={<Aviator />} />
        <Route path="/quick-games-mobile" element={<QuickGamesPlaceholder />} />
        <Route path="/bank-credit-mobile" element={<BankCreditPlaceholder />} />
        <Route path="/portal" element={<div>Portal</div>} />
        {isLoggedIn && (
          <Route path="/profil/" element={<UserProfile />}>
            <Route
              path={profileRoutes.TICKETS_OVERVIEW}
              element={<TicketOverview />}
            />
            <Route
              path={profileRoutes.QUICK_TICKETS_OVERVIEW}
              element={<TicketOverview quickCodes={true} />}
            />
            <Route path={profileRoutes.DOCUMENTS} element={<Verification />} />
            <Route
              path={profileRoutes.PASSWORD_CHANGE}
              element={<ChangePassword />}
            />
            <Route
              path={profileRoutes.RESPONSIBLE_GAMBLING}
              element={<ResponsibleGambling />}
            />
            <Route path={profileRoutes.DEPOSIT} element={<Debit />} />
            <Route path={profileRoutes.WITHDRAW} element={<Credit />} />
            <Route
              path={profileRoutes.POKER_DEPOSIT}
              element={<PokerDebit />}
            />
            <Route
              path={profileRoutes.POKER_WITHDRAW}
              element={<PokerCredit />}
            />
            <Route
              path={profileRoutes.TRANSACTIONS}
              element={<UserTransactions />}
            />
            <Route path={profileRoutes.PROMO} element={<UserPromoCode />} />
            <Route path={profileRoutes.MARKET} element={<UserMarket />} />
            <Route
              path={profileRoutes.MARKET_HISTORY}
              element={<UserMarketHistory />}
            />
            <Route path={profileRoutes.MARKET_CART} element={<MarketCart />} />
            <Route path={profileRoutes.SETTINGS} element={<UserSettings />} />
            <Route
              path="/profil/"
              element={<Navigate to="/profil/pregled-tiketa" replace />}
            />
          </Route>
        )}
        {/* <Redirect from="**" to="/sport" /> */}
        <Route path="*" element={<Navigate to="/sport" replace />} />
      </Routes>

      {!disabledMobileFooterRoutes.includes(pathname) && (
        <MobileFooter routes={mobileFooterRoutes} />
      )}

      {isActive ? <Loader /> : null}

      <Modal {...overlayStore.modalProps} visible={!!overlayStore.modalContent}>
        {overlayStore.modalContent && overlayStore.modalContent()}
      </Modal>

      <Drawer
        {...overlayStore.drawerProps}
        visible={!!overlayStore.drawerContent}
      >
        {overlayStore.drawerContent && overlayStore.drawerContent()}
      </Drawer>

      <ToastContainer hideProgressBar pauseOnHover={false} />

      {cookiesBanner && <CookiesBanner onClick={acceptCookies} />}
    </div>
  );
});

export default App;
