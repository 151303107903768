/* React modules */

/* Our modules */
import { OddsStreamServiceClient } from 'proto/odds_stream/Odds_streamServiceClientPb';
import { WebOddsServiceClient } from 'proto/odds/Web_oddsServiceClientPb';
import { OddsCacheServiceClient } from 'proto/cache_service/CacheServiceClientPb';
import { CompetitionId, InitialDataRequest } from 'proto/odds/web_odds_pb';
import {
  WebStreamEvent,
  InitialDataRequest as InitialDataRequestGO,
  CounterRequest,
} from 'proto/cache_service/cache_pb';
import { Event, MarketGroup, Sport } from 'modules/sports/sports.types';
import ProtobufApi from 'libs/protobufApi';
import { getApiUrl } from 'libs/urlBuilder';
import { http } from 'app/axios-config';

/* 3rd Party modules */
import Empty from 'google-protobuf/google/protobuf/empty_pb';

export type SportsAndMarketsResponse = {
  marketGroupsList: MarketGroup[];
  sportsList: Sport[];
};

export type EventsResponse = {
  liveEvents: {
    eventsList: Event[];
  };
  upcomingEvents: {
    error: any;
    eventsList: Event[];
  };
  playerEvents: {
    error: any;
    eventsList: Event[];
  };
};

class SportsApi {
  private client: WebOddsServiceClient;
  private oddsCacheClient: OddsCacheServiceClient;
  private oddsClient: OddsStreamServiceClient;
  private api: ProtobufApi;
  private oddsApi: ProtobufApi;
  private oddsCacheApi: ProtobufApi;

  constructor() {
    this.client = new WebOddsServiceClient(getApiUrl(), {}, {});
    this.oddsCacheClient = new OddsCacheServiceClient(
      getApiUrl('', { service: 'go_cache' }),
      {},
      {}
    );
    this.oddsClient = new OddsStreamServiceClient(
      getApiUrl('', { service: 'go' }),
      {},
      {}
    );
    this.api = new ProtobufApi(this.client);
    this.oddsApi = new ProtobufApi(this.oddsClient);
    this.oddsCacheApi = new ProtobufApi(this.oddsCacheClient);
  }

  getSportsAndMarkets(options = {}): Promise<SportsAndMarketsResponse> {
    const request = new InitialDataRequestGO();
    return this.oddsCacheApi.request<SportsAndMarketsResponse>(
      'getInitialData',
      [request, options]
    );
  }

  getSports(options = {}): Promise<any> {
    const request = new InitialDataRequestGO();
    return this.oddsCacheApi.request<any>('getInitialDataSportsMap', [
      request,
      options,
    ]);
  }

  getMarkets(options = {}): Promise<any> {
    const request = new InitialDataRequestGO();
    return this.oddsCacheApi.request<any>('getInitialDataMarkets', [
      request,
      options,
    ]);
  }

  getInitialDelta(options = {}): Promise<any> {
    const request = new InitialDataRequestGO();
    return this.oddsCacheApi.request<any>('getInitialDataDeltaMap', [
      request,
      options,
    ]);
  }

  getPlayerOutrightMarkets(options = {}): Promise<SportsAndMarketsResponse> {
    const request = new InitialDataRequest();
    return this.api.request<SportsAndMarketsResponse>(
      'getPlayerOutrightMarkets',
      [request, options]
    );
  }

  // getEvents(onData: any, onEnd: any, options = {}) {
  //   const request = new Empty.Empty();
  //   this.oddsApi.stream('webEventsStream', onData, onEnd, [request, options]);
  // }

  getOfferCounters(timeZoneOffset: string, options?: any): Promise<any> {
    const request = new CounterRequest();
    request.setOffset(timeZoneOffset);
    return this.oddsCacheApi.request<any>('getOfferCounter', [
      request,
      options,
    ]);
  }

  fetchAllStream<T>(events: any, onData: any, onEnd: any): Promise<T[]> {
    const request = new Empty.Empty();
    const response: T[] = [];
    var count = 0;
    return new Promise((resolve) => {
      this.oddsApi.stream(
        'webEventsStreamOrdered',
        (data: any) => {
          onData(data);
          response.push(data);
        },
        () => {
          response.forEach((batch) => {
            onEnd(batch);
          });
        },
        [request, {}]
      );
    });
  }

  getEventOdds(id: string): Promise<any> {
    const request = new WebStreamEvent();
    request.setId(id);
    return this.oddsCacheApi.request<any>('getSingleEvent', [request, {}]);
  }

  getLiveDisableStatus(options = {}) {
    const request = new Empty.Empty();
    return this.api.request<any>('getLiveDisabled', [request, options]);
  }

  getMissingCompetition(id: number, options = {}) {
    const request = new CompetitionId();
    request.setId(id);
    return this.api.request<any>('getMissingCompetition', [request, options]);
  }

  getTimestamp() {
    return http.get<{
      l: boolean;
      po: boolean;
      r: boolean;
      tms: string;
      ts: string;
      v: string;
    }>('/app-config');
  }
}

export default SportsApi;
