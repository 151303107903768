/* React modules */

/* Our modules */
import {
  ActiveWithdrawal,
  ActivatePromoCodeResponse,
  MonriTransactionResponse,
  MinMaxPaymentResponse,
} from 'modules/payment/payment.types';
import { http } from 'app/axios-config';

/* 3rd Party modules */

class PaymentApi {
  initMonriDeposit(amount: number) {
    return http.post<MonriTransactionResponse>(
      '/payments2/monri/init-transaction',
      { amount }
    );
  }

  createWithdrawal(data: any) {
    return http.post('/payments2/withdrawal-reservation', data);
  }

  createBankWithdrawal(data: any) {
    return http.post('/payments2/bank/withdrawal-reservation', data);
  }

  cancelWithdrawal() {
    return http.put('/payments2/cancel-withdrawal-reservation');
  }

  cancelBankWithdrawal() {
    return http.put('/payments2/bank/cancel-withdrawal-reservation');
  }

  getWithdrawalReservation(): Promise<ActiveWithdrawal> {
    return http.get('/payments2/platform/withdrawal-reservation');
  }

  getBankWithdrawalReservation(): Promise<ActiveWithdrawal> {
    return http.get('/payments2/platform/bank/withdrawal-reservation');
  }

  activatePromoCode(
    code: string
  ): Promise<{ data: ActivatePromoCodeResponse }> {
    return http.post(`/payments2/promo/activate?promo_code=${code}`);
  }

  checkPromoCode(code: string): Promise<any> {
    return http.get(`/payments2/promo/check?promo_code=${code}`);
  }

  getMinMaxWithdrawal() {
    return http.get<MinMaxPaymentResponse>(
      '/payments2/bo/get-min-max-lb-payment'
    );
  }
}

export default PaymentApi;
