/* React modules */

/* Our modules */
import { MonriResponseCodes } from 'modules/payment/payment.types';

/* 3rd Party modules */

const MONRI_DEFAULT_ERROR = 'payments.payment-default-error';

const MONRI_RESPONSE_MESSAGES: any = {
  [MonriResponseCodes.APPROVED]: 'payments.payment-success',
  [MonriResponseCodes.CARD_NOT_FOUND]: 'payments.card-not-found',
  [MonriResponseCodes.CARD_EXPIRED]: 'payments.card-expired',
  [MonriResponseCodes.CARD_SUSPENDED]: 'payments.card-expired',
  [MonriResponseCodes.INVALID_AMOUNT]: 'payments.invalid-amount',
  [MonriResponseCodes.INVALID_CARD_NUMBER]: 'payments.invalid-card-number',
};

const MONRI_SUCCESS_STATUSES = [MonriResponseCodes.APPROVED];

// const MAX_CREDIT_BETTING_PLACE = 25000;
// const MIN_CREDIT_BETTING_PLACE = 1;
const MAX_CREDIT_BANK = 25000;
const MIN_CREDIT_BANK = 10;

const MAX_DEBIT_BANK = 5000;
const MAX_DEBIT_PHYSICAL = 5000;
const MIN_DEBIT_BANK = 5;
const MIN_DEBIT_PHYSICAL = 10;

export {
  MONRI_DEFAULT_ERROR,
  MONRI_RESPONSE_MESSAGES,
  MONRI_SUCCESS_STATUSES,
  MAX_CREDIT_BANK,
  MIN_CREDIT_BANK,
  MAX_DEBIT_BANK,
  MAX_DEBIT_PHYSICAL,
  MIN_DEBIT_BANK,
  MIN_DEBIT_PHYSICAL,
};
