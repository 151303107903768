// source: proto/user/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_common_common_pb = require('../../proto/common/common_pb.js');
goog.object.extend(proto, proto_common_common_pb);
goog.exportSymbol('proto.user.ActiveBettingPlaceCodes', null, global);
goog.exportSymbol('proto.user.Activity', null, global);
goog.exportSymbol('proto.user.Agent', null, global);
goog.exportSymbol('proto.user.AgentPayinRequest', null, global);
goog.exportSymbol('proto.user.AllBMMenuItems', null, global);
goog.exportSymbol('proto.user.BMCommandData', null, global);
goog.exportSymbol('proto.user.BMCommandRequest', null, global);
goog.exportSymbol('proto.user.BMMenuItem', null, global);
goog.exportSymbol('proto.user.BMMonitorResponse', null, global);
goog.exportSymbol('proto.user.BMMonitorUrlRequest', null, global);
goog.exportSymbol('proto.user.BMSecondaryWindow', null, global);
goog.exportSymbol('proto.user.BackOfficeUser', null, global);
goog.exportSymbol('proto.user.BettingMachine', null, global);
goog.exportSymbol('proto.user.BettingMachineDevice', null, global);
goog.exportSymbol('proto.user.BettingMachineExternalLink', null, global);
goog.exportSymbol('proto.user.BettingMachineExternalLinks', null, global);
goog.exportSymbol('proto.user.BettingPlace', null, global);
goog.exportSymbol('proto.user.BettingPlaceDevice', null, global);
goog.exportSymbol('proto.user.BettingPlaceDeviceRequest', null, global);
goog.exportSymbol('proto.user.BettingPlaceId', null, global);
goog.exportSymbol('proto.user.BettingPlaceLimitsRequest', null, global);
goog.exportSymbol('proto.user.BlankSlipRequest', null, global);
goog.exportSymbol('proto.user.BoPermissionMap', null, global);
goog.exportSymbol('proto.user.Bonus', null, global);
goog.exportSymbol('proto.user.BonusCategory', null, global);
goog.exportSymbol('proto.user.BonusMetadata', null, global);
goog.exportSymbol('proto.user.BonusSubType', null, global);
goog.exportSymbol('proto.user.BonusType', null, global);
goog.exportSymbol('proto.user.CacheBackAward', null, global);
goog.exportSymbol('proto.user.CheckSameMunicipalityBPSRequest', null, global);
goog.exportSymbol('proto.user.CheckSameMunicipalityBPSResponse', null, global);
goog.exportSymbol('proto.user.CheckSlipBonusRequest', null, global);
goog.exportSymbol('proto.user.CheckSlipBonusResponse', null, global);
goog.exportSymbol('proto.user.Combination', null, global);
goog.exportSymbol('proto.user.ConfirmFundsResponse', null, global);
goog.exportSymbol('proto.user.CreateBonusRequest', null, global);
goog.exportSymbol('proto.user.CreatePlatformUserRequest', null, global);
goog.exportSymbol('proto.user.CreateUserRequest', null, global);
goog.exportSymbol('proto.user.Creditee', null, global);
goog.exportSymbol('proto.user.CrediteeList', null, global);
goog.exportSymbol('proto.user.DeviceCode', null, global);
goog.exportSymbol('proto.user.DoublePrintRequest', null, global);
goog.exportSymbol('proto.user.EditBMMenuItemRequest', null, global);
goog.exportSymbol('proto.user.EditBettingPlaceRequest', null, global);
goog.exportSymbol('proto.user.Employee', null, global);
goog.exportSymbol('proto.user.Filter', null, global);
goog.exportSymbol('proto.user.GetAgentCommisionAndLimitResponse', null, global);
goog.exportSymbol('proto.user.GetAgentsRequest', null, global);
goog.exportSymbol('proto.user.GetAgentsResponse', null, global);
goog.exportSymbol('proto.user.GetAllowDepositRequest', null, global);
goog.exportSymbol('proto.user.GetAllowDepositResponse', null, global);
goog.exportSymbol('proto.user.GetBPBettingMachinesResponse', null, global);
goog.exportSymbol('proto.user.GetBackOfficeUsersRequest', null, global);
goog.exportSymbol('proto.user.GetBackOfficeUsersResponse', null, global);
goog.exportSymbol('proto.user.GetBettingMachineByUsernameAndTokenRequest', null, global);
goog.exportSymbol('proto.user.GetBettingMachineByUsernameAndTokenResponse', null, global);
goog.exportSymbol('proto.user.GetBettingMachineDevicesRequest', null, global);
goog.exportSymbol('proto.user.GetBettingMachineDevicesResponse', null, global);
goog.exportSymbol('proto.user.GetBettingMachinesRequest', null, global);
goog.exportSymbol('proto.user.GetBettingMachinesResponse', null, global);
goog.exportSymbol('proto.user.GetBettingPlaceInfoByDeviceCodeResponse', null, global);
goog.exportSymbol('proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse', null, global);
goog.exportSymbol('proto.user.GetBettingPlacesBoRequest', null, global);
goog.exportSymbol('proto.user.GetBettingPlacesBoResponse', null, global);
goog.exportSymbol('proto.user.GetBettingPlacesByMunicipalityResponse', null, global);
goog.exportSymbol('proto.user.GetEmployeesRequest', null, global);
goog.exportSymbol('proto.user.GetEmployeesResponse', null, global);
goog.exportSymbol('proto.user.GetLiveBettingMachinesResponse', null, global);
goog.exportSymbol('proto.user.GetTestBettingPlaceResponse', null, global);
goog.exportSymbol('proto.user.GetUserByEmailAndPasswordRequest', null, global);
goog.exportSymbol('proto.user.GetUserByEmailAndPasswordResponse', null, global);
goog.exportSymbol('proto.user.GetUserPermissionsRequest', null, global);
goog.exportSymbol('proto.user.GetUserPermissionsResponse', null, global);
goog.exportSymbol('proto.user.GetUserRequest', null, global);
goog.exportSymbol('proto.user.GrantCacheBackBonusRequest', null, global);
goog.exportSymbol('proto.user.IndependentBMMenuItem', null, global);
goog.exportSymbol('proto.user.IsTestValues', null, global);
goog.exportSymbol('proto.user.ListActivityRequest', null, global);
goog.exportSymbol('proto.user.ListActivityResponse', null, global);
goog.exportSymbol('proto.user.ListUsersRequest', null, global);
goog.exportSymbol('proto.user.ListUsersResponse', null, global);
goog.exportSymbol('proto.user.Municipality', null, global);
goog.exportSymbol('proto.user.NewBMMenuItem', null, global);
goog.exportSymbol('proto.user.NewBettingPlace', null, global);
goog.exportSymbol('proto.user.NewBettingPlaceRequest', null, global);
goog.exportSymbol('proto.user.ReleaseFundsRequest', null, global);
goog.exportSymbol('proto.user.ReleaseFundsResponse', null, global);
goog.exportSymbol('proto.user.ReserveFundsRequest', null, global);
goog.exportSymbol('proto.user.ReserveFundsResponse', null, global);
goog.exportSymbol('proto.user.SetActiveBettingPlaceRequest', null, global);
goog.exportSymbol('proto.user.SetAllowDepositRequest', null, global);
goog.exportSymbol('proto.user.SetBMMonitorUrlRequest', null, global);
goog.exportSymbol('proto.user.TestBettingPlaceRequest', null, global);
goog.exportSymbol('proto.user.TestBettingPlacesResponse', null, global);
goog.exportSymbol('proto.user.UpdateUserRequest', null, global);
goog.exportSymbol('proto.user.User', null, global);
goog.exportSymbol('proto.user.UserResponse', null, global);
goog.exportSymbol('proto.user.ValidationPriorityObjects', null, global);
goog.exportSymbol('proto.user.WinningSlipAward', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetLiveBettingMachinesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetLiveBettingMachinesResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetLiveBettingMachinesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetLiveBettingMachinesResponse.displayName = 'proto.user.GetLiveBettingMachinesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BMSecondaryWindow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BMSecondaryWindow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BMSecondaryWindow.displayName = 'proto.user.BMSecondaryWindow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BMCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.BMCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.user.BMCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BMCommandRequest.displayName = 'proto.user.BMCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BMCommandData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BMCommandData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BMCommandData.displayName = 'proto.user.BMCommandData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.IndependentBMMenuItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.IndependentBMMenuItem.repeatedFields_, null);
};
goog.inherits(proto.user.IndependentBMMenuItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.IndependentBMMenuItem.displayName = 'proto.user.IndependentBMMenuItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.EditBMMenuItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.EditBMMenuItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.EditBMMenuItemRequest.displayName = 'proto.user.EditBMMenuItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.NewBMMenuItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.NewBMMenuItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.NewBMMenuItem.displayName = 'proto.user.NewBMMenuItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BMMenuItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BMMenuItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BMMenuItem.displayName = 'proto.user.BMMenuItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.AllBMMenuItems = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.AllBMMenuItems.repeatedFields_, null);
};
goog.inherits(proto.user.AllBMMenuItems, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.AllBMMenuItems.displayName = 'proto.user.AllBMMenuItems';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingPlaceId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingPlaceId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingPlaceId.displayName = 'proto.user.BettingPlaceId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.SetBMMonitorUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.SetBMMonitorUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.SetBMMonitorUrlRequest.displayName = 'proto.user.SetBMMonitorUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BMMonitorUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BMMonitorUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BMMonitorUrlRequest.displayName = 'proto.user.BMMonitorUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BMMonitorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BMMonitorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BMMonitorResponse.displayName = 'proto.user.BMMonitorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.IsTestValues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.IsTestValues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.IsTestValues.displayName = 'proto.user.IsTestValues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.SetAllowDepositRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.SetAllowDepositRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.SetAllowDepositRequest.displayName = 'proto.user.SetAllowDepositRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetAllowDepositRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetAllowDepositRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetAllowDepositRequest.displayName = 'proto.user.GetAllowDepositRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetAllowDepositResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetAllowDepositResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetAllowDepositResponse.displayName = 'proto.user.GetAllowDepositResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.SetActiveBettingPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.SetActiveBettingPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.SetActiveBettingPlaceRequest.displayName = 'proto.user.SetActiveBettingPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BoPermissionMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BoPermissionMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BoPermissionMap.displayName = 'proto.user.BoPermissionMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBackOfficeUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBackOfficeUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBackOfficeUsersRequest.displayName = 'proto.user.GetBackOfficeUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBackOfficeUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetBackOfficeUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetBackOfficeUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBackOfficeUsersResponse.displayName = 'proto.user.GetBackOfficeUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BackOfficeUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BackOfficeUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BackOfficeUser.displayName = 'proto.user.BackOfficeUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetAgentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetAgentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetAgentsRequest.displayName = 'proto.user.GetAgentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetAgentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetAgentsResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetAgentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetAgentsResponse.displayName = 'proto.user.GetAgentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Agent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Agent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Agent.displayName = 'proto.user.Agent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetAgentCommisionAndLimitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetAgentCommisionAndLimitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetAgentCommisionAndLimitResponse.displayName = 'proto.user.GetAgentCommisionAndLimitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.AgentPayinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.AgentPayinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.AgentPayinRequest.displayName = 'proto.user.AgentPayinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Employee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Employee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Employee.displayName = 'proto.user.Employee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetEmployeesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetEmployeesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetEmployeesRequest.displayName = 'proto.user.GetEmployeesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Filter.displayName = 'proto.user.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetEmployeesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetEmployeesResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetEmployeesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetEmployeesResponse.displayName = 'proto.user.GetEmployeesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingMachine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingMachine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingMachine.displayName = 'proto.user.BettingMachine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingMachinesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingMachinesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingMachinesRequest.displayName = 'proto.user.GetBettingMachinesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingMachinesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetBettingMachinesResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetBettingMachinesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingMachinesResponse.displayName = 'proto.user.GetBettingMachinesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingMachineDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingMachineDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingMachineDevice.displayName = 'proto.user.BettingMachineDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingMachineDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingMachineDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingMachineDevicesRequest.displayName = 'proto.user.GetBettingMachineDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingMachineDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetBettingMachineDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetBettingMachineDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingMachineDevicesResponse.displayName = 'proto.user.GetBettingMachineDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingMachineExternalLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingMachineExternalLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingMachineExternalLink.displayName = 'proto.user.BettingMachineExternalLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingMachineExternalLinks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.BettingMachineExternalLinks.repeatedFields_, null);
};
goog.inherits(proto.user.BettingMachineExternalLinks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingMachineExternalLinks.displayName = 'proto.user.BettingMachineExternalLinks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CrediteeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.CrediteeList.repeatedFields_, null);
};
goog.inherits(proto.user.CrediteeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CrediteeList.displayName = 'proto.user.CrediteeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Creditee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Creditee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Creditee.displayName = 'proto.user.Creditee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.UserResponse.displayName = 'proto.user.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.User.displayName = 'proto.user.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CreateUserRequest.displayName = 'proto.user.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CreatePlatformUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CreatePlatformUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CreatePlatformUserRequest.displayName = 'proto.user.CreatePlatformUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserByEmailAndPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetUserByEmailAndPasswordRequest.repeatedFields_, null);
};
goog.inherits(proto.user.GetUserByEmailAndPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserByEmailAndPasswordRequest.displayName = 'proto.user.GetUserByEmailAndPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserByEmailAndPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetUserByEmailAndPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserByEmailAndPasswordResponse.displayName = 'proto.user.GetUserByEmailAndPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingMachineByUsernameAndTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingMachineByUsernameAndTokenRequest.displayName = 'proto.user.GetBettingMachineByUsernameAndTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingMachineByUsernameAndTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingMachineByUsernameAndTokenResponse.displayName = 'proto.user.GetBettingMachineByUsernameAndTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserRequest.displayName = 'proto.user.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.UpdateUserRequest.displayName = 'proto.user.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ListUsersRequest.displayName = 'proto.user.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.user.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ListUsersResponse.displayName = 'proto.user.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ReserveFundsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ReserveFundsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ReserveFundsRequest.displayName = 'proto.user.ReserveFundsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ReserveFundsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ReserveFundsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ReserveFundsResponse.displayName = 'proto.user.ReserveFundsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ReleaseFundsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ReleaseFundsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ReleaseFundsRequest.displayName = 'proto.user.ReleaseFundsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ReleaseFundsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ReleaseFundsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ReleaseFundsResponse.displayName = 'proto.user.ReleaseFundsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ConfirmFundsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ConfirmFundsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ConfirmFundsResponse.displayName = 'proto.user.ConfirmFundsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingPlacesBoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingPlacesBoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingPlacesBoRequest.displayName = 'proto.user.GetBettingPlacesBoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingPlacesBoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetBettingPlacesBoResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetBettingPlacesBoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingPlacesBoResponse.displayName = 'proto.user.GetBettingPlacesBoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.BettingPlace.repeatedFields_, null);
};
goog.inherits(proto.user.BettingPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingPlace.displayName = 'proto.user.BettingPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.NewBettingPlace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.NewBettingPlace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.NewBettingPlace.displayName = 'proto.user.NewBettingPlace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.NewBettingPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.NewBettingPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.NewBettingPlaceRequest.displayName = 'proto.user.NewBettingPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.EditBettingPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.EditBettingPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.EditBettingPlaceRequest.displayName = 'proto.user.EditBettingPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingPlaceDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingPlaceDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingPlaceDevice.displayName = 'proto.user.BettingPlaceDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingPlaceDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingPlaceDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingPlaceDeviceRequest.displayName = 'proto.user.BettingPlaceDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetUserPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserPermissionsRequest.displayName = 'proto.user.GetUserPermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetUserPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetUserPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserPermissionsResponse.displayName = 'proto.user.GetUserPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CreateBonusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.CreateBonusRequest.repeatedFields_, null);
};
goog.inherits(proto.user.CreateBonusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CreateBonusRequest.displayName = 'proto.user.CreateBonusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Bonus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Bonus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Bonus.displayName = 'proto.user.Bonus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BonusMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.BonusMetadata.repeatedFields_, null);
};
goog.inherits(proto.user.BonusMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BonusMetadata.displayName = 'proto.user.BonusMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BonusCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.BonusCategory.repeatedFields_, null);
};
goog.inherits(proto.user.BonusCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BonusCategory.displayName = 'proto.user.BonusCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BonusType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.BonusType.repeatedFields_, null);
};
goog.inherits(proto.user.BonusType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BonusType.displayName = 'proto.user.BonusType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BonusSubType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BonusSubType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BonusSubType.displayName = 'proto.user.BonusSubType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.WinningSlipAward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.WinningSlipAward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.WinningSlipAward.displayName = 'proto.user.WinningSlipAward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CacheBackAward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CacheBackAward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CacheBackAward.displayName = 'proto.user.CacheBackAward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CheckSlipBonusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.CheckSlipBonusRequest.repeatedFields_, null);
};
goog.inherits(proto.user.CheckSlipBonusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CheckSlipBonusRequest.displayName = 'proto.user.CheckSlipBonusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CheckSlipBonusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CheckSlipBonusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CheckSlipBonusResponse.displayName = 'proto.user.CheckSlipBonusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Combination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.Combination.repeatedFields_, null);
};
goog.inherits(proto.user.Combination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Combination.displayName = 'proto.user.Combination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GrantCacheBackBonusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GrantCacheBackBonusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GrantCacheBackBonusRequest.displayName = 'proto.user.GrantCacheBackBonusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.DeviceCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.DeviceCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.DeviceCode.displayName = 'proto.user.DeviceCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingPlaceInfoByDeviceCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingPlaceInfoByDeviceCodeResponse.displayName = 'proto.user.GetBettingPlaceInfoByDeviceCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.displayName = 'proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ValidationPriorityObjects = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ValidationPriorityObjects, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ValidationPriorityObjects.displayName = 'proto.user.ValidationPriorityObjects';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ActiveBettingPlaceCodes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ActiveBettingPlaceCodes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ActiveBettingPlaceCodes.displayName = 'proto.user.ActiveBettingPlaceCodes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Municipality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.Municipality.repeatedFields_, null);
};
goog.inherits(proto.user.Municipality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Municipality.displayName = 'proto.user.Municipality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBettingPlacesByMunicipalityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetBettingPlacesByMunicipalityResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetBettingPlacesByMunicipalityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBettingPlacesByMunicipalityResponse.displayName = 'proto.user.GetBettingPlacesByMunicipalityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ListActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.ListActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ListActivityRequest.displayName = 'proto.user.ListActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.ListActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.ListActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.user.ListActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.ListActivityResponse.displayName = 'proto.user.ListActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.Activity.displayName = 'proto.user.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.DoublePrintRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.DoublePrintRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.DoublePrintRequest.displayName = 'proto.user.DoublePrintRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BlankSlipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BlankSlipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BlankSlipRequest.displayName = 'proto.user.BlankSlipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.TestBettingPlaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.TestBettingPlaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.TestBettingPlaceRequest.displayName = 'proto.user.TestBettingPlaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.TestBettingPlacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.TestBettingPlacesResponse.repeatedFields_, null);
};
goog.inherits(proto.user.TestBettingPlacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.TestBettingPlacesResponse.displayName = 'proto.user.TestBettingPlacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetTestBettingPlaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetTestBettingPlaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetTestBettingPlaceResponse.displayName = 'proto.user.GetTestBettingPlaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CheckSameMunicipalityBPSRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CheckSameMunicipalityBPSRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CheckSameMunicipalityBPSRequest.displayName = 'proto.user.CheckSameMunicipalityBPSRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.CheckSameMunicipalityBPSResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.CheckSameMunicipalityBPSResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.CheckSameMunicipalityBPSResponse.displayName = 'proto.user.CheckSameMunicipalityBPSResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetBPBettingMachinesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetBPBettingMachinesResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetBPBettingMachinesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetBPBettingMachinesResponse.displayName = 'proto.user.GetBPBettingMachinesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.BettingPlaceLimitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.BettingPlaceLimitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.BettingPlaceLimitsRequest.displayName = 'proto.user.BettingPlaceLimitsRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetLiveBettingMachinesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetLiveBettingMachinesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetLiveBettingMachinesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetLiveBettingMachinesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetLiveBettingMachinesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetLiveBettingMachinesResponse}
 */
proto.user.GetLiveBettingMachinesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetLiveBettingMachinesResponse;
  return proto.user.GetLiveBettingMachinesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetLiveBettingMachinesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetLiveBettingMachinesResponse}
 */
proto.user.GetLiveBettingMachinesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetLiveBettingMachinesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetLiveBettingMachinesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetLiveBettingMachinesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetLiveBettingMachinesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 ids = 1;
 * @return {!Array<number>}
 */
proto.user.GetLiveBettingMachinesResponse.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.user.GetLiveBettingMachinesResponse} returns this
 */
proto.user.GetLiveBettingMachinesResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.user.GetLiveBettingMachinesResponse} returns this
 */
proto.user.GetLiveBettingMachinesResponse.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetLiveBettingMachinesResponse} returns this
 */
proto.user.GetLiveBettingMachinesResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BMSecondaryWindow.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BMSecondaryWindow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BMSecondaryWindow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMSecondaryWindow.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    port: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BMSecondaryWindow}
 */
proto.user.BMSecondaryWindow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BMSecondaryWindow;
  return proto.user.BMSecondaryWindow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BMSecondaryWindow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BMSecondaryWindow}
 */
proto.user.BMSecondaryWindow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingMachineId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BMSecondaryWindow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BMSecondaryWindow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BMSecondaryWindow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMSecondaryWindow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachineId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 betting_machine_id = 1;
 * @return {number}
 */
proto.user.BMSecondaryWindow.prototype.getBettingMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BMSecondaryWindow} returns this
 */
proto.user.BMSecondaryWindow.prototype.setBettingMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string port = 2;
 * @return {string}
 */
proto.user.BMSecondaryWindow.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMSecondaryWindow} returns this
 */
proto.user.BMSecondaryWindow.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.BMCommandRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BMCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BMCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BMCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachineIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.user.BMCommandData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BMCommandRequest}
 */
proto.user.BMCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BMCommandRequest;
  return proto.user.BMCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BMCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BMCommandRequest}
 */
proto.user.BMCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBettingMachineIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.user.BMCommandData;
      reader.readMessage(value,proto.user.BMCommandData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BMCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BMCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BMCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachineIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.user.BMCommandData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 betting_machine_ids = 1;
 * @return {!Array<number>}
 */
proto.user.BMCommandRequest.prototype.getBettingMachineIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.user.BMCommandRequest} returns this
 */
proto.user.BMCommandRequest.prototype.setBettingMachineIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.user.BMCommandRequest} returns this
 */
proto.user.BMCommandRequest.prototype.addBettingMachineIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.BMCommandRequest} returns this
 */
proto.user.BMCommandRequest.prototype.clearBettingMachineIdsList = function() {
  return this.setBettingMachineIdsList([]);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.user.BMCommandRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandRequest} returns this
 */
proto.user.BMCommandRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BMCommandData data = 3;
 * @return {?proto.user.BMCommandData}
 */
proto.user.BMCommandRequest.prototype.getData = function() {
  return /** @type{?proto.user.BMCommandData} */ (
    jspb.Message.getWrapperField(this, proto.user.BMCommandData, 3));
};


/**
 * @param {?proto.user.BMCommandData|undefined} value
 * @return {!proto.user.BMCommandRequest} returns this
*/
proto.user.BMCommandRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BMCommandRequest} returns this
 */
proto.user.BMCommandRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BMCommandRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BMCommandData.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BMCommandData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BMCommandData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMCommandData.toObject = function(includeInstance, msg) {
  var f, obj = {
    dev: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, ""),
    port: jspb.Message.getFieldWithDefault(msg, 3, ""),
    externalUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    externalName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    resolution: jspb.Message.getFieldWithDefault(msg, 8, ""),
    refreshRate: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BMCommandData}
 */
proto.user.BMCommandData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BMCommandData;
  return proto.user.BMCommandData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BMCommandData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BMCommandData}
 */
proto.user.BMCommandData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDev(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolution(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BMCommandData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BMCommandData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BMCommandData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMCommandData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDev();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExternalUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResolution();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRefreshRate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string dev = 1;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getDev = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setDev = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action = 2;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string port = 3;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string external_url = 4;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getExternalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setExternalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string external_id = 5;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string external_name = 6;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string orientation = 7;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string resolution = 8;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getResolution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setResolution = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string refresh_rate = 9;
 * @return {string}
 */
proto.user.BMCommandData.prototype.getRefreshRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMCommandData} returns this
 */
proto.user.BMCommandData.prototype.setRefreshRate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.IndependentBMMenuItem.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.IndependentBMMenuItem.prototype.toObject = function(opt_includeInstance) {
  return proto.user.IndependentBMMenuItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.IndependentBMMenuItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.IndependentBMMenuItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.user.BMMenuItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.IndependentBMMenuItem}
 */
proto.user.IndependentBMMenuItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.IndependentBMMenuItem;
  return proto.user.IndependentBMMenuItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.IndependentBMMenuItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.IndependentBMMenuItem}
 */
proto.user.IndependentBMMenuItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingMachineId(value);
      break;
    case 2:
      var value = new proto.user.BMMenuItem;
      reader.readMessage(value,proto.user.BMMenuItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.IndependentBMMenuItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.IndependentBMMenuItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.IndependentBMMenuItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.IndependentBMMenuItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachineId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.user.BMMenuItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 betting_machine_id = 1;
 * @return {number}
 */
proto.user.IndependentBMMenuItem.prototype.getBettingMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.IndependentBMMenuItem} returns this
 */
proto.user.IndependentBMMenuItem.prototype.setBettingMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated BMMenuItem items = 2;
 * @return {!Array<!proto.user.BMMenuItem>}
 */
proto.user.IndependentBMMenuItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.user.BMMenuItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BMMenuItem, 2));
};


/**
 * @param {!Array<!proto.user.BMMenuItem>} value
 * @return {!proto.user.IndependentBMMenuItem} returns this
*/
proto.user.IndependentBMMenuItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.user.BMMenuItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BMMenuItem}
 */
proto.user.IndependentBMMenuItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.user.BMMenuItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.IndependentBMMenuItem} returns this
 */
proto.user.IndependentBMMenuItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.EditBMMenuItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.EditBMMenuItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.EditBMMenuItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.EditBMMenuItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    link: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    image: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gameId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    gameName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.EditBMMenuItemRequest}
 */
proto.user.EditBMMenuItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.EditBMMenuItemRequest;
  return proto.user.EditBMMenuItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.EditBMMenuItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.EditBMMenuItemRequest}
 */
proto.user.EditBMMenuItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameName(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.EditBMMenuItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.EditBMMenuItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.EditBMMenuItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.EditBMMenuItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGameId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGameName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.EditBMMenuItemRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.user.EditBMMenuItemRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.user.EditBMMenuItemRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string link = 4;
 * @return {string}
 */
proto.user.EditBMMenuItemRequest.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.user.EditBMMenuItemRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool default = 6;
 * @return {boolean}
 */
proto.user.EditBMMenuItemRequest.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string image = 7;
 * @return {string}
 */
proto.user.EditBMMenuItemRequest.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 game_id = 8;
 * @return {number}
 */
proto.user.EditBMMenuItemRequest.prototype.getGameId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setGameId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string game_name = 9;
 * @return {string}
 */
proto.user.EditBMMenuItemRequest.prototype.getGameName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setGameName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_live = 10;
 * @return {boolean}
 */
proto.user.EditBMMenuItemRequest.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.EditBMMenuItemRequest} returns this
 */
proto.user.EditBMMenuItemRequest.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.NewBMMenuItem.prototype.toObject = function(opt_includeInstance) {
  return proto.user.NewBMMenuItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.NewBMMenuItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.NewBMMenuItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    gameId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gameName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.NewBMMenuItem}
 */
proto.user.NewBMMenuItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.NewBMMenuItem;
  return proto.user.NewBMMenuItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.NewBMMenuItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.NewBMMenuItem}
 */
proto.user.NewBMMenuItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameName(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.NewBMMenuItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.NewBMMenuItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.NewBMMenuItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.NewBMMenuItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getGameId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getGameName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.user.NewBMMenuItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.user.NewBMMenuItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.user.NewBMMenuItem.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.user.NewBMMenuItem.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.user.NewBMMenuItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool default = 6;
 * @return {boolean}
 */
proto.user.NewBMMenuItem.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 game_id = 7;
 * @return {number}
 */
proto.user.NewBMMenuItem.prototype.getGameId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setGameId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string game_name = 8;
 * @return {string}
 */
proto.user.NewBMMenuItem.prototype.getGameName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setGameName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_live = 9;
 * @return {boolean}
 */
proto.user.NewBMMenuItem.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBMMenuItem} returns this
 */
proto.user.NewBMMenuItem.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BMMenuItem.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BMMenuItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BMMenuItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMMenuItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0),
    globalActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    image: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gameId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    gameName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BMMenuItem}
 */
proto.user.BMMenuItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BMMenuItem;
  return proto.user.BMMenuItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BMMenuItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BMMenuItem}
 */
proto.user.BMMenuItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGlobalActive(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    case 12:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BMMenuItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BMMenuItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BMMenuItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMMenuItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGlobalActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGameId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getGameName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.user.BMMenuItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.user.BMMenuItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.user.BMMenuItem.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.user.BMMenuItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool global_active = 5;
 * @return {boolean}
 */
proto.user.BMMenuItem.prototype.getGlobalActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setGlobalActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.user.BMMenuItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool default = 7;
 * @return {boolean}
 */
proto.user.BMMenuItem.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string image = 8;
 * @return {string}
 */
proto.user.BMMenuItem.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 game_id = 9;
 * @return {number}
 */
proto.user.BMMenuItem.prototype.getGameId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setGameId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string game_name = 10;
 * @return {string}
 */
proto.user.BMMenuItem.prototype.getGameName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setGameName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_live = 11;
 * @return {boolean}
 */
proto.user.BMMenuItem.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional common.Error error = 12;
 * @return {?proto.common.Error}
 */
proto.user.BMMenuItem.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 12));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.BMMenuItem} returns this
*/
proto.user.BMMenuItem.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BMMenuItem} returns this
 */
proto.user.BMMenuItem.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BMMenuItem.prototype.hasError = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.AllBMMenuItems.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.AllBMMenuItems.prototype.toObject = function(opt_includeInstance) {
  return proto.user.AllBMMenuItems.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.AllBMMenuItems} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.AllBMMenuItems.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.user.BMMenuItem.toObject, includeInstance),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bettingMachineId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.AllBMMenuItems}
 */
proto.user.AllBMMenuItems.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.AllBMMenuItems;
  return proto.user.AllBMMenuItems.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.AllBMMenuItems} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.AllBMMenuItems}
 */
proto.user.AllBMMenuItems.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.BMMenuItem;
      reader.readMessage(value,proto.user.BMMenuItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingMachineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.AllBMMenuItems.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.AllBMMenuItems.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.AllBMMenuItems} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.AllBMMenuItems.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.BMMenuItem.serializeBinaryToWriter
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBettingMachineId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated BMMenuItem items = 1;
 * @return {!Array<!proto.user.BMMenuItem>}
 */
proto.user.AllBMMenuItems.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.user.BMMenuItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BMMenuItem, 1));
};


/**
 * @param {!Array<!proto.user.BMMenuItem>} value
 * @return {!proto.user.AllBMMenuItems} returns this
*/
proto.user.AllBMMenuItems.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.BMMenuItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BMMenuItem}
 */
proto.user.AllBMMenuItems.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.BMMenuItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.AllBMMenuItems} returns this
 */
proto.user.AllBMMenuItems.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int32 betting_place_id = 2;
 * @return {number}
 */
proto.user.AllBMMenuItems.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.AllBMMenuItems} returns this
 */
proto.user.AllBMMenuItems.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 betting_machine_id = 3;
 * @return {number}
 */
proto.user.AllBMMenuItems.prototype.getBettingMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.AllBMMenuItems} returns this
 */
proto.user.AllBMMenuItems.prototype.setBettingMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingPlaceId.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingPlaceId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingPlaceId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceId.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingPlaceId}
 */
proto.user.BettingPlaceId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingPlaceId;
  return proto.user.BettingPlaceId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingPlaceId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingPlaceId}
 */
proto.user.BettingPlaceId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingPlaceId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingPlaceId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingPlaceId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 betting_place_id = 1;
 * @return {number}
 */
proto.user.BettingPlaceId.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlaceId} returns this
 */
proto.user.BettingPlaceId.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.SetBMMonitorUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.SetBMMonitorUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.SetBMMonitorUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.SetBMMonitorUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monitorId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.SetBMMonitorUrlRequest}
 */
proto.user.SetBMMonitorUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.SetBMMonitorUrlRequest;
  return proto.user.SetBMMonitorUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.SetBMMonitorUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.SetBMMonitorUrlRequest}
 */
proto.user.SetBMMonitorUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonitorId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.SetBMMonitorUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.SetBMMonitorUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.SetBMMonitorUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.SetBMMonitorUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonitorId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string host_name = 1;
 * @return {string}
 */
proto.user.SetBMMonitorUrlRequest.prototype.getHostName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.SetBMMonitorUrlRequest} returns this
 */
proto.user.SetBMMonitorUrlRequest.prototype.setHostName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 monitor_id = 2;
 * @return {number}
 */
proto.user.SetBMMonitorUrlRequest.prototype.getMonitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.SetBMMonitorUrlRequest} returns this
 */
proto.user.SetBMMonitorUrlRequest.prototype.setMonitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.user.SetBMMonitorUrlRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.SetBMMonitorUrlRequest} returns this
 */
proto.user.SetBMMonitorUrlRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BMMonitorUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BMMonitorUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BMMonitorUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMMonitorUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monitorId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BMMonitorUrlRequest}
 */
proto.user.BMMonitorUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BMMonitorUrlRequest;
  return proto.user.BMMonitorUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BMMonitorUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BMMonitorUrlRequest}
 */
proto.user.BMMonitorUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonitorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BMMonitorUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BMMonitorUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BMMonitorUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMMonitorUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonitorId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string host_name = 1;
 * @return {string}
 */
proto.user.BMMonitorUrlRequest.prototype.getHostName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMonitorUrlRequest} returns this
 */
proto.user.BMMonitorUrlRequest.prototype.setHostName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 monitor_id = 2;
 * @return {number}
 */
proto.user.BMMonitorUrlRequest.prototype.getMonitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BMMonitorUrlRequest} returns this
 */
proto.user.BMMonitorUrlRequest.prototype.setMonitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BMMonitorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BMMonitorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BMMonitorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMMonitorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BMMonitorResponse}
 */
proto.user.BMMonitorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BMMonitorResponse;
  return proto.user.BMMonitorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BMMonitorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BMMonitorResponse}
 */
proto.user.BMMonitorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BMMonitorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BMMonitorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BMMonitorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BMMonitorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.user.BMMonitorResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BMMonitorResponse} returns this
 */
proto.user.BMMonitorResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.IsTestValues.prototype.toObject = function(opt_includeInstance) {
  return proto.user.IsTestValues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.IsTestValues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.IsTestValues.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    test: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.IsTestValues}
 */
proto.user.IsTestValues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.IsTestValues;
  return proto.user.IsTestValues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.IsTestValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.IsTestValues}
 */
proto.user.IsTestValues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.IsTestValues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.IsTestValues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.IsTestValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.IsTestValues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTest();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.IsTestValues.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.IsTestValues} returns this
 */
proto.user.IsTestValues.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool test = 2;
 * @return {boolean}
 */
proto.user.IsTestValues.prototype.getTest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.IsTestValues} returns this
 */
proto.user.IsTestValues.prototype.setTest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.SetAllowDepositRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.SetAllowDepositRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.SetAllowDepositRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.SetAllowDepositRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allowDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.SetAllowDepositRequest}
 */
proto.user.SetAllowDepositRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.SetAllowDepositRequest;
  return proto.user.SetAllowDepositRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.SetAllowDepositRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.SetAllowDepositRequest}
 */
proto.user.SetAllowDepositRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.SetAllowDepositRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.SetAllowDepositRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.SetAllowDepositRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.SetAllowDepositRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAllowDeposit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.SetAllowDepositRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.SetAllowDepositRequest} returns this
 */
proto.user.SetAllowDepositRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool allow_deposit = 2;
 * @return {boolean}
 */
proto.user.SetAllowDepositRequest.prototype.getAllowDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.SetAllowDepositRequest} returns this
 */
proto.user.SetAllowDepositRequest.prototype.setAllowDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetAllowDepositRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetAllowDepositRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetAllowDepositRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAllowDepositRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetAllowDepositRequest}
 */
proto.user.GetAllowDepositRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetAllowDepositRequest;
  return proto.user.GetAllowDepositRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetAllowDepositRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetAllowDepositRequest}
 */
proto.user.GetAllowDepositRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetAllowDepositRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetAllowDepositRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetAllowDepositRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAllowDepositRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.GetAllowDepositRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetAllowDepositRequest} returns this
 */
proto.user.GetAllowDepositRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetAllowDepositResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetAllowDepositResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetAllowDepositResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAllowDepositResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetAllowDepositResponse}
 */
proto.user.GetAllowDepositResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetAllowDepositResponse;
  return proto.user.GetAllowDepositResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetAllowDepositResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetAllowDepositResponse}
 */
proto.user.GetAllowDepositResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetAllowDepositResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetAllowDepositResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetAllowDepositResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAllowDepositResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowDeposit();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool allow_deposit = 1;
 * @return {boolean}
 */
proto.user.GetAllowDepositResponse.prototype.getAllowDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.GetAllowDepositResponse} returns this
 */
proto.user.GetAllowDepositResponse.prototype.setAllowDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.SetActiveBettingPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.SetActiveBettingPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.SetActiveBettingPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.SetActiveBettingPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.SetActiveBettingPlaceRequest}
 */
proto.user.SetActiveBettingPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.SetActiveBettingPlaceRequest;
  return proto.user.SetActiveBettingPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.SetActiveBettingPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.SetActiveBettingPlaceRequest}
 */
proto.user.SetActiveBettingPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.SetActiveBettingPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.SetActiveBettingPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.SetActiveBettingPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.SetActiveBettingPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.SetActiveBettingPlaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.SetActiveBettingPlaceRequest} returns this
 */
proto.user.SetActiveBettingPlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_active = 2;
 * @return {boolean}
 */
proto.user.SetActiveBettingPlaceRequest.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.SetActiveBettingPlaceRequest} returns this
 */
proto.user.SetActiveBettingPlaceRequest.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BoPermissionMap.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BoPermissionMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BoPermissionMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BoPermissionMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsMap: (f = msg.getPermissionsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BoPermissionMap}
 */
proto.user.BoPermissionMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BoPermissionMap;
  return proto.user.BoPermissionMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BoPermissionMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BoPermissionMap}
 */
proto.user.BoPermissionMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPermissionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BoPermissionMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BoPermissionMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BoPermissionMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BoPermissionMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> permissions = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.user.BoPermissionMap.prototype.getPermissionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.user.BoPermissionMap} returns this
 */
proto.user.BoPermissionMap.prototype.clearPermissionsMap = function() {
  this.getPermissionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBackOfficeUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBackOfficeUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBackOfficeUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBackOfficeUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.user.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBackOfficeUsersRequest}
 */
proto.user.GetBackOfficeUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBackOfficeUsersRequest;
  return proto.user.GetBackOfficeUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBackOfficeUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBackOfficeUsersRequest}
 */
proto.user.GetBackOfficeUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Filter;
      reader.readMessage(value,proto.user.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBackOfficeUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBackOfficeUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBackOfficeUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBackOfficeUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.user.Filter}
 */
proto.user.GetBackOfficeUsersRequest.prototype.getFilter = function() {
  return /** @type{?proto.user.Filter} */ (
    jspb.Message.getWrapperField(this, proto.user.Filter, 1));
};


/**
 * @param {?proto.user.Filter|undefined} value
 * @return {!proto.user.GetBackOfficeUsersRequest} returns this
*/
proto.user.GetBackOfficeUsersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBackOfficeUsersRequest} returns this
 */
proto.user.GetBackOfficeUsersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBackOfficeUsersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetBackOfficeUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBackOfficeUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBackOfficeUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBackOfficeUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBackOfficeUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    boUsersList: jspb.Message.toObjectList(msg.getBoUsersList(),
    proto.user.BackOfficeUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBackOfficeUsersResponse}
 */
proto.user.GetBackOfficeUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBackOfficeUsersResponse;
  return proto.user.GetBackOfficeUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBackOfficeUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBackOfficeUsersResponse}
 */
proto.user.GetBackOfficeUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.BackOfficeUser;
      reader.readMessage(value,proto.user.BackOfficeUser.deserializeBinaryFromReader);
      msg.addBoUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBackOfficeUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBackOfficeUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBackOfficeUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBackOfficeUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.BackOfficeUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BackOfficeUser bo_users = 1;
 * @return {!Array<!proto.user.BackOfficeUser>}
 */
proto.user.GetBackOfficeUsersResponse.prototype.getBoUsersList = function() {
  return /** @type{!Array<!proto.user.BackOfficeUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BackOfficeUser, 1));
};


/**
 * @param {!Array<!proto.user.BackOfficeUser>} value
 * @return {!proto.user.GetBackOfficeUsersResponse} returns this
*/
proto.user.GetBackOfficeUsersResponse.prototype.setBoUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.BackOfficeUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BackOfficeUser}
 */
proto.user.GetBackOfficeUsersResponse.prototype.addBoUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.BackOfficeUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetBackOfficeUsersResponse} returns this
 */
proto.user.GetBackOfficeUsersResponse.prototype.clearBoUsersList = function() {
  return this.setBoUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BackOfficeUser.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BackOfficeUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BackOfficeUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BackOfficeUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    permissionsMap: (f = msg.getPermissionsMap()) ? f.toObject(includeInstance, undefined) : [],
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BackOfficeUser}
 */
proto.user.BackOfficeUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BackOfficeUser;
  return proto.user.BackOfficeUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BackOfficeUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BackOfficeUser}
 */
proto.user.BackOfficeUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = msg.getPermissionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 6:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BackOfficeUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BackOfficeUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BackOfficeUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BackOfficeUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPermissionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.BackOfficeUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.user.BackOfficeUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.user.BackOfficeUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.user.BackOfficeUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.BackOfficeUser.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * map<string, bool> permissions = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.user.BackOfficeUser.prototype.getPermissionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.clearPermissionsMap = function() {
  this.getPermissionsMap().clear();
  return this;};


/**
 * optional common.Error error = 6;
 * @return {?proto.common.Error}
 */
proto.user.BackOfficeUser.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 6));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.BackOfficeUser} returns this
*/
proto.user.BackOfficeUser.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BackOfficeUser} returns this
 */
proto.user.BackOfficeUser.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BackOfficeUser.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetAgentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetAgentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetAgentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAgentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.user.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetAgentsRequest}
 */
proto.user.GetAgentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetAgentsRequest;
  return proto.user.GetAgentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetAgentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetAgentsRequest}
 */
proto.user.GetAgentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Filter;
      reader.readMessage(value,proto.user.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetAgentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetAgentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetAgentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAgentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.user.Filter}
 */
proto.user.GetAgentsRequest.prototype.getFilter = function() {
  return /** @type{?proto.user.Filter} */ (
    jspb.Message.getWrapperField(this, proto.user.Filter, 1));
};


/**
 * @param {?proto.user.Filter|undefined} value
 * @return {!proto.user.GetAgentsRequest} returns this
*/
proto.user.GetAgentsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetAgentsRequest} returns this
 */
proto.user.GetAgentsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetAgentsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetAgentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetAgentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetAgentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetAgentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAgentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentsList: jspb.Message.toObjectList(msg.getAgentsList(),
    proto.user.Agent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetAgentsResponse}
 */
proto.user.GetAgentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetAgentsResponse;
  return proto.user.GetAgentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetAgentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetAgentsResponse}
 */
proto.user.GetAgentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Agent;
      reader.readMessage(value,proto.user.Agent.deserializeBinaryFromReader);
      msg.addAgents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetAgentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetAgentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetAgentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAgentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.Agent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Agent agents = 1;
 * @return {!Array<!proto.user.Agent>}
 */
proto.user.GetAgentsResponse.prototype.getAgentsList = function() {
  return /** @type{!Array<!proto.user.Agent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.Agent, 1));
};


/**
 * @param {!Array<!proto.user.Agent>} value
 * @return {!proto.user.GetAgentsResponse} returns this
*/
proto.user.GetAgentsResponse.prototype.setAgentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.Agent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.Agent}
 */
proto.user.GetAgentsResponse.prototype.addAgents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.Agent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetAgentsResponse} returns this
 */
proto.user.GetAgentsResponse.prototype.clearAgentsList = function() {
  return this.setAgentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Agent.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Agent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Agent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Agent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    depositLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    commission: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    permissionsMap: (f = msg.getPermissionsMap()) ? f.toObject(includeInstance, undefined) : [],
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Agent}
 */
proto.user.Agent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Agent;
  return proto.user.Agent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Agent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Agent}
 */
proto.user.Agent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDepositLimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommission(value);
      break;
    case 8:
      var value = msg.getPermissionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 9:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Agent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Agent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Agent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Agent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDepositLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCommission();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getPermissionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.Agent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.user.Agent.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.user.Agent.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.user.Agent.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.Agent.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double deposit_limit = 6;
 * @return {number}
 */
proto.user.Agent.prototype.getDepositLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setDepositLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double commission = 7;
 * @return {number}
 */
proto.user.Agent.prototype.getCommission = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.setCommission = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * map<string, bool> permissions = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.user.Agent.prototype.getPermissionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.clearPermissionsMap = function() {
  this.getPermissionsMap().clear();
  return this;};


/**
 * optional common.Error error = 9;
 * @return {?proto.common.Error}
 */
proto.user.Agent.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 9));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.Agent} returns this
*/
proto.user.Agent.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.Agent} returns this
 */
proto.user.Agent.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Agent.prototype.hasError = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetAgentCommisionAndLimitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetAgentCommisionAndLimitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAgentCommisionAndLimitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    depositLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    commission: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currentSpent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetAgentCommisionAndLimitResponse}
 */
proto.user.GetAgentCommisionAndLimitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetAgentCommisionAndLimitResponse;
  return proto.user.GetAgentCommisionAndLimitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetAgentCommisionAndLimitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetAgentCommisionAndLimitResponse}
 */
proto.user.GetAgentCommisionAndLimitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDepositLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommission(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentSpent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetAgentCommisionAndLimitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetAgentCommisionAndLimitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAgentCommisionAndLimitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepositLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCommission();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrentSpent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double deposit_limit = 1;
 * @return {number}
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.getDepositLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetAgentCommisionAndLimitResponse} returns this
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.setDepositLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double commission = 2;
 * @return {number}
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.getCommission = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetAgentCommisionAndLimitResponse} returns this
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.setCommission = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double current_spent = 3;
 * @return {number}
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.getCurrentSpent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetAgentCommisionAndLimitResponse} returns this
 */
proto.user.GetAgentCommisionAndLimitResponse.prototype.setCurrentSpent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.AgentPayinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.AgentPayinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.AgentPayinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.AgentPayinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.AgentPayinRequest}
 */
proto.user.AgentPayinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.AgentPayinRequest;
  return proto.user.AgentPayinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.AgentPayinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.AgentPayinRequest}
 */
proto.user.AgentPayinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.AgentPayinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.AgentPayinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.AgentPayinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.AgentPayinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.AgentPayinRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.AgentPayinRequest} returns this
 */
proto.user.AgentPayinRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.user.AgentPayinRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.AgentPayinRequest} returns this
 */
proto.user.AgentPayinRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Employee.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Employee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Employee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Employee.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Employee}
 */
proto.user.Employee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Employee;
  return proto.user.Employee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Employee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Employee}
 */
proto.user.Employee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Employee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Employee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Employee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Employee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.Employee.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Employee} returns this
 */
proto.user.Employee.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.user.Employee.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Employee} returns this
 */
proto.user.Employee.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.user.Employee.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Employee} returns this
 */
proto.user.Employee.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.user.Employee.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Employee} returns this
 */
proto.user.Employee.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.Employee.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Employee} returns this
 */
proto.user.Employee.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.Error error = 6;
 * @return {?proto.common.Error}
 */
proto.user.Employee.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 6));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.Employee} returns this
*/
proto.user.Employee.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.Employee} returns this
 */
proto.user.Employee.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Employee.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetEmployeesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetEmployeesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetEmployeesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetEmployeesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.user.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetEmployeesRequest}
 */
proto.user.GetEmployeesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetEmployeesRequest;
  return proto.user.GetEmployeesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetEmployeesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetEmployeesRequest}
 */
proto.user.GetEmployeesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Filter;
      reader.readMessage(value,proto.user.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetEmployeesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetEmployeesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetEmployeesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetEmployeesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.user.Filter}
 */
proto.user.GetEmployeesRequest.prototype.getFilter = function() {
  return /** @type{?proto.user.Filter} */ (
    jspb.Message.getWrapperField(this, proto.user.Filter, 1));
};


/**
 * @param {?proto.user.Filter|undefined} value
 * @return {!proto.user.GetEmployeesRequest} returns this
*/
proto.user.GetEmployeesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetEmployeesRequest} returns this
 */
proto.user.GetEmployeesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetEmployeesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchString: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Filter}
 */
proto.user.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Filter;
  return proto.user.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Filter}
 */
proto.user.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_string = 1;
 * @return {string}
 */
proto.user.Filter.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Filter} returns this
 */
proto.user.Filter.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetEmployeesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetEmployeesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetEmployeesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetEmployeesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetEmployeesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    employeesList: jspb.Message.toObjectList(msg.getEmployeesList(),
    proto.user.Employee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetEmployeesResponse}
 */
proto.user.GetEmployeesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetEmployeesResponse;
  return proto.user.GetEmployeesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetEmployeesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetEmployeesResponse}
 */
proto.user.GetEmployeesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Employee;
      reader.readMessage(value,proto.user.Employee.deserializeBinaryFromReader);
      msg.addEmployees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetEmployeesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetEmployeesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetEmployeesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetEmployeesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.Employee.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Employee employees = 1;
 * @return {!Array<!proto.user.Employee>}
 */
proto.user.GetEmployeesResponse.prototype.getEmployeesList = function() {
  return /** @type{!Array<!proto.user.Employee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.Employee, 1));
};


/**
 * @param {!Array<!proto.user.Employee>} value
 * @return {!proto.user.GetEmployeesResponse} returns this
*/
proto.user.GetEmployeesResponse.prototype.setEmployeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.Employee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.Employee}
 */
proto.user.GetEmployeesResponse.prototype.addEmployees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.Employee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetEmployeesResponse} returns this
 */
proto.user.GetEmployeesResponse.prototype.clearEmployeesList = function() {
  return this.setEmployeesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingMachine.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingMachine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingMachine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachine.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bettingPlace: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isonisCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    identifier: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isTesting: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    external: jspb.Message.getFieldWithDefault(msg, 8, ""),
    display: jspb.Message.getFieldWithDefault(msg, 9, ""),
    system: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    saveUsername: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingMachine}
 */
proto.user.BettingMachine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingMachine;
  return proto.user.BettingMachine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingMachine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingMachine}
 */
proto.user.BettingMachine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlace(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsonisCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTesting(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternal(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplay(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystem(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaveUsername(value);
      break;
    case 11:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingMachine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingMachine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingMachine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBettingPlace();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsonisCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsTesting();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getExternal();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDisplay();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSystem();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSaveUsername();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.BettingMachine.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 role = 3;
 * @return {number}
 */
proto.user.BettingMachine.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 betting_place = 4;
 * @return {number}
 */
proto.user.BettingMachine.prototype.getBettingPlace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setBettingPlace = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string isonis_code = 5;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getIsonisCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setIsonisCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string serial_number = 12;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string identifier = 6;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_testing = 7;
 * @return {boolean}
 */
proto.user.BettingMachine.prototype.getIsTesting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setIsTesting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string external = 8;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getExternal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setExternal = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string display = 9;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getDisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setDisplay = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string system = 10;
 * @return {string}
 */
proto.user.BettingMachine.prototype.getSystem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setSystem = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_deleted = 13;
 * @return {boolean}
 */
proto.user.BettingMachine.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool save_username = 14;
 * @return {boolean}
 */
proto.user.BettingMachine.prototype.getSaveUsername = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.setSaveUsername = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional common.Error error = 11;
 * @return {?proto.common.Error}
 */
proto.user.BettingMachine.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 11));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.BettingMachine} returns this
*/
proto.user.BettingMachine.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BettingMachine} returns this
 */
proto.user.BettingMachine.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BettingMachine.prototype.hasError = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingMachinesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingMachinesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingMachinesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachinesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.user.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingMachinesRequest}
 */
proto.user.GetBettingMachinesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingMachinesRequest;
  return proto.user.GetBettingMachinesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingMachinesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingMachinesRequest}
 */
proto.user.GetBettingMachinesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Filter;
      reader.readMessage(value,proto.user.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingMachinesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingMachinesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingMachinesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachinesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.user.Filter}
 */
proto.user.GetBettingMachinesRequest.prototype.getFilter = function() {
  return /** @type{?proto.user.Filter} */ (
    jspb.Message.getWrapperField(this, proto.user.Filter, 1));
};


/**
 * @param {?proto.user.Filter|undefined} value
 * @return {!proto.user.GetBettingMachinesRequest} returns this
*/
proto.user.GetBettingMachinesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingMachinesRequest} returns this
 */
proto.user.GetBettingMachinesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingMachinesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetBettingMachinesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingMachinesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingMachinesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingMachinesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachinesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachinesList: jspb.Message.toObjectList(msg.getBettingMachinesList(),
    proto.user.BettingMachine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingMachinesResponse}
 */
proto.user.GetBettingMachinesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingMachinesResponse;
  return proto.user.GetBettingMachinesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingMachinesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingMachinesResponse}
 */
proto.user.GetBettingMachinesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.BettingMachine;
      reader.readMessage(value,proto.user.BettingMachine.deserializeBinaryFromReader);
      msg.addBettingMachines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingMachinesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingMachinesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingMachinesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachinesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.BettingMachine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BettingMachine betting_machines = 1;
 * @return {!Array<!proto.user.BettingMachine>}
 */
proto.user.GetBettingMachinesResponse.prototype.getBettingMachinesList = function() {
  return /** @type{!Array<!proto.user.BettingMachine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingMachine, 1));
};


/**
 * @param {!Array<!proto.user.BettingMachine>} value
 * @return {!proto.user.GetBettingMachinesResponse} returns this
*/
proto.user.GetBettingMachinesResponse.prototype.setBettingMachinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.BettingMachine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingMachine}
 */
proto.user.GetBettingMachinesResponse.prototype.addBettingMachines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.BettingMachine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetBettingMachinesResponse} returns this
 */
proto.user.GetBettingMachinesResponse.prototype.clearBettingMachinesList = function() {
  return this.setBettingMachinesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingMachineDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingMachineDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingMachineDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachineDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bettingMachine: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    manufacturerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingMachineDevice}
 */
proto.user.BettingMachineDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingMachineDevice;
  return proto.user.BettingMachineDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingMachineDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingMachineDevice}
 */
proto.user.BettingMachineDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingMachine(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingMachineDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingMachineDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingMachineDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachineDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBettingMachine();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getManufacturerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.BettingMachineDevice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.user.BettingMachineDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 betting_machine = 3;
 * @return {number}
 */
proto.user.BettingMachineDevice.prototype.getBettingMachine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setBettingMachine = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.user.BettingMachineDevice.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string manufacturer_id = 5;
 * @return {string}
 */
proto.user.BettingMachineDevice.prototype.getManufacturerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setManufacturerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_type = 6;
 * @return {string}
 */
proto.user.BettingMachineDevice.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string url = 7;
 * @return {string}
 */
proto.user.BettingMachineDevice.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional common.Error error = 8;
 * @return {?proto.common.Error}
 */
proto.user.BettingMachineDevice.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 8));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.BettingMachineDevice} returns this
*/
proto.user.BettingMachineDevice.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BettingMachineDevice} returns this
 */
proto.user.BettingMachineDevice.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BettingMachineDevice.prototype.hasError = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingMachineDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingMachineDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingMachineDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachineId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingMachineDevicesRequest}
 */
proto.user.GetBettingMachineDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingMachineDevicesRequest;
  return proto.user.GetBettingMachineDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingMachineDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingMachineDevicesRequest}
 */
proto.user.GetBettingMachineDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingMachineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingMachineDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingMachineDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingMachineDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachineId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 betting_machine_id = 1;
 * @return {number}
 */
proto.user.GetBettingMachineDevicesRequest.prototype.getBettingMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetBettingMachineDevicesRequest} returns this
 */
proto.user.GetBettingMachineDevicesRequest.prototype.setBettingMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetBettingMachineDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingMachineDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingMachineDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingMachineDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachineDevicesList: jspb.Message.toObjectList(msg.getBettingMachineDevicesList(),
    proto.user.BettingMachineDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingMachineDevicesResponse}
 */
proto.user.GetBettingMachineDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingMachineDevicesResponse;
  return proto.user.GetBettingMachineDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingMachineDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingMachineDevicesResponse}
 */
proto.user.GetBettingMachineDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.BettingMachineDevice;
      reader.readMessage(value,proto.user.BettingMachineDevice.deserializeBinaryFromReader);
      msg.addBettingMachineDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingMachineDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingMachineDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingMachineDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachineDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.BettingMachineDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BettingMachineDevice betting_machine_devices = 1;
 * @return {!Array<!proto.user.BettingMachineDevice>}
 */
proto.user.GetBettingMachineDevicesResponse.prototype.getBettingMachineDevicesList = function() {
  return /** @type{!Array<!proto.user.BettingMachineDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingMachineDevice, 1));
};


/**
 * @param {!Array<!proto.user.BettingMachineDevice>} value
 * @return {!proto.user.GetBettingMachineDevicesResponse} returns this
*/
proto.user.GetBettingMachineDevicesResponse.prototype.setBettingMachineDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.BettingMachineDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingMachineDevice}
 */
proto.user.GetBettingMachineDevicesResponse.prototype.addBettingMachineDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.BettingMachineDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetBettingMachineDevicesResponse} returns this
 */
proto.user.GetBettingMachineDevicesResponse.prototype.clearBettingMachineDevicesList = function() {
  return this.setBettingMachineDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingMachineExternalLink.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingMachineExternalLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingMachineExternalLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachineExternalLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bettingMachineId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    port: jspb.Message.getFieldWithDefault(msg, 5, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingMachineExternalLink}
 */
proto.user.BettingMachineExternalLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingMachineExternalLink;
  return proto.user.BettingMachineExternalLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingMachineExternalLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingMachineExternalLink}
 */
proto.user.BettingMachineExternalLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingMachineId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    case 6:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingMachineExternalLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingMachineExternalLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingMachineExternalLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachineExternalLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBettingMachineId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.user.BettingMachineExternalLink.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineExternalLink} returns this
 */
proto.user.BettingMachineExternalLink.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.user.BettingMachineExternalLink.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineExternalLink} returns this
 */
proto.user.BettingMachineExternalLink.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.user.BettingMachineExternalLink.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachineExternalLink} returns this
 */
proto.user.BettingMachineExternalLink.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 betting_machine_id = 4;
 * @return {number}
 */
proto.user.BettingMachineExternalLink.prototype.getBettingMachineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingMachineExternalLink} returns this
 */
proto.user.BettingMachineExternalLink.prototype.setBettingMachineId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string port = 5;
 * @return {string}
 */
proto.user.BettingMachineExternalLink.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingMachineExternalLink} returns this
 */
proto.user.BettingMachineExternalLink.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional common.Error error = 6;
 * @return {?proto.common.Error}
 */
proto.user.BettingMachineExternalLink.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 6));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.BettingMachineExternalLink} returns this
*/
proto.user.BettingMachineExternalLink.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BettingMachineExternalLink} returns this
 */
proto.user.BettingMachineExternalLink.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BettingMachineExternalLink.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.BettingMachineExternalLinks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingMachineExternalLinks.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingMachineExternalLinks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingMachineExternalLinks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachineExternalLinks.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalLinksList: jspb.Message.toObjectList(msg.getExternalLinksList(),
    proto.user.BettingMachineExternalLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingMachineExternalLinks}
 */
proto.user.BettingMachineExternalLinks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingMachineExternalLinks;
  return proto.user.BettingMachineExternalLinks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingMachineExternalLinks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingMachineExternalLinks}
 */
proto.user.BettingMachineExternalLinks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.BettingMachineExternalLink;
      reader.readMessage(value,proto.user.BettingMachineExternalLink.deserializeBinaryFromReader);
      msg.addExternalLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingMachineExternalLinks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingMachineExternalLinks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingMachineExternalLinks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingMachineExternalLinks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.BettingMachineExternalLink.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BettingMachineExternalLink external_links = 1;
 * @return {!Array<!proto.user.BettingMachineExternalLink>}
 */
proto.user.BettingMachineExternalLinks.prototype.getExternalLinksList = function() {
  return /** @type{!Array<!proto.user.BettingMachineExternalLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingMachineExternalLink, 1));
};


/**
 * @param {!Array<!proto.user.BettingMachineExternalLink>} value
 * @return {!proto.user.BettingMachineExternalLinks} returns this
*/
proto.user.BettingMachineExternalLinks.prototype.setExternalLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.BettingMachineExternalLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingMachineExternalLink}
 */
proto.user.BettingMachineExternalLinks.prototype.addExternalLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.BettingMachineExternalLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.BettingMachineExternalLinks} returns this
 */
proto.user.BettingMachineExternalLinks.prototype.clearExternalLinksList = function() {
  return this.setExternalLinksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.CrediteeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CrediteeList.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CrediteeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CrediteeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CrediteeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    crediteesList: jspb.Message.toObjectList(msg.getCrediteesList(),
    proto.user.Creditee.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CrediteeList}
 */
proto.user.CrediteeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CrediteeList;
  return proto.user.CrediteeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CrediteeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CrediteeList}
 */
proto.user.CrediteeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.Creditee;
      reader.readMessage(value,proto.user.Creditee.deserializeBinaryFromReader);
      msg.addCreditees(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CrediteeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CrediteeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CrediteeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CrediteeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrediteesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.Creditee.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Creditee creditees = 1;
 * @return {!Array<!proto.user.Creditee>}
 */
proto.user.CrediteeList.prototype.getCrediteesList = function() {
  return /** @type{!Array<!proto.user.Creditee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.Creditee, 1));
};


/**
 * @param {!Array<!proto.user.Creditee>} value
 * @return {!proto.user.CrediteeList} returns this
*/
proto.user.CrediteeList.prototype.setCrediteesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.Creditee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.Creditee}
 */
proto.user.CrediteeList.prototype.addCreditees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.Creditee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.CrediteeList} returns this
 */
proto.user.CrediteeList.prototype.clearCrediteesList = function() {
  return this.setCrediteesList([]);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.CrediteeList.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.CrediteeList} returns this
*/
proto.user.CrediteeList.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CrediteeList} returns this
 */
proto.user.CrediteeList.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CrediteeList.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Creditee.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Creditee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Creditee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Creditee.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platformUserId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Creditee}
 */
proto.user.Creditee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Creditee;
  return proto.user.Creditee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Creditee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Creditee}
 */
proto.user.Creditee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlatformUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Creditee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Creditee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Creditee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Creditee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatformUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.user.Creditee.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Creditee} returns this
 */
proto.user.Creditee.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.user.Creditee.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Creditee} returns this
 */
proto.user.Creditee.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 platform_user_id = 3;
 * @return {number}
 */
proto.user.Creditee.prototype.getPlatformUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Creditee} returns this
 */
proto.user.Creditee.prototype.setPlatformUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.user.User.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.UserResponse}
 */
proto.user.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.UserResponse;
  return proto.user.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.UserResponse}
 */
proto.user.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 18:
      var value = new proto.user.User;
      reader.readMessage(value,proto.user.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.user.User.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 18;
 * @return {?proto.user.User}
 */
proto.user.UserResponse.prototype.getUser = function() {
  return /** @type{?proto.user.User} */ (
    jspb.Message.getWrapperField(this, proto.user.User, 18));
};


/**
 * @param {?proto.user.User|undefined} value
 * @return {!proto.user.UserResponse} returns this
*/
proto.user.UserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserResponse} returns this
 */
proto.user.UserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.UserResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.UserResponse} returns this
*/
proto.user.UserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserResponse} returns this
 */
proto.user.UserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.User.prototype.toObject = function(opt_includeInstance) {
  return proto.user.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 72, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.User}
 */
proto.user.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.User;
  return proto.user.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.User}
 */
proto.user.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeInt32(
      72,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.user.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.user.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.user.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 role_id = 72;
 * @return {number}
 */
proto.user.User.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 72, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.User} returns this
 */
proto.user.User.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 72, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    repeatedPassword: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CreateUserRequest}
 */
proto.user.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CreateUserRequest;
  return proto.user.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CreateUserRequest}
 */
proto.user.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatedPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRepeatedPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.user.CreateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreateUserRequest} returns this
 */
proto.user.CreateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.CreateUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreateUserRequest} returns this
 */
proto.user.CreateUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string repeated_password = 7;
 * @return {string}
 */
proto.user.CreateUserRequest.prototype.getRepeatedPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreateUserRequest} returns this
 */
proto.user.CreateUserRequest.prototype.setRepeatedPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CreatePlatformUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CreatePlatformUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CreatePlatformUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CreatePlatformUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repeatedEmail: jspb.Message.getFieldWithDefault(msg, 34, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    repeatedPassword: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 25, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 26, ""),
    country: jspb.Message.getFieldWithDefault(msg, 27, ""),
    city: jspb.Message.getFieldWithDefault(msg, 28, ""),
    ssn: jspb.Message.getFieldWithDefault(msg, 31, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 32, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 33, ""),
    isGovernmentOfficial: jspb.Message.getFieldWithDefault(msg, 29, ""),
    termsAndConditions: jspb.Message.getFieldWithDefault(msg, 30, ""),
    subscribed: jspb.Message.getFieldWithDefault(msg, 36, ""),
    dateOfBirth: (f = msg.getDateOfBirth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CreatePlatformUserRequest}
 */
proto.user.CreatePlatformUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CreatePlatformUserRequest;
  return proto.user.CreatePlatformUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CreatePlatformUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CreatePlatformUserRequest}
 */
proto.user.CreatePlatformUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatedEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatedPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsn(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsGovernmentOfficial(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTermsAndConditions(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscribed(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateOfBirth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CreatePlatformUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CreatePlatformUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CreatePlatformUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CreatePlatformUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepeatedEmail();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRepeatedPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getSsn();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getIsGovernmentOfficial();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTermsAndConditions();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getSubscribed();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string repeated_email = 34;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getRepeatedEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setRepeatedEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string repeated_password = 7;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getRepeatedPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setRepeatedPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bio = 8;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string gender = 25;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string nickname = 26;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string country = 27;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string city = 28;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string ssn = 31;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getSsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setSsn = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string phone_number = 32;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string account_number = 33;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string is_government_official = 29;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getIsGovernmentOfficial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setIsGovernmentOfficial = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string terms_and_conditions = 30;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getTermsAndConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setTermsAndConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string subscribed = 36;
 * @return {string}
 */
proto.user.CreatePlatformUserRequest.prototype.getSubscribed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.setSubscribed = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional google.protobuf.Timestamp date_of_birth = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.user.CreatePlatformUserRequest.prototype.getDateOfBirth = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.user.CreatePlatformUserRequest} returns this
*/
proto.user.CreatePlatformUserRequest.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CreatePlatformUserRequest} returns this
 */
proto.user.CreatePlatformUserRequest.prototype.clearDateOfBirth = function() {
  return this.setDateOfBirth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CreatePlatformUserRequest.prototype.hasDateOfBirth = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetUserByEmailAndPasswordRequest.repeatedFields_ = [40];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserByEmailAndPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserByEmailAndPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserByEmailAndPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceCode: jspb.Message.getFieldWithDefault(msg, 38, ""),
    roleIdsList: (f = jspb.Message.getRepeatedField(msg, 40)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserByEmailAndPasswordRequest}
 */
proto.user.GetUserByEmailAndPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserByEmailAndPasswordRequest;
  return proto.user.GetUserByEmailAndPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserByEmailAndPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserByEmailAndPasswordRequest}
 */
proto.user.GetUserByEmailAndPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    case 40:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoleIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserByEmailAndPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserByEmailAndPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserByEmailAndPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getRoleIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      40,
      f
    );
  }
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetUserByEmailAndPasswordRequest} returns this
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetUserByEmailAndPasswordRequest} returns this
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_code = 38;
 * @return {string}
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetUserByEmailAndPasswordRequest} returns this
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * repeated int32 role_ids = 40;
 * @return {!Array<number>}
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.getRoleIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 40));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.user.GetUserByEmailAndPasswordRequest} returns this
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.setRoleIdsList = function(value) {
  return jspb.Message.setField(this, 40, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.user.GetUserByEmailAndPasswordRequest} returns this
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.addRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 40, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetUserByEmailAndPasswordRequest} returns this
 */
proto.user.GetUserByEmailAndPasswordRequest.prototype.clearRoleIdsList = function() {
  return this.setRoleIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserByEmailAndPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserByEmailAndPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserByEmailAndPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 11, ""),
    user: (f = msg.getUser()) && proto.user.User.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserByEmailAndPasswordResponse}
 */
proto.user.GetUserByEmailAndPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserByEmailAndPasswordResponse;
  return proto.user.GetUserByEmailAndPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserByEmailAndPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserByEmailAndPasswordResponse}
 */
proto.user.GetUserByEmailAndPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 18:
      var value = new proto.user.User;
      reader.readMessage(value,proto.user.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserByEmailAndPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserByEmailAndPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserByEmailAndPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.user.User.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 11;
 * @return {string}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetUserByEmailAndPasswordResponse} returns this
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional User user = 18;
 * @return {?proto.user.User}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.getUser = function() {
  return /** @type{?proto.user.User} */ (
    jspb.Message.getWrapperField(this, proto.user.User, 18));
};


/**
 * @param {?proto.user.User|undefined} value
 * @return {!proto.user.GetUserByEmailAndPasswordResponse} returns this
*/
proto.user.GetUserByEmailAndPasswordResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetUserByEmailAndPasswordResponse} returns this
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.GetUserByEmailAndPasswordResponse} returns this
*/
proto.user.GetUserByEmailAndPasswordResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetUserByEmailAndPasswordResponse} returns this
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetUserByEmailAndPasswordResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingMachineByUsernameAndTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingMachineByUsernameAndTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenRequest}
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingMachineByUsernameAndTokenRequest;
  return proto.user.GetBettingMachineByUsernameAndTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingMachineByUsernameAndTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenRequest}
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingMachineByUsernameAndTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingMachineByUsernameAndTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenRequest} returns this
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenRequest} returns this
 */
proto.user.GetBettingMachineByUsernameAndTokenRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingMachineByUsernameAndTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingMachineByUsernameAndTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 11, ""),
    blockedActions: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bettingMachine: (f = msg.getBettingMachine()) && proto.user.BettingMachine.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingMachineByUsernameAndTokenResponse;
  return proto.user.GetBettingMachineByUsernameAndTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingMachineByUsernameAndTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedActions(value);
      break;
    case 18:
      var value = new proto.user.BettingMachine;
      reader.readMessage(value,proto.user.BettingMachine.deserializeBinaryFromReader);
      msg.setBettingMachine(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingMachineByUsernameAndTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingMachineByUsernameAndTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBlockedActions();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBettingMachine();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.user.BettingMachine.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 11;
 * @return {string}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse} returns this
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string blocked_actions = 2;
 * @return {string}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.getBlockedActions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse} returns this
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.setBlockedActions = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BettingMachine betting_machine = 18;
 * @return {?proto.user.BettingMachine}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.getBettingMachine = function() {
  return /** @type{?proto.user.BettingMachine} */ (
    jspb.Message.getWrapperField(this, proto.user.BettingMachine, 18));
};


/**
 * @param {?proto.user.BettingMachine|undefined} value
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse} returns this
*/
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.setBettingMachine = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse} returns this
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.clearBettingMachine = function() {
  return this.setBettingMachine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.hasBettingMachine = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse} returns this
*/
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingMachineByUsernameAndTokenResponse} returns this
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingMachineByUsernameAndTokenResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 37, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserRequest}
 */
proto.user.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserRequest;
  return proto.user.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserRequest}
 */
proto.user.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      37,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.GetUserRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetUserRequest} returns this
 */
proto.user.GetUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 betting_place_id = 37;
 * @return {number}
 */
proto.user.GetUserRequest.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetUserRequest} returns this
 */
proto.user.GetUserRequest.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 7, ""),
    birthDate: (f = msg.getBirthDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.UpdateUserRequest}
 */
proto.user.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.UpdateUserRequest;
  return proto.user.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.UpdateUserRequest}
 */
proto.user.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBirthDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBirthDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.UpdateUserRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bio = 7;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp birth_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.user.UpdateUserRequest.prototype.getBirthDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.user.UpdateUserRequest} returns this
*/
proto.user.UpdateUserRequest.prototype.setBirthDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.clearBirthDate = function() {
  return this.setBirthDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UpdateUserRequest.prototype.hasBirthDate = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 34, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 35, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ListUsersRequest}
 */
proto.user.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ListUsersRequest;
  return proto.user.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ListUsersRequest}
 */
proto.user.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 34:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      34,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      35,
      f
    );
  }
};


/**
 * optional int32 page_size = 34;
 * @return {number}
 */
proto.user.ListUsersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ListUsersRequest} returns this
 */
proto.user.ListUsersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional int32 page_number = 35;
 * @return {number}
 */
proto.user.ListUsersRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ListUsersRequest} returns this
 */
proto.user.ListUsersRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.ListUsersResponse.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalPages: jspb.Message.getFieldWithDefault(msg, 13, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.user.User.toObject, includeInstance),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ListUsersResponse}
 */
proto.user.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ListUsersResponse;
  return proto.user.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ListUsersResponse}
 */
proto.user.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 14:
      var value = new proto.user.User;
      reader.readMessage(value,proto.user.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.user.User.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_pages = 13;
 * @return {number}
 */
proto.user.ListUsersResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ListUsersResponse} returns this
 */
proto.user.ListUsersResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated User users = 14;
 * @return {!Array<!proto.user.User>}
 */
proto.user.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.user.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.User, 14));
};


/**
 * @param {!Array<!proto.user.User>} value
 * @return {!proto.user.ListUsersResponse} returns this
*/
proto.user.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.user.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.User}
 */
proto.user.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.user.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.ListUsersResponse} returns this
 */
proto.user.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.ListUsersResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.ListUsersResponse} returns this
*/
proto.user.ListUsersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ListUsersResponse} returns this
 */
proto.user.ListUsersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ListUsersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ReserveFundsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ReserveFundsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ReserveFundsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReserveFundsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ReserveFundsRequest}
 */
proto.user.ReserveFundsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ReserveFundsRequest;
  return proto.user.ReserveFundsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ReserveFundsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ReserveFundsRequest}
 */
proto.user.ReserveFundsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ReserveFundsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ReserveFundsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ReserveFundsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReserveFundsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
};


/**
 * optional string slip_id = 15;
 * @return {string}
 */
proto.user.ReserveFundsRequest.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.ReserveFundsRequest} returns this
 */
proto.user.ReserveFundsRequest.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 user_id = 16;
 * @return {number}
 */
proto.user.ReserveFundsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ReserveFundsRequest} returns this
 */
proto.user.ReserveFundsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double amount = 17;
 * @return {number}
 */
proto.user.ReserveFundsRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.ReserveFundsRequest} returns this
 */
proto.user.ReserveFundsRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ReserveFundsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ReserveFundsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ReserveFundsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReserveFundsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 21, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ReserveFundsResponse}
 */
proto.user.ReserveFundsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ReserveFundsResponse;
  return proto.user.ReserveFundsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ReserveFundsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ReserveFundsResponse}
 */
proto.user.ReserveFundsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreator(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ReserveFundsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ReserveFundsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ReserveFundsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReserveFundsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 betting_place_id = 19;
 * @return {number}
 */
proto.user.ReserveFundsResponse.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ReserveFundsResponse} returns this
 */
proto.user.ReserveFundsResponse.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 account_id = 20;
 * @return {number}
 */
proto.user.ReserveFundsResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ReserveFundsResponse} returns this
 */
proto.user.ReserveFundsResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 creator = 21;
 * @return {number}
 */
proto.user.ReserveFundsResponse.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ReserveFundsResponse} returns this
 */
proto.user.ReserveFundsResponse.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.ReserveFundsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.ReserveFundsResponse} returns this
*/
proto.user.ReserveFundsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ReserveFundsResponse} returns this
 */
proto.user.ReserveFundsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ReserveFundsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ReleaseFundsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ReleaseFundsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ReleaseFundsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReleaseFundsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ReleaseFundsRequest}
 */
proto.user.ReleaseFundsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ReleaseFundsRequest;
  return proto.user.ReleaseFundsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ReleaseFundsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ReleaseFundsRequest}
 */
proto.user.ReleaseFundsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ReleaseFundsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ReleaseFundsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ReleaseFundsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReleaseFundsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional string slip_id = 15;
 * @return {string}
 */
proto.user.ReleaseFundsRequest.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.ReleaseFundsRequest} returns this
 */
proto.user.ReleaseFundsRequest.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 user_id = 16;
 * @return {number}
 */
proto.user.ReleaseFundsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ReleaseFundsRequest} returns this
 */
proto.user.ReleaseFundsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ReleaseFundsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ReleaseFundsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ReleaseFundsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReleaseFundsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ReleaseFundsResponse}
 */
proto.user.ReleaseFundsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ReleaseFundsResponse;
  return proto.user.ReleaseFundsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ReleaseFundsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ReleaseFundsResponse}
 */
proto.user.ReleaseFundsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ReleaseFundsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ReleaseFundsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ReleaseFundsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ReleaseFundsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.ReleaseFundsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.ReleaseFundsResponse} returns this
*/
proto.user.ReleaseFundsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ReleaseFundsResponse} returns this
 */
proto.user.ReleaseFundsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ReleaseFundsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ConfirmFundsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ConfirmFundsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ConfirmFundsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ConfirmFundsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ConfirmFundsResponse}
 */
proto.user.ConfirmFundsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ConfirmFundsResponse;
  return proto.user.ConfirmFundsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ConfirmFundsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ConfirmFundsResponse}
 */
proto.user.ConfirmFundsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ConfirmFundsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ConfirmFundsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ConfirmFundsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ConfirmFundsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.ConfirmFundsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.ConfirmFundsResponse} returns this
*/
proto.user.ConfirmFundsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ConfirmFundsResponse} returns this
 */
proto.user.ConfirmFundsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ConfirmFundsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingPlacesBoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingPlacesBoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingPlacesBoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlacesBoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingPlacesBoRequest}
 */
proto.user.GetBettingPlacesBoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingPlacesBoRequest;
  return proto.user.GetBettingPlacesBoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingPlacesBoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingPlacesBoRequest}
 */
proto.user.GetBettingPlacesBoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingPlacesBoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingPlacesBoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingPlacesBoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlacesBoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetBettingPlacesBoResponse.repeatedFields_ = [23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingPlacesBoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingPlacesBoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingPlacesBoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlacesBoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlacesList: jspb.Message.toObjectList(msg.getBettingPlacesList(),
    proto.user.BettingPlace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingPlacesBoResponse}
 */
proto.user.GetBettingPlacesBoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingPlacesBoResponse;
  return proto.user.GetBettingPlacesBoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingPlacesBoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingPlacesBoResponse}
 */
proto.user.GetBettingPlacesBoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 23:
      var value = new proto.user.BettingPlace;
      reader.readMessage(value,proto.user.BettingPlace.deserializeBinaryFromReader);
      msg.addBettingPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingPlacesBoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingPlacesBoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingPlacesBoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlacesBoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.user.BettingPlace.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BettingPlace betting_places = 23;
 * @return {!Array<!proto.user.BettingPlace>}
 */
proto.user.GetBettingPlacesBoResponse.prototype.getBettingPlacesList = function() {
  return /** @type{!Array<!proto.user.BettingPlace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingPlace, 23));
};


/**
 * @param {!Array<!proto.user.BettingPlace>} value
 * @return {!proto.user.GetBettingPlacesBoResponse} returns this
*/
proto.user.GetBettingPlacesBoResponse.prototype.setBettingPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.user.BettingPlace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingPlace}
 */
proto.user.GetBettingPlacesBoResponse.prototype.addBettingPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.user.BettingPlace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetBettingPlacesBoResponse} returns this
 */
proto.user.GetBettingPlacesBoResponse.prototype.clearBettingPlacesList = function() {
  return this.setBettingPlacesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.BettingPlace.repeatedFields_ = [62];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    address: jspb.Message.getFieldWithDefault(msg, 85, ""),
    municipalityName: jspb.Message.getFieldWithDefault(msg, 58, ""),
    code: jspb.Message.getFieldWithDefault(msg, 83, ""),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.user.BettingPlaceDevice.toObject, includeInstance),
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 86, false),
    isTesting: jspb.Message.getBooleanFieldWithDefault(msg, 80, false),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
    allowDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    limitLiveBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    limitMixBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    limitPrematchBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    blockedActions: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingPlace}
 */
proto.user.BettingPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingPlace;
  return proto.user.BettingPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingPlace}
 */
proto.user.BettingPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipalityName(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 62:
      var value = new proto.user.BettingPlaceDevice;
      reader.readMessage(value,proto.user.BettingPlaceDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 86:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    case 80:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTesting(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeposit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitLiveBm(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitMixBm(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitPrematchBm(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getMunicipalityName();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      83,
      f
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      62,
      f,
      proto.user.BettingPlaceDevice.serializeBinaryToWriter
    );
  }
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      86,
      f
    );
  }
  f = message.getIsTesting();
  if (f) {
    writer.writeBool(
      80,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getAllowDeposit();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLimitLiveBm();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLimitMixBm();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLimitPrematchBm();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getBlockedActions();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.BettingPlace.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.BettingPlace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string address = 85;
 * @return {string}
 */
proto.user.BettingPlace.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional string municipality_name = 58;
 * @return {string}
 */
proto.user.BettingPlace.prototype.getMunicipalityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setMunicipalityName = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string code = 83;
 * @return {string}
 */
proto.user.BettingPlace.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 83, value);
};


/**
 * repeated BettingPlaceDevice devices = 62;
 * @return {!Array<!proto.user.BettingPlaceDevice>}
 */
proto.user.BettingPlace.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.user.BettingPlaceDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingPlaceDevice, 62));
};


/**
 * @param {!Array<!proto.user.BettingPlaceDevice>} value
 * @return {!proto.user.BettingPlace} returns this
*/
proto.user.BettingPlace.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 62, value);
};


/**
 * @param {!proto.user.BettingPlaceDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingPlaceDevice}
 */
proto.user.BettingPlace.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 62, opt_value, proto.user.BettingPlaceDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional bool is_online = 86;
 * @return {boolean}
 */
proto.user.BettingPlace.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 86, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 86, value);
};


/**
 * optional bool is_testing = 80;
 * @return {boolean}
 */
proto.user.BettingPlace.prototype.getIsTesting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 80, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setIsTesting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 80, value);
};


/**
 * optional bool is_active = 81;
 * @return {boolean}
 */
proto.user.BettingPlace.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * optional bool allow_deposit = 7;
 * @return {boolean}
 */
proto.user.BettingPlace.prototype.getAllowDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setAllowDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional double limit_live_bm = 9;
 * @return {number}
 */
proto.user.BettingPlace.prototype.getLimitLiveBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setLimitLiveBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double limit_mix_bm = 10;
 * @return {number}
 */
proto.user.BettingPlace.prototype.getLimitMixBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setLimitMixBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double limit_prematch_bm = 11;
 * @return {number}
 */
proto.user.BettingPlace.prototype.getLimitPrematchBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setLimitPrematchBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string blocked_actions = 12;
 * @return {string}
 */
proto.user.BettingPlace.prototype.getBlockedActions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlace} returns this
 */
proto.user.BettingPlace.prototype.setBlockedActions = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.NewBettingPlace.prototype.toObject = function(opt_includeInstance) {
  return proto.user.NewBettingPlace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.NewBettingPlace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.NewBettingPlace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    municipalityName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTesting: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    allowDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    limitLiveBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    limitMixBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    limitPrematchBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    blockedActions: jspb.Message.getFieldWithDefault(msg, 12, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.NewBettingPlace}
 */
proto.user.NewBettingPlace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.NewBettingPlace;
  return proto.user.NewBettingPlace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.NewBettingPlace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.NewBettingPlace}
 */
proto.user.NewBettingPlace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipalityName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTesting(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeposit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitLiveBm(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitMixBm(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitPrematchBm(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedActions(value);
      break;
    case 13:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.NewBettingPlace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.NewBettingPlace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.NewBettingPlace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.NewBettingPlace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMunicipalityName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTesting();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAllowDeposit();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLimitLiveBm();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLimitMixBm();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLimitPrematchBm();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getBlockedActions();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.NewBettingPlace.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.user.NewBettingPlace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.user.NewBettingPlace.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string municipality_name = 4;
 * @return {string}
 */
proto.user.NewBettingPlace.prototype.getMunicipalityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setMunicipalityName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.user.NewBettingPlace.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_online = 6;
 * @return {boolean}
 */
proto.user.NewBettingPlace.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_testing = 7;
 * @return {boolean}
 */
proto.user.NewBettingPlace.prototype.getIsTesting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setIsTesting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool allow_deposit = 8;
 * @return {boolean}
 */
proto.user.NewBettingPlace.prototype.getAllowDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setAllowDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional double limit_live_bm = 9;
 * @return {number}
 */
proto.user.NewBettingPlace.prototype.getLimitLiveBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setLimitLiveBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double limit_mix_bm = 10;
 * @return {number}
 */
proto.user.NewBettingPlace.prototype.getLimitMixBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setLimitMixBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double limit_prematch_bm = 11;
 * @return {number}
 */
proto.user.NewBettingPlace.prototype.getLimitPrematchBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setLimitPrematchBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string blocked_actions = 12;
 * @return {string}
 */
proto.user.NewBettingPlace.prototype.getBlockedActions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.setBlockedActions = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional common.Error error = 13;
 * @return {?proto.common.Error}
 */
proto.user.NewBettingPlace.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 13));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.NewBettingPlace} returns this
*/
proto.user.NewBettingPlace.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.NewBettingPlace} returns this
 */
proto.user.NewBettingPlace.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.NewBettingPlace.prototype.hasError = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.NewBettingPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.NewBettingPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.NewBettingPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.NewBettingPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    municipalityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isTesting: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    allowDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    limitLiveBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    limitMixBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    limitPrematchBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.NewBettingPlaceRequest}
 */
proto.user.NewBettingPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.NewBettingPlaceRequest;
  return proto.user.NewBettingPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.NewBettingPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.NewBettingPlaceRequest}
 */
proto.user.NewBettingPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMunicipalityId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTesting(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeposit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitLiveBm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitMixBm(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitPrematchBm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.NewBettingPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.NewBettingPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.NewBettingPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.NewBettingPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMunicipalityId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsTesting();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAllowDeposit();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLimitLiveBm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLimitMixBm();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLimitPrematchBm();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.user.NewBettingPlaceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.user.NewBettingPlaceRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 municipality_id = 3;
 * @return {number}
 */
proto.user.NewBettingPlaceRequest.prototype.getMunicipalityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setMunicipalityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_testing = 4;
 * @return {boolean}
 */
proto.user.NewBettingPlaceRequest.prototype.getIsTesting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setIsTesting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.user.NewBettingPlaceRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool allow_deposit = 6;
 * @return {boolean}
 */
proto.user.NewBettingPlaceRequest.prototype.getAllowDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setAllowDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional double limit_live_bm = 7;
 * @return {number}
 */
proto.user.NewBettingPlaceRequest.prototype.getLimitLiveBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setLimitLiveBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double limit_mix_bm = 8;
 * @return {number}
 */
proto.user.NewBettingPlaceRequest.prototype.getLimitMixBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setLimitMixBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double limit_prematch_bm = 9;
 * @return {number}
 */
proto.user.NewBettingPlaceRequest.prototype.getLimitPrematchBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.NewBettingPlaceRequest} returns this
 */
proto.user.NewBettingPlaceRequest.prototype.setLimitPrematchBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.EditBettingPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.EditBettingPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.EditBettingPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.EditBettingPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    municipalityId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isTesting: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    code: jspb.Message.getFieldWithDefault(msg, 7, ""),
    allowDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    limitLiveBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    limitMixBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    limitPrematchBm: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    blockedActions: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.EditBettingPlaceRequest}
 */
proto.user.EditBettingPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.EditBettingPlaceRequest;
  return proto.user.EditBettingPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.EditBettingPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.EditBettingPlaceRequest}
 */
proto.user.EditBettingPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMunicipalityId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTesting(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeposit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitLiveBm(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitMixBm(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimitPrematchBm(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.EditBettingPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.EditBettingPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.EditBettingPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.EditBettingPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMunicipalityId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsTesting();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAllowDeposit();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLimitLiveBm();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getLimitMixBm();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLimitPrematchBm();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getBlockedActions();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.EditBettingPlaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.user.EditBettingPlaceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.user.EditBettingPlaceRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 municipality_id = 4;
 * @return {number}
 */
proto.user.EditBettingPlaceRequest.prototype.getMunicipalityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setMunicipalityId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_testing = 5;
 * @return {boolean}
 */
proto.user.EditBettingPlaceRequest.prototype.getIsTesting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setIsTesting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_online = 6;
 * @return {boolean}
 */
proto.user.EditBettingPlaceRequest.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string code = 7;
 * @return {string}
 */
proto.user.EditBettingPlaceRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool allow_deposit = 8;
 * @return {boolean}
 */
proto.user.EditBettingPlaceRequest.prototype.getAllowDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setAllowDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional double limit_live_bm = 9;
 * @return {number}
 */
proto.user.EditBettingPlaceRequest.prototype.getLimitLiveBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setLimitLiveBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double limit_mix_bm = 10;
 * @return {number}
 */
proto.user.EditBettingPlaceRequest.prototype.getLimitMixBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setLimitMixBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double limit_prematch_bm = 11;
 * @return {number}
 */
proto.user.EditBettingPlaceRequest.prototype.getLimitPrematchBm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setLimitPrematchBm = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string blocked_actions = 12;
 * @return {string}
 */
proto.user.EditBettingPlaceRequest.prototype.getBlockedActions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.EditBettingPlaceRequest} returns this
 */
proto.user.EditBettingPlaceRequest.prototype.setBlockedActions = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingPlaceDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingPlaceDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingPlaceDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    doublePrint: jspb.Message.getBooleanFieldWithDefault(msg, 84, false),
    blankSlip: jspb.Message.getBooleanFieldWithDefault(msg, 89, false),
    notReporting: jspb.Message.getBooleanFieldWithDefault(msg, 90, false),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingPlaceDevice}
 */
proto.user.BettingPlaceDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingPlaceDevice;
  return proto.user.BettingPlaceDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingPlaceDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingPlaceDevice}
 */
proto.user.BettingPlaceDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 84:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoublePrint(value);
      break;
    case 89:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlankSlip(value);
      break;
    case 90:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotReporting(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingPlaceDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingPlaceDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingPlaceDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDoublePrint();
  if (f) {
    writer.writeBool(
      84,
      f
    );
  }
  f = message.getBlankSlip();
  if (f) {
    writer.writeBool(
      89,
      f
    );
  }
  f = message.getNotReporting();
  if (f) {
    writer.writeBool(
      90,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.BettingPlaceDevice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlaceDevice} returns this
 */
proto.user.BettingPlaceDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.BettingPlaceDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlaceDevice} returns this
 */
proto.user.BettingPlaceDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool double_print = 84;
 * @return {boolean}
 */
proto.user.BettingPlaceDevice.prototype.getDoublePrint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 84, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlaceDevice} returns this
 */
proto.user.BettingPlaceDevice.prototype.setDoublePrint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 84, value);
};


/**
 * optional bool blank_slip = 89;
 * @return {boolean}
 */
proto.user.BettingPlaceDevice.prototype.getBlankSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 89, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlaceDevice} returns this
 */
proto.user.BettingPlaceDevice.prototype.setBlankSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 89, value);
};


/**
 * optional bool not_reporting = 90;
 * @return {boolean}
 */
proto.user.BettingPlaceDevice.prototype.getNotReporting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 90, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlaceDevice} returns this
 */
proto.user.BettingPlaceDevice.prototype.setNotReporting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 90, value);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.BettingPlaceDevice.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.BettingPlaceDevice} returns this
*/
proto.user.BettingPlaceDevice.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.BettingPlaceDevice} returns this
 */
proto.user.BettingPlaceDevice.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.BettingPlaceDevice.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingPlaceDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingPlaceDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingPlaceDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notReporting: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    doublePrint: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    blankSlip: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingPlaceDeviceRequest}
 */
proto.user.BettingPlaceDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingPlaceDeviceRequest;
  return proto.user.BettingPlaceDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingPlaceDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingPlaceDeviceRequest}
 */
proto.user.BettingPlaceDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotReporting(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoublePrint(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlankSlip(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingPlaceDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingPlaceDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingPlaceDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNotReporting();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDoublePrint();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBlankSlip();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.user.BettingPlaceDeviceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BettingPlaceDeviceRequest} returns this
 */
proto.user.BettingPlaceDeviceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 betting_place_id = 2;
 * @return {number}
 */
proto.user.BettingPlaceDeviceRequest.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlaceDeviceRequest} returns this
 */
proto.user.BettingPlaceDeviceRequest.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool not_reporting = 3;
 * @return {boolean}
 */
proto.user.BettingPlaceDeviceRequest.prototype.getNotReporting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlaceDeviceRequest} returns this
 */
proto.user.BettingPlaceDeviceRequest.prototype.setNotReporting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool double_print = 4;
 * @return {boolean}
 */
proto.user.BettingPlaceDeviceRequest.prototype.getDoublePrint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlaceDeviceRequest} returns this
 */
proto.user.BettingPlaceDeviceRequest.prototype.setDoublePrint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool blank_slip = 5;
 * @return {boolean}
 */
proto.user.BettingPlaceDeviceRequest.prototype.getBlankSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.BettingPlaceDeviceRequest} returns this
 */
proto.user.BettingPlaceDeviceRequest.prototype.setBlankSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 id = 6;
 * @return {number}
 */
proto.user.BettingPlaceDeviceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlaceDeviceRequest} returns this
 */
proto.user.BettingPlaceDeviceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserPermissionsRequest}
 */
proto.user.GetUserPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserPermissionsRequest;
  return proto.user.GetUserPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserPermissionsRequest}
 */
proto.user.GetUserPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.GetUserPermissionsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetUserPermissionsRequest} returns this
 */
proto.user.GetUserPermissionsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetUserPermissionsResponse.repeatedFields_ = [39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 39)) == null ? undefined : f,
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserPermissionsResponse}
 */
proto.user.GetUserPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserPermissionsResponse;
  return proto.user.GetUserPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserPermissionsResponse}
 */
proto.user.GetUserPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.addPermissions(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      39,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string permissions = 39;
 * @return {!Array<string>}
 */
proto.user.GetUserPermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 39));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.user.GetUserPermissionsResponse} returns this
 */
proto.user.GetUserPermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 39, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.user.GetUserPermissionsResponse} returns this
 */
proto.user.GetUserPermissionsResponse.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 39, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetUserPermissionsResponse} returns this
 */
proto.user.GetUserPermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.GetUserPermissionsResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.GetUserPermissionsResponse} returns this
*/
proto.user.GetUserPermissionsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetUserPermissionsResponse} returns this
 */
proto.user.GetUserPermissionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetUserPermissionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.CreateBonusRequest.repeatedFields_ = [48,52];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CreateBonusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CreateBonusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CreateBonusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CreateBonusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 41, ""),
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    minOddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    totalGamesLost: jspb.Message.getFieldWithDefault(msg, 53, 0),
    winningSlipAwardsList: jspb.Message.toObjectList(msg.getWinningSlipAwardsList(),
    proto.user.WinningSlipAward.toObject, includeInstance),
    cacheBackAwardsList: jspb.Message.toObjectList(msg.getCacheBackAwardsList(),
    proto.user.CacheBackAward.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CreateBonusRequest}
 */
proto.user.CreateBonusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CreateBonusRequest;
  return proto.user.CreateBonusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CreateBonusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CreateBonusRequest}
 */
proto.user.CreateBonusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinOddValue(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalGamesLost(value);
      break;
    case 48:
      var value = new proto.user.WinningSlipAward;
      reader.readMessage(value,proto.user.WinningSlipAward.deserializeBinaryFromReader);
      msg.addWinningSlipAwards(value);
      break;
    case 52:
      var value = new proto.user.CacheBackAward;
      reader.readMessage(value,proto.user.CacheBackAward.deserializeBinaryFromReader);
      msg.addCacheBackAwards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CreateBonusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CreateBonusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CreateBonusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CreateBonusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getMinOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getTotalGamesLost();
  if (f !== 0) {
    writer.writeInt32(
      53,
      f
    );
  }
  f = message.getWinningSlipAwardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      48,
      f,
      proto.user.WinningSlipAward.serializeBinaryToWriter
    );
  }
  f = message.getCacheBackAwardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      52,
      f,
      proto.user.CacheBackAward.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 41;
 * @return {string}
 */
proto.user.CreateBonusRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreateBonusRequest} returns this
 */
proto.user.CreateBonusRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.CreateBonusRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CreateBonusRequest} returns this
 */
proto.user.CreateBonusRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional double min_odd_value = 49;
 * @return {number}
 */
proto.user.CreateBonusRequest.prototype.getMinOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.CreateBonusRequest} returns this
 */
proto.user.CreateBonusRequest.prototype.setMinOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional int32 total_games_lost = 53;
 * @return {number}
 */
proto.user.CreateBonusRequest.prototype.getTotalGamesLost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.CreateBonusRequest} returns this
 */
proto.user.CreateBonusRequest.prototype.setTotalGamesLost = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * repeated WinningSlipAward winning_slip_awards = 48;
 * @return {!Array<!proto.user.WinningSlipAward>}
 */
proto.user.CreateBonusRequest.prototype.getWinningSlipAwardsList = function() {
  return /** @type{!Array<!proto.user.WinningSlipAward>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.WinningSlipAward, 48));
};


/**
 * @param {!Array<!proto.user.WinningSlipAward>} value
 * @return {!proto.user.CreateBonusRequest} returns this
*/
proto.user.CreateBonusRequest.prototype.setWinningSlipAwardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 48, value);
};


/**
 * @param {!proto.user.WinningSlipAward=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.WinningSlipAward}
 */
proto.user.CreateBonusRequest.prototype.addWinningSlipAwards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 48, opt_value, proto.user.WinningSlipAward, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.CreateBonusRequest} returns this
 */
proto.user.CreateBonusRequest.prototype.clearWinningSlipAwardsList = function() {
  return this.setWinningSlipAwardsList([]);
};


/**
 * repeated CacheBackAward cache_back_awards = 52;
 * @return {!Array<!proto.user.CacheBackAward>}
 */
proto.user.CreateBonusRequest.prototype.getCacheBackAwardsList = function() {
  return /** @type{!Array<!proto.user.CacheBackAward>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.CacheBackAward, 52));
};


/**
 * @param {!Array<!proto.user.CacheBackAward>} value
 * @return {!proto.user.CreateBonusRequest} returns this
*/
proto.user.CreateBonusRequest.prototype.setCacheBackAwardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 52, value);
};


/**
 * @param {!proto.user.CacheBackAward=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.CacheBackAward}
 */
proto.user.CreateBonusRequest.prototype.addCacheBackAwards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 52, opt_value, proto.user.CacheBackAward, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.CreateBonusRequest} returns this
 */
proto.user.CreateBonusRequest.prototype.clearCacheBackAwardsList = function() {
  return this.setCacheBackAwardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Bonus.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Bonus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Bonus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Bonus.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    percent: jspb.Message.getFieldWithDefault(msg, 47, 0),
    coefficient: jspb.Message.getFieldWithDefault(msg, 50, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Bonus}
 */
proto.user.Bonus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Bonus;
  return proto.user.Bonus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Bonus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Bonus}
 */
proto.user.Bonus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercent(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoefficient(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Bonus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Bonus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Bonus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Bonus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0) {
    writer.writeInt32(
      47,
      f
    );
  }
  f = message.getCoefficient();
  if (f !== 0) {
    writer.writeInt32(
      50,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional double amount = 17;
 * @return {number}
 */
proto.user.Bonus.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.Bonus} returns this
 */
proto.user.Bonus.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional int32 percent = 47;
 * @return {number}
 */
proto.user.Bonus.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Bonus} returns this
 */
proto.user.Bonus.prototype.setPercent = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional int32 coefficient = 50;
 * @return {number}
 */
proto.user.Bonus.prototype.getCoefficient = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Bonus} returns this
 */
proto.user.Bonus.prototype.setCoefficient = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.Bonus.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.Bonus} returns this
*/
proto.user.Bonus.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.Bonus} returns this
 */
proto.user.Bonus.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Bonus.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.BonusMetadata.repeatedFields_ = [43];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BonusMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BonusMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BonusMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusCategoryList: jspb.Message.toObjectList(msg.getBonusCategoryList(),
    proto.user.BonusCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BonusMetadata}
 */
proto.user.BonusMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BonusMetadata;
  return proto.user.BonusMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BonusMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BonusMetadata}
 */
proto.user.BonusMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 43:
      var value = new proto.user.BonusCategory;
      reader.readMessage(value,proto.user.BonusCategory.deserializeBinaryFromReader);
      msg.addBonusCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BonusMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BonusMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BonusMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      43,
      f,
      proto.user.BonusCategory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BonusCategory bonus_category = 43;
 * @return {!Array<!proto.user.BonusCategory>}
 */
proto.user.BonusMetadata.prototype.getBonusCategoryList = function() {
  return /** @type{!Array<!proto.user.BonusCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BonusCategory, 43));
};


/**
 * @param {!Array<!proto.user.BonusCategory>} value
 * @return {!proto.user.BonusMetadata} returns this
*/
proto.user.BonusMetadata.prototype.setBonusCategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 43, value);
};


/**
 * @param {!proto.user.BonusCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BonusCategory}
 */
proto.user.BonusMetadata.prototype.addBonusCategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 43, opt_value, proto.user.BonusCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.BonusMetadata} returns this
 */
proto.user.BonusMetadata.prototype.clearBonusCategoryList = function() {
  return this.setBonusCategoryList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.BonusCategory.repeatedFields_ = [44];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BonusCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BonusCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BonusCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    bonusTypeList: jspb.Message.toObjectList(msg.getBonusTypeList(),
    proto.user.BonusType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BonusCategory}
 */
proto.user.BonusCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BonusCategory;
  return proto.user.BonusCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BonusCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BonusCategory}
 */
proto.user.BonusCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 44:
      var value = new proto.user.BonusType;
      reader.readMessage(value,proto.user.BonusType.deserializeBinaryFromReader);
      msg.addBonusType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BonusCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BonusCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BonusCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBonusTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.user.BonusType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.BonusCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BonusCategory} returns this
 */
proto.user.BonusCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated BonusType bonus_type = 44;
 * @return {!Array<!proto.user.BonusType>}
 */
proto.user.BonusCategory.prototype.getBonusTypeList = function() {
  return /** @type{!Array<!proto.user.BonusType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BonusType, 44));
};


/**
 * @param {!Array<!proto.user.BonusType>} value
 * @return {!proto.user.BonusCategory} returns this
*/
proto.user.BonusCategory.prototype.setBonusTypeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.user.BonusType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BonusType}
 */
proto.user.BonusCategory.prototype.addBonusType = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.user.BonusType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.BonusCategory} returns this
 */
proto.user.BonusCategory.prototype.clearBonusTypeList = function() {
  return this.setBonusTypeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.BonusType.repeatedFields_ = [45];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BonusType.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BonusType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BonusType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    subtypeList: jspb.Message.toObjectList(msg.getSubtypeList(),
    proto.user.BonusSubType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BonusType}
 */
proto.user.BonusType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BonusType;
  return proto.user.BonusType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BonusType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BonusType}
 */
proto.user.BonusType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 45:
      var value = new proto.user.BonusSubType;
      reader.readMessage(value,proto.user.BonusSubType.deserializeBinaryFromReader);
      msg.addSubtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BonusType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BonusType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BonusType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSubtypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      45,
      f,
      proto.user.BonusSubType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.BonusType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BonusType} returns this
 */
proto.user.BonusType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated BonusSubType subtype = 45;
 * @return {!Array<!proto.user.BonusSubType>}
 */
proto.user.BonusType.prototype.getSubtypeList = function() {
  return /** @type{!Array<!proto.user.BonusSubType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BonusSubType, 45));
};


/**
 * @param {!Array<!proto.user.BonusSubType>} value
 * @return {!proto.user.BonusType} returns this
*/
proto.user.BonusType.prototype.setSubtypeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 45, value);
};


/**
 * @param {!proto.user.BonusSubType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BonusSubType}
 */
proto.user.BonusType.prototype.addSubtype = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 45, opt_value, proto.user.BonusSubType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.BonusType} returns this
 */
proto.user.BonusType.prototype.clearSubtypeList = function() {
  return this.setSubtypeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BonusSubType.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BonusSubType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BonusSubType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusSubType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BonusSubType}
 */
proto.user.BonusSubType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BonusSubType;
  return proto.user.BonusSubType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BonusSubType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BonusSubType}
 */
proto.user.BonusSubType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BonusSubType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BonusSubType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BonusSubType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BonusSubType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.BonusSubType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.BonusSubType} returns this
 */
proto.user.BonusSubType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.WinningSlipAward.prototype.toObject = function(opt_includeInstance) {
  return proto.user.WinningSlipAward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.WinningSlipAward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.WinningSlipAward.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    totalGamesWon: jspb.Message.getFieldWithDefault(msg, 46, 0),
    percent: jspb.Message.getFieldWithDefault(msg, 47, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.WinningSlipAward}
 */
proto.user.WinningSlipAward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.WinningSlipAward;
  return proto.user.WinningSlipAward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.WinningSlipAward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.WinningSlipAward}
 */
proto.user.WinningSlipAward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalGamesWon(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.WinningSlipAward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.WinningSlipAward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.WinningSlipAward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.WinningSlipAward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTotalGamesWon();
  if (f !== 0) {
    writer.writeInt32(
      46,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0) {
    writer.writeInt32(
      47,
      f
    );
  }
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.WinningSlipAward.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.WinningSlipAward} returns this
 */
proto.user.WinningSlipAward.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 total_games_won = 46;
 * @return {number}
 */
proto.user.WinningSlipAward.prototype.getTotalGamesWon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.WinningSlipAward} returns this
 */
proto.user.WinningSlipAward.prototype.setTotalGamesWon = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional int32 percent = 47;
 * @return {number}
 */
proto.user.WinningSlipAward.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.WinningSlipAward} returns this
 */
proto.user.WinningSlipAward.prototype.setPercent = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CacheBackAward.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CacheBackAward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CacheBackAward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CacheBackAward.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    coefficient: jspb.Message.getFieldWithDefault(msg, 50, 0),
    oddThreshold: jspb.Message.getFieldWithDefault(msg, 51, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CacheBackAward}
 */
proto.user.CacheBackAward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CacheBackAward;
  return proto.user.CacheBackAward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CacheBackAward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CacheBackAward}
 */
proto.user.CacheBackAward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCoefficient(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOddThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CacheBackAward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CacheBackAward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CacheBackAward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CacheBackAward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCoefficient();
  if (f !== 0) {
    writer.writeInt32(
      50,
      f
    );
  }
  f = message.getOddThreshold();
  if (f !== 0) {
    writer.writeInt32(
      51,
      f
    );
  }
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.CacheBackAward.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CacheBackAward} returns this
 */
proto.user.CacheBackAward.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 coefficient = 50;
 * @return {number}
 */
proto.user.CacheBackAward.prototype.getCoefficient = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.CacheBackAward} returns this
 */
proto.user.CacheBackAward.prototype.setCoefficient = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int32 odd_threshold = 51;
 * @return {number}
 */
proto.user.CacheBackAward.prototype.getOddThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.CacheBackAward} returns this
 */
proto.user.CacheBackAward.prototype.setOddThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.CheckSlipBonusRequest.repeatedFields_ = [54];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CheckSlipBonusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CheckSlipBonusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CheckSlipBonusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSlipBonusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    combinationsList: jspb.Message.toObjectList(msg.getCombinationsList(),
    proto.user.Combination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CheckSlipBonusRequest}
 */
proto.user.CheckSlipBonusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CheckSlipBonusRequest;
  return proto.user.CheckSlipBonusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CheckSlipBonusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CheckSlipBonusRequest}
 */
proto.user.CheckSlipBonusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 54:
      var value = new proto.user.Combination;
      reader.readMessage(value,proto.user.Combination.deserializeBinaryFromReader);
      msg.addCombinations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CheckSlipBonusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CheckSlipBonusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CheckSlipBonusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSlipBonusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCombinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      54,
      f,
      proto.user.Combination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string slip_id = 15;
 * @return {string}
 */
proto.user.CheckSlipBonusRequest.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CheckSlipBonusRequest} returns this
 */
proto.user.CheckSlipBonusRequest.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated Combination combinations = 54;
 * @return {!Array<!proto.user.Combination>}
 */
proto.user.CheckSlipBonusRequest.prototype.getCombinationsList = function() {
  return /** @type{!Array<!proto.user.Combination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.Combination, 54));
};


/**
 * @param {!Array<!proto.user.Combination>} value
 * @return {!proto.user.CheckSlipBonusRequest} returns this
*/
proto.user.CheckSlipBonusRequest.prototype.setCombinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 54, value);
};


/**
 * @param {!proto.user.Combination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.Combination}
 */
proto.user.CheckSlipBonusRequest.prototype.addCombinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 54, opt_value, proto.user.Combination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.CheckSlipBonusRequest} returns this
 */
proto.user.CheckSlipBonusRequest.prototype.clearCombinationsList = function() {
  return this.setCombinationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CheckSlipBonusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CheckSlipBonusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CheckSlipBonusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSlipBonusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    minBonusAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    maxBonusAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 56, 0.0),
    bonusPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 88, 0.0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CheckSlipBonusResponse}
 */
proto.user.CheckSlipBonusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CheckSlipBonusResponse;
  return proto.user.CheckSlipBonusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CheckSlipBonusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CheckSlipBonusResponse}
 */
proto.user.CheckSlipBonusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinBonusAmount(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxBonusAmount(value);
      break;
    case 88:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBonusPercent(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CheckSlipBonusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CheckSlipBonusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CheckSlipBonusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSlipBonusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMinBonusAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getMaxBonusAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      56,
      f
    );
  }
  f = message.getBonusPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      88,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string slip_id = 15;
 * @return {string}
 */
proto.user.CheckSlipBonusResponse.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.CheckSlipBonusResponse} returns this
 */
proto.user.CheckSlipBonusResponse.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double min_bonus_amount = 55;
 * @return {number}
 */
proto.user.CheckSlipBonusResponse.prototype.getMinBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.CheckSlipBonusResponse} returns this
 */
proto.user.CheckSlipBonusResponse.prototype.setMinBonusAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional double max_bonus_amount = 56;
 * @return {number}
 */
proto.user.CheckSlipBonusResponse.prototype.getMaxBonusAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 56, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.CheckSlipBonusResponse} returns this
 */
proto.user.CheckSlipBonusResponse.prototype.setMaxBonusAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 56, value);
};


/**
 * optional double bonus_percent = 88;
 * @return {number}
 */
proto.user.CheckSlipBonusResponse.prototype.getBonusPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 88, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.CheckSlipBonusResponse} returns this
 */
proto.user.CheckSlipBonusResponse.prototype.setBonusPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 88, value);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.CheckSlipBonusResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.CheckSlipBonusResponse} returns this
*/
proto.user.CheckSlipBonusResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CheckSlipBonusResponse} returns this
 */
proto.user.CheckSlipBonusResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CheckSlipBonusResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.Combination.repeatedFields_ = [57];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Combination.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Combination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Combination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Combination.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    oddValuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 57)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Combination}
 */
proto.user.Combination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Combination;
  return proto.user.Combination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Combination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Combination}
 */
proto.user.Combination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 57:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOddValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Combination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Combination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Combination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Combination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getOddValuesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      57,
      f
    );
  }
};


/**
 * optional double amount = 17;
 * @return {number}
 */
proto.user.Combination.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.Combination} returns this
 */
proto.user.Combination.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * repeated double odd_values = 57;
 * @return {!Array<number>}
 */
proto.user.Combination.prototype.getOddValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 57));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.user.Combination} returns this
 */
proto.user.Combination.prototype.setOddValuesList = function(value) {
  return jspb.Message.setField(this, 57, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.user.Combination} returns this
 */
proto.user.Combination.prototype.addOddValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 57, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.Combination} returns this
 */
proto.user.Combination.prototype.clearOddValuesList = function() {
  return this.setOddValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GrantCacheBackBonusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GrantCacheBackBonusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GrantCacheBackBonusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GrantCacheBackBonusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    oddThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GrantCacheBackBonusRequest}
 */
proto.user.GrantCacheBackBonusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GrantCacheBackBonusRequest;
  return proto.user.GrantCacheBackBonusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GrantCacheBackBonusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GrantCacheBackBonusRequest}
 */
proto.user.GrantCacheBackBonusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlipId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GrantCacheBackBonusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GrantCacheBackBonusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GrantCacheBackBonusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GrantCacheBackBonusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getOddThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      51,
      f
    );
  }
};


/**
 * optional string slip_id = 15;
 * @return {string}
 */
proto.user.GrantCacheBackBonusRequest.prototype.getSlipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GrantCacheBackBonusRequest} returns this
 */
proto.user.GrantCacheBackBonusRequest.prototype.setSlipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double amount = 17;
 * @return {number}
 */
proto.user.GrantCacheBackBonusRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.GrantCacheBackBonusRequest} returns this
 */
proto.user.GrantCacheBackBonusRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double odd_threshold = 51;
 * @return {number}
 */
proto.user.GrantCacheBackBonusRequest.prototype.getOddThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.user.GrantCacheBackBonusRequest} returns this
 */
proto.user.GrantCacheBackBonusRequest.prototype.setOddThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.DeviceCode.prototype.toObject = function(opt_includeInstance) {
  return proto.user.DeviceCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.DeviceCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.DeviceCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceCode: jspb.Message.getFieldWithDefault(msg, 59, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.DeviceCode}
 */
proto.user.DeviceCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.DeviceCode;
  return proto.user.DeviceCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.DeviceCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.DeviceCode}
 */
proto.user.DeviceCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.DeviceCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.DeviceCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.DeviceCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.DeviceCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceCode();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
};


/**
 * optional string device_code = 59;
 * @return {string}
 */
proto.user.DeviceCode.prototype.getDeviceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.DeviceCode} returns this
 */
proto.user.DeviceCode.prototype.setDeviceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingPlaceInfoByDeviceCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlace: (f = msg.getBettingPlace()) && proto.user.BettingPlace.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && proto.user.BettingPlaceDevice.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingPlaceInfoByDeviceCodeResponse;
  return proto.user.GetBettingPlaceInfoByDeviceCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 60:
      var value = new proto.user.BettingPlace;
      reader.readMessage(value,proto.user.BettingPlace.deserializeBinaryFromReader);
      msg.setBettingPlace(value);
      break;
    case 61:
      var value = new proto.user.BettingPlaceDevice;
      reader.readMessage(value,proto.user.BettingPlaceDevice.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingPlaceInfoByDeviceCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlace();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      proto.user.BettingPlace.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.user.BettingPlaceDevice.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional BettingPlace betting_place = 60;
 * @return {?proto.user.BettingPlace}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.getBettingPlace = function() {
  return /** @type{?proto.user.BettingPlace} */ (
    jspb.Message.getWrapperField(this, proto.user.BettingPlace, 60));
};


/**
 * @param {?proto.user.BettingPlace|undefined} value
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} returns this
*/
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.setBettingPlace = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.clearBettingPlace = function() {
  return this.setBettingPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.hasBettingPlace = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional BettingPlaceDevice device = 61;
 * @return {?proto.user.BettingPlaceDevice}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.getDevice = function() {
  return /** @type{?proto.user.BettingPlaceDevice} */ (
    jspb.Message.getWrapperField(this, proto.user.BettingPlaceDevice, 61));
};


/**
 * @param {?proto.user.BettingPlaceDevice|undefined} value
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} returns this
*/
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} returns this
*/
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingPlaceInfoByDeviceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingPlaceInfoByDeviceCodeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    bettingPlaceTerminalId: jspb.Message.getFieldWithDefault(msg, 91, 0),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 92, ""),
    bettingPlaceCode: jspb.Message.getFieldWithDefault(msg, 93, ""),
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    address: jspb.Message.getFieldWithDefault(msg, 85, ""),
    municipalityName: jspb.Message.getFieldWithDefault(msg, 58, ""),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse;
  return proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 91:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceTerminalId(value);
      break;
    case 92:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setBettingPlaceCode(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipalityName(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getBettingPlaceTerminalId();
  if (f !== 0) {
    writer.writeInt32(
      91,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      92,
      f
    );
  }
  f = message.getBettingPlaceCode();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getMunicipalityName();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 betting_place_id = 19;
 * @return {number}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 betting_place_terminal_id = 91;
 * @return {number}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getBettingPlaceTerminalId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 91, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setBettingPlaceTerminalId = function(value) {
  return jspb.Message.setProto3IntField(this, 91, value);
};


/**
 * optional string serial_number = 92;
 * @return {string}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 92, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 92, value);
};


/**
 * optional string betting_place_code = 93;
 * @return {string}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getBettingPlaceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setBettingPlaceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string address = 85;
 * @return {string}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional string municipality_name = 58;
 * @return {string}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getMunicipalityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setMunicipalityName = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
*/
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse} returns this
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetBettingPlaceInfoByIsonisPlaceCodeResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ValidationPriorityObjects.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ValidationPriorityObjects.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ValidationPriorityObjects} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ValidationPriorityObjects.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlacesMapMap: (f = msg.getBettingPlacesMapMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ValidationPriorityObjects}
 */
proto.user.ValidationPriorityObjects.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ValidationPriorityObjects;
  return proto.user.ValidationPriorityObjects.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ValidationPriorityObjects} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ValidationPriorityObjects}
 */
proto.user.ValidationPriorityObjects.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 63:
      var value = msg.getBettingPlacesMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ValidationPriorityObjects.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ValidationPriorityObjects.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ValidationPriorityObjects} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ValidationPriorityObjects.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlacesMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(63, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<int32, string> betting_places_map = 63;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.user.ValidationPriorityObjects.prototype.getBettingPlacesMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 63, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.user.ValidationPriorityObjects} returns this
 */
proto.user.ValidationPriorityObjects.prototype.clearBettingPlacesMapMap = function() {
  this.getBettingPlacesMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ActiveBettingPlaceCodes.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ActiveBettingPlaceCodes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ActiveBettingPlaceCodes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ActiveBettingPlaceCodes.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeCodesMap: (f = msg.getActiveCodesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ActiveBettingPlaceCodes}
 */
proto.user.ActiveBettingPlaceCodes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ActiveBettingPlaceCodes;
  return proto.user.ActiveBettingPlaceCodes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ActiveBettingPlaceCodes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ActiveBettingPlaceCodes}
 */
proto.user.ActiveBettingPlaceCodes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 87:
      var value = msg.getActiveCodesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ActiveBettingPlaceCodes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ActiveBettingPlaceCodes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ActiveBettingPlaceCodes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ActiveBettingPlaceCodes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveCodesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(87, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<int32, string> active_codes = 87;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.user.ActiveBettingPlaceCodes.prototype.getActiveCodesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 87, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.user.ActiveBettingPlaceCodes} returns this
 */
proto.user.ActiveBettingPlaceCodes.prototype.clearActiveCodesMap = function() {
  this.getActiveCodesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.Municipality.repeatedFields_ = [23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Municipality.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Municipality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Municipality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Municipality.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 24, ""),
    bettingPlacesList: jspb.Message.toObjectList(msg.getBettingPlacesList(),
    proto.user.BettingPlace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Municipality}
 */
proto.user.Municipality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Municipality;
  return proto.user.Municipality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Municipality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Municipality}
 */
proto.user.Municipality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 23:
      var value = new proto.user.BettingPlace;
      reader.readMessage(value,proto.user.BettingPlace.deserializeBinaryFromReader);
      msg.addBettingPlaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Municipality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Municipality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Municipality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Municipality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBettingPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.user.BettingPlace.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.Municipality.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Municipality} returns this
 */
proto.user.Municipality.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 24;
 * @return {string}
 */
proto.user.Municipality.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Municipality} returns this
 */
proto.user.Municipality.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated BettingPlace betting_places = 23;
 * @return {!Array<!proto.user.BettingPlace>}
 */
proto.user.Municipality.prototype.getBettingPlacesList = function() {
  return /** @type{!Array<!proto.user.BettingPlace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingPlace, 23));
};


/**
 * @param {!Array<!proto.user.BettingPlace>} value
 * @return {!proto.user.Municipality} returns this
*/
proto.user.Municipality.prototype.setBettingPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.user.BettingPlace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingPlace}
 */
proto.user.Municipality.prototype.addBettingPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.user.BettingPlace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.Municipality} returns this
 */
proto.user.Municipality.prototype.clearBettingPlacesList = function() {
  return this.setBettingPlacesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetBettingPlacesByMunicipalityResponse.repeatedFields_ = [64];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBettingPlacesByMunicipalityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBettingPlacesByMunicipalityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBettingPlacesByMunicipalityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlacesByMunicipalityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    municipalitiesList: jspb.Message.toObjectList(msg.getMunicipalitiesList(),
    proto.user.Municipality.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBettingPlacesByMunicipalityResponse}
 */
proto.user.GetBettingPlacesByMunicipalityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBettingPlacesByMunicipalityResponse;
  return proto.user.GetBettingPlacesByMunicipalityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBettingPlacesByMunicipalityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBettingPlacesByMunicipalityResponse}
 */
proto.user.GetBettingPlacesByMunicipalityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 64:
      var value = new proto.user.Municipality;
      reader.readMessage(value,proto.user.Municipality.deserializeBinaryFromReader);
      msg.addMunicipalities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBettingPlacesByMunicipalityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBettingPlacesByMunicipalityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBettingPlacesByMunicipalityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBettingPlacesByMunicipalityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMunicipalitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      64,
      f,
      proto.user.Municipality.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Municipality municipalities = 64;
 * @return {!Array<!proto.user.Municipality>}
 */
proto.user.GetBettingPlacesByMunicipalityResponse.prototype.getMunicipalitiesList = function() {
  return /** @type{!Array<!proto.user.Municipality>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.Municipality, 64));
};


/**
 * @param {!Array<!proto.user.Municipality>} value
 * @return {!proto.user.GetBettingPlacesByMunicipalityResponse} returns this
*/
proto.user.GetBettingPlacesByMunicipalityResponse.prototype.setMunicipalitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 64, value);
};


/**
 * @param {!proto.user.Municipality=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.Municipality}
 */
proto.user.GetBettingPlacesByMunicipalityResponse.prototype.addMunicipalities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 64, opt_value, proto.user.Municipality, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetBettingPlacesByMunicipalityResponse} returns this
 */
proto.user.GetBettingPlacesByMunicipalityResponse.prototype.clearMunicipalitiesList = function() {
  return this.setMunicipalitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ListActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ListActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ListActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 65, 0),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateTo: (f = msg.getDateTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    queryString: jspb.Message.getFieldWithDefault(msg, 82, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ListActivityRequest}
 */
proto.user.ListActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ListActivityRequest;
  return proto.user.ListActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ListActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ListActivityRequest}
 */
proto.user.ListActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 65:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 80:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 81:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTo(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ListActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ListActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ListActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      65,
      f
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateTo();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQueryString();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
};


/**
 * optional int32 page = 65;
 * @return {number}
 */
proto.user.ListActivityRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ListActivityRequest} returns this
 */
proto.user.ListActivityRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


/**
 * optional google.protobuf.Timestamp date_from = 80;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.user.ListActivityRequest.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 80));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.user.ListActivityRequest} returns this
*/
proto.user.ListActivityRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 80, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ListActivityRequest} returns this
 */
proto.user.ListActivityRequest.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ListActivityRequest.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional google.protobuf.Timestamp date_to = 81;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.user.ListActivityRequest.prototype.getDateTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 81));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.user.ListActivityRequest} returns this
*/
proto.user.ListActivityRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 81, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ListActivityRequest} returns this
 */
proto.user.ListActivityRequest.prototype.clearDateTo = function() {
  return this.setDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ListActivityRequest.prototype.hasDateTo = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional string query_string = 82;
 * @return {string}
 */
proto.user.ListActivityRequest.prototype.getQueryString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.ListActivityRequest} returns this
 */
proto.user.ListActivityRequest.prototype.setQueryString = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.ListActivityResponse.repeatedFields_ = [66];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.ListActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.ListActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.ListActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.user.Activity.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 67, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 68, 0),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.ListActivityResponse}
 */
proto.user.ListActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.ListActivityResponse;
  return proto.user.ListActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.ListActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.ListActivityResponse}
 */
proto.user.ListActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 66:
      var value = new proto.user.Activity;
      reader.readMessage(value,proto.user.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    case 22:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.ListActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.ListActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.ListActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.ListActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      66,
      f,
      proto.user.Activity.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      67,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      68,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Activity activities = 66;
 * @return {!Array<!proto.user.Activity>}
 */
proto.user.ListActivityResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.user.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.Activity, 66));
};


/**
 * @param {!Array<!proto.user.Activity>} value
 * @return {!proto.user.ListActivityResponse} returns this
*/
proto.user.ListActivityResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 66, value);
};


/**
 * @param {!proto.user.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.Activity}
 */
proto.user.ListActivityResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 66, opt_value, proto.user.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.ListActivityResponse} returns this
 */
proto.user.ListActivityResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


/**
 * optional int32 page = 67;
 * @return {number}
 */
proto.user.ListActivityResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ListActivityResponse} returns this
 */
proto.user.ListActivityResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 67, value);
};


/**
 * optional int32 total_pages = 68;
 * @return {number}
 */
proto.user.ListActivityResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.ListActivityResponse} returns this
 */
proto.user.ListActivityResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 68, value);
};


/**
 * optional common.Error error = 22;
 * @return {?proto.common.Error}
 */
proto.user.ListActivityResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 22));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.ListActivityResponse} returns this
*/
proto.user.ListActivityResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.ListActivityResponse} returns this
 */
proto.user.ListActivityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.ListActivityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.user.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 69, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 70, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 71, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 72, 0),
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 73, 0),
    bettingPlaceName: jspb.Message.getFieldWithDefault(msg, 74, ""),
    bettingPlaceCode: jspb.Message.getFieldWithDefault(msg, 75, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 76, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 77, ""),
    activityKwargs: jspb.Message.getFieldWithDefault(msg, 78, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.Activity}
 */
proto.user.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.Activity;
  return proto.user.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.Activity}
 */
proto.user.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 69:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoleId(value);
      break;
    case 73:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setBettingPlaceName(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setBettingPlaceCode(value);
      break;
    case 76:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceId(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivity(value);
      break;
    case 78:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityKwargs(value);
      break;
    case 79:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      69,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeInt32(
      72,
      f
    );
  }
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      73,
      f
    );
  }
  f = message.getBettingPlaceName();
  if (f.length > 0) {
    writer.writeString(
      74,
      f
    );
  }
  f = message.getBettingPlaceCode();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt32(
      76,
      f
    );
  }
  f = message.getActivity();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getActivityKwargs();
  if (f.length > 0) {
    writer.writeString(
      78,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 69;
 * @return {number}
 */
proto.user.Activity.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 69, value);
};


/**
 * optional string first_name = 70;
 * @return {string}
 */
proto.user.Activity.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string last_name = 71;
 * @return {string}
 */
proto.user.Activity.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional int32 role_id = 72;
 * @return {number}
 */
proto.user.Activity.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 72, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 72, value);
};


/**
 * optional int32 betting_place_id = 73;
 * @return {number}
 */
proto.user.Activity.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 73, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 73, value);
};


/**
 * optional string betting_place_name = 74;
 * @return {string}
 */
proto.user.Activity.prototype.getBettingPlaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setBettingPlaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 74, value);
};


/**
 * optional string betting_place_code = 75;
 * @return {string}
 */
proto.user.Activity.prototype.getBettingPlaceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setBettingPlaceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional int32 device_id = 76;
 * @return {number}
 */
proto.user.Activity.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 76, value);
};


/**
 * optional string activity = 77;
 * @return {string}
 */
proto.user.Activity.prototype.getActivity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setActivity = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional string activity_kwargs = 78;
 * @return {string}
 */
proto.user.Activity.prototype.getActivityKwargs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 78, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.setActivityKwargs = function(value) {
  return jspb.Message.setProto3StringField(this, 78, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 79;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.user.Activity.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 79));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.user.Activity} returns this
*/
proto.user.Activity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 79, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.Activity} returns this
 */
proto.user.Activity.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.Activity.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 79) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.DoublePrintRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.DoublePrintRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.DoublePrintRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.DoublePrintRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.DoublePrintRequest}
 */
proto.user.DoublePrintRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.DoublePrintRequest;
  return proto.user.DoublePrintRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.DoublePrintRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.DoublePrintRequest}
 */
proto.user.DoublePrintRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.DoublePrintRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.DoublePrintRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.DoublePrintRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.DoublePrintRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.DoublePrintRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.DoublePrintRequest} returns this
 */
proto.user.DoublePrintRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BlankSlipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BlankSlipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BlankSlipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BlankSlipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BlankSlipRequest}
 */
proto.user.BlankSlipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BlankSlipRequest;
  return proto.user.BlankSlipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BlankSlipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BlankSlipRequest}
 */
proto.user.BlankSlipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BlankSlipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BlankSlipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BlankSlipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BlankSlipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.BlankSlipRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BlankSlipRequest} returns this
 */
proto.user.BlankSlipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.TestBettingPlaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.TestBettingPlaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.TestBettingPlaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.TestBettingPlaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.TestBettingPlaceRequest}
 */
proto.user.TestBettingPlaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.TestBettingPlaceRequest;
  return proto.user.TestBettingPlaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.TestBettingPlaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.TestBettingPlaceRequest}
 */
proto.user.TestBettingPlaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.TestBettingPlaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.TestBettingPlaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.TestBettingPlaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.TestBettingPlaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.user.TestBettingPlaceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.TestBettingPlaceRequest} returns this
 */
proto.user.TestBettingPlaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.TestBettingPlacesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.TestBettingPlacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.TestBettingPlacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.TestBettingPlacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.TestBettingPlacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.TestBettingPlacesResponse}
 */
proto.user.TestBettingPlacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.TestBettingPlacesResponse;
  return proto.user.TestBettingPlacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.TestBettingPlacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.TestBettingPlacesResponse}
 */
proto.user.TestBettingPlacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.TestBettingPlacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.TestBettingPlacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.TestBettingPlacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.TestBettingPlacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 ids = 1;
 * @return {!Array<number>}
 */
proto.user.TestBettingPlacesResponse.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.user.TestBettingPlacesResponse} returns this
 */
proto.user.TestBettingPlacesResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.user.TestBettingPlacesResponse} returns this
 */
proto.user.TestBettingPlacesResponse.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.TestBettingPlacesResponse} returns this
 */
proto.user.TestBettingPlacesResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetTestBettingPlaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetTestBettingPlaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetTestBettingPlaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetTestBettingPlaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    test: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetTestBettingPlaceResponse}
 */
proto.user.GetTestBettingPlaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetTestBettingPlaceResponse;
  return proto.user.GetTestBettingPlaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetTestBettingPlaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetTestBettingPlaceResponse}
 */
proto.user.GetTestBettingPlaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetTestBettingPlaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetTestBettingPlaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetTestBettingPlaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetTestBettingPlaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTest();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool test = 1;
 * @return {boolean}
 */
proto.user.GetTestBettingPlaceResponse.prototype.getTest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.GetTestBettingPlaceResponse} returns this
 */
proto.user.GetTestBettingPlaceResponse.prototype.setTest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CheckSameMunicipalityBPSRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CheckSameMunicipalityBPSRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CheckSameMunicipalityBPSRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSameMunicipalityBPSRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bp1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bp2: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CheckSameMunicipalityBPSRequest}
 */
proto.user.CheckSameMunicipalityBPSRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CheckSameMunicipalityBPSRequest;
  return proto.user.CheckSameMunicipalityBPSRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CheckSameMunicipalityBPSRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CheckSameMunicipalityBPSRequest}
 */
proto.user.CheckSameMunicipalityBPSRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBp1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBp2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CheckSameMunicipalityBPSRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CheckSameMunicipalityBPSRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CheckSameMunicipalityBPSRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSameMunicipalityBPSRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBp1();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBp2();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 bp1 = 1;
 * @return {number}
 */
proto.user.CheckSameMunicipalityBPSRequest.prototype.getBp1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.CheckSameMunicipalityBPSRequest} returns this
 */
proto.user.CheckSameMunicipalityBPSRequest.prototype.setBp1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 bp2 = 2;
 * @return {number}
 */
proto.user.CheckSameMunicipalityBPSRequest.prototype.getBp2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.CheckSameMunicipalityBPSRequest} returns this
 */
proto.user.CheckSameMunicipalityBPSRequest.prototype.setBp2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.CheckSameMunicipalityBPSResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.CheckSameMunicipalityBPSResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSameMunicipalityBPSResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    check: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: (f = msg.getError()) && proto_common_common_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.CheckSameMunicipalityBPSResponse}
 */
proto.user.CheckSameMunicipalityBPSResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.CheckSameMunicipalityBPSResponse;
  return proto.user.CheckSameMunicipalityBPSResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.CheckSameMunicipalityBPSResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.CheckSameMunicipalityBPSResponse}
 */
proto.user.CheckSameMunicipalityBPSResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheck(value);
      break;
    case 6:
      var value = new proto_common_common_pb.Error;
      reader.readMessage(value,proto_common_common_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.CheckSameMunicipalityBPSResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.CheckSameMunicipalityBPSResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.CheckSameMunicipalityBPSResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheck();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_common_common_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool check = 1;
 * @return {boolean}
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.getCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.CheckSameMunicipalityBPSResponse} returns this
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.setCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional common.Error error = 6;
 * @return {?proto.common.Error}
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.getError = function() {
  return /** @type{?proto.common.Error} */ (
    jspb.Message.getWrapperField(this, proto_common_common_pb.Error, 6));
};


/**
 * @param {?proto.common.Error|undefined} value
 * @return {!proto.user.CheckSameMunicipalityBPSResponse} returns this
*/
proto.user.CheckSameMunicipalityBPSResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.CheckSameMunicipalityBPSResponse} returns this
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.CheckSameMunicipalityBPSResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetBPBettingMachinesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetBPBettingMachinesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetBPBettingMachinesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetBPBettingMachinesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBPBettingMachinesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingMachinesList: jspb.Message.toObjectList(msg.getBettingMachinesList(),
    proto.user.BettingMachine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetBPBettingMachinesResponse}
 */
proto.user.GetBPBettingMachinesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetBPBettingMachinesResponse;
  return proto.user.GetBPBettingMachinesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetBPBettingMachinesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetBPBettingMachinesResponse}
 */
proto.user.GetBPBettingMachinesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.BettingMachine;
      reader.readMessage(value,proto.user.BettingMachine.deserializeBinaryFromReader);
      msg.addBettingMachines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetBPBettingMachinesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetBPBettingMachinesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetBPBettingMachinesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetBPBettingMachinesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingMachinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.user.BettingMachine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BettingMachine betting_machines = 1;
 * @return {!Array<!proto.user.BettingMachine>}
 */
proto.user.GetBPBettingMachinesResponse.prototype.getBettingMachinesList = function() {
  return /** @type{!Array<!proto.user.BettingMachine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.BettingMachine, 1));
};


/**
 * @param {!Array<!proto.user.BettingMachine>} value
 * @return {!proto.user.GetBPBettingMachinesResponse} returns this
*/
proto.user.GetBPBettingMachinesResponse.prototype.setBettingMachinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.user.BettingMachine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.BettingMachine}
 */
proto.user.GetBPBettingMachinesResponse.prototype.addBettingMachines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.user.BettingMachine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetBPBettingMachinesResponse} returns this
 */
proto.user.GetBPBettingMachinesResponse.prototype.clearBettingMachinesList = function() {
  return this.setBettingMachinesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.BettingPlaceLimitsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.BettingPlaceLimitsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.BettingPlaceLimitsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceLimitsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bettingPlaceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.BettingPlaceLimitsRequest}
 */
proto.user.BettingPlaceLimitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.BettingPlaceLimitsRequest;
  return proto.user.BettingPlaceLimitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.BettingPlaceLimitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.BettingPlaceLimitsRequest}
 */
proto.user.BettingPlaceLimitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBettingPlaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.BettingPlaceLimitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.BettingPlaceLimitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.BettingPlaceLimitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.BettingPlaceLimitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBettingPlaceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 betting_place_id = 1;
 * @return {number}
 */
proto.user.BettingPlaceLimitsRequest.prototype.getBettingPlaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.user.BettingPlaceLimitsRequest} returns this
 */
proto.user.BettingPlaceLimitsRequest.prototype.setBettingPlaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.user);
