/**
 * @fileoverview gRPC-Web generated client stub for user
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_common_common_pb from '../../proto/common/common_pb';
import * as proto_user_user_pb from '../../proto/user/user_pb';


export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.UserResponse,
    (request: proto_user_user_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.UserResponse.deserializeBinary
  );

  createUser(
    request: proto_user_user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.UserResponse>;

  createUser(
    request: proto_user_user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.UserResponse>;

  createUser(
    request: proto_user_user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateUser',
        request,
        metadata || {},
        this.methodInfoCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateUser',
    request,
    metadata || {},
    this.methodInfoCreateUser);
  }

  methodInfoCreatePlatformUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.UserResponse,
    (request: proto_user_user_pb.CreatePlatformUserRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.UserResponse.deserializeBinary
  );

  createPlatformUser(
    request: proto_user_user_pb.CreatePlatformUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.UserResponse>;

  createPlatformUser(
    request: proto_user_user_pb.CreatePlatformUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.UserResponse>;

  createPlatformUser(
    request: proto_user_user_pb.CreatePlatformUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreatePlatformUser',
        request,
        metadata || {},
        this.methodInfoCreatePlatformUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreatePlatformUser',
    request,
    metadata || {},
    this.methodInfoCreatePlatformUser);
  }

  methodInfoGetUserByEmailAndPassword = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetUserByEmailAndPasswordResponse,
    (request: proto_user_user_pb.GetUserByEmailAndPasswordRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetUserByEmailAndPasswordResponse.deserializeBinary
  );

  getUserByEmailAndPassword(
    request: proto_user_user_pb.GetUserByEmailAndPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetUserByEmailAndPasswordResponse>;

  getUserByEmailAndPassword(
    request: proto_user_user_pb.GetUserByEmailAndPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetUserByEmailAndPasswordResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetUserByEmailAndPasswordResponse>;

  getUserByEmailAndPassword(
    request: proto_user_user_pb.GetUserByEmailAndPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetUserByEmailAndPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetUserByEmailAndPassword',
        request,
        metadata || {},
        this.methodInfoGetUserByEmailAndPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetUserByEmailAndPassword',
    request,
    metadata || {},
    this.methodInfoGetUserByEmailAndPassword);
  }

  methodInfoGetUserPermissions = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetUserPermissionsResponse,
    (request: proto_user_user_pb.GetUserPermissionsRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetUserPermissionsResponse.deserializeBinary
  );

  getUserPermissions(
    request: proto_user_user_pb.GetUserPermissionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetUserPermissionsResponse>;

  getUserPermissions(
    request: proto_user_user_pb.GetUserPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetUserPermissionsResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetUserPermissionsResponse>;

  getUserPermissions(
    request: proto_user_user_pb.GetUserPermissionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetUserPermissionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetUserPermissions',
        request,
        metadata || {},
        this.methodInfoGetUserPermissions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetUserPermissions',
    request,
    metadata || {},
    this.methodInfoGetUserPermissions);
  }

  methodInfoGetApprovalId = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetUserRequest,
    (request: proto_user_user_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetUserRequest.deserializeBinary
  );

  getApprovalId(
    request: proto_user_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetUserRequest>;

  getApprovalId(
    request: proto_user_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetUserRequest) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetUserRequest>;

  getApprovalId(
    request: proto_user_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetUserRequest) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetApprovalId',
        request,
        metadata || {},
        this.methodInfoGetApprovalId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetApprovalId',
    request,
    metadata || {},
    this.methodInfoGetApprovalId);
  }

  methodInfoGetUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.UserResponse,
    (request: proto_user_user_pb.GetUserRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.UserResponse.deserializeBinary
  );

  getUser(
    request: proto_user_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.UserResponse>;

  getUser(
    request: proto_user_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.UserResponse>;

  getUser(
    request: proto_user_user_pb.GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetUser',
        request,
        metadata || {},
        this.methodInfoGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetUser',
    request,
    metadata || {},
    this.methodInfoGetUser);
  }

  methodInfoUpdateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.UserResponse,
    (request: proto_user_user_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.UserResponse.deserializeBinary
  );

  updateUser(
    request: proto_user_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.UserResponse>;

  updateUser(
    request: proto_user_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.UserResponse>;

  updateUser(
    request: proto_user_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateUser',
        request,
        metadata || {},
        this.methodInfoUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateUser',
    request,
    metadata || {},
    this.methodInfoUpdateUser);
  }

  methodInfoListUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ListUsersResponse,
    (request: proto_user_user_pb.ListUsersRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ListUsersResponse.deserializeBinary
  );

  listUsers(
    request: proto_user_user_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ListUsersResponse>;

  listUsers(
    request: proto_user_user_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ListUsersResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ListUsersResponse>;

  listUsers(
    request: proto_user_user_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ListUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ListUsers',
        request,
        metadata || {},
        this.methodInfoListUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ListUsers',
    request,
    metadata || {},
    this.methodInfoListUsers);
  }

  methodInfoListActivity = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ListActivityResponse,
    (request: proto_user_user_pb.ListActivityRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ListActivityResponse.deserializeBinary
  );

  listActivity(
    request: proto_user_user_pb.ListActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ListActivityResponse>;

  listActivity(
    request: proto_user_user_pb.ListActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ListActivityResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ListActivityResponse>;

  listActivity(
    request: proto_user_user_pb.ListActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ListActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ListActivity',
        request,
        metadata || {},
        this.methodInfoListActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ListActivity',
    request,
    metadata || {},
    this.methodInfoListActivity);
  }

  methodInfoReserveFunds = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ReserveFundsResponse,
    (request: proto_user_user_pb.ReserveFundsRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ReserveFundsResponse.deserializeBinary
  );

  reserveFunds(
    request: proto_user_user_pb.ReserveFundsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ReserveFundsResponse>;

  reserveFunds(
    request: proto_user_user_pb.ReserveFundsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ReserveFundsResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ReserveFundsResponse>;

  reserveFunds(
    request: proto_user_user_pb.ReserveFundsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ReserveFundsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ReserveFunds',
        request,
        metadata || {},
        this.methodInfoReserveFunds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ReserveFunds',
    request,
    metadata || {},
    this.methodInfoReserveFunds);
  }

  methodInfoConfirmFunds = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ConfirmFundsResponse,
    (request: proto_user_user_pb.ReleaseFundsRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ConfirmFundsResponse.deserializeBinary
  );

  confirmFunds(
    request: proto_user_user_pb.ReleaseFundsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ConfirmFundsResponse>;

  confirmFunds(
    request: proto_user_user_pb.ReleaseFundsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ConfirmFundsResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ConfirmFundsResponse>;

  confirmFunds(
    request: proto_user_user_pb.ReleaseFundsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ConfirmFundsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ConfirmFunds',
        request,
        metadata || {},
        this.methodInfoConfirmFunds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ConfirmFunds',
    request,
    metadata || {},
    this.methodInfoConfirmFunds);
  }

  methodInfoReleaseFunds = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ReleaseFundsResponse,
    (request: proto_user_user_pb.ReleaseFundsRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ReleaseFundsResponse.deserializeBinary
  );

  releaseFunds(
    request: proto_user_user_pb.ReleaseFundsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ReleaseFundsResponse>;

  releaseFunds(
    request: proto_user_user_pb.ReleaseFundsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ReleaseFundsResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ReleaseFundsResponse>;

  releaseFunds(
    request: proto_user_user_pb.ReleaseFundsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ReleaseFundsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ReleaseFunds',
        request,
        metadata || {},
        this.methodInfoReleaseFunds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ReleaseFunds',
    request,
    metadata || {},
    this.methodInfoReleaseFunds);
  }

  methodInfoGetBonusMetadata = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BonusMetadata,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BonusMetadata.deserializeBinary
  );

  getBonusMetadata(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BonusMetadata>;

  getBonusMetadata(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BonusMetadata) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BonusMetadata>;

  getBonusMetadata(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BonusMetadata) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBonusMetadata',
        request,
        metadata || {},
        this.methodInfoGetBonusMetadata,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBonusMetadata',
    request,
    metadata || {},
    this.methodInfoGetBonusMetadata);
  }

  methodInfoCreateBonus = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Bonus,
    (request: proto_user_user_pb.CreateBonusRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Bonus.deserializeBinary
  );

  createBonus(
    request: proto_user_user_pb.CreateBonusRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Bonus>;

  createBonus(
    request: proto_user_user_pb.CreateBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Bonus) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Bonus>;

  createBonus(
    request: proto_user_user_pb.CreateBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Bonus) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateBonus',
        request,
        metadata || {},
        this.methodInfoCreateBonus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateBonus',
    request,
    metadata || {},
    this.methodInfoCreateBonus);
  }

  methodInfoCheckSlipBonus = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.CheckSlipBonusResponse,
    (request: proto_user_user_pb.CheckSlipBonusRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.CheckSlipBonusResponse.deserializeBinary
  );

  checkSlipBonus(
    request: proto_user_user_pb.CheckSlipBonusRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.CheckSlipBonusResponse>;

  checkSlipBonus(
    request: proto_user_user_pb.CheckSlipBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.CheckSlipBonusResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.CheckSlipBonusResponse>;

  checkSlipBonus(
    request: proto_user_user_pb.CheckSlipBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.CheckSlipBonusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CheckSlipBonus',
        request,
        metadata || {},
        this.methodInfoCheckSlipBonus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CheckSlipBonus',
    request,
    metadata || {},
    this.methodInfoCheckSlipBonus);
  }

  methodInfoGrantCacheBackBonus = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Bonus,
    (request: proto_user_user_pb.GrantCacheBackBonusRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Bonus.deserializeBinary
  );

  grantCacheBackBonus(
    request: proto_user_user_pb.GrantCacheBackBonusRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Bonus>;

  grantCacheBackBonus(
    request: proto_user_user_pb.GrantCacheBackBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Bonus) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Bonus>;

  grantCacheBackBonus(
    request: proto_user_user_pb.GrantCacheBackBonusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Bonus) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GrantCacheBackBonus',
        request,
        metadata || {},
        this.methodInfoGrantCacheBackBonus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GrantCacheBackBonus',
    request,
    metadata || {},
    this.methodInfoGrantCacheBackBonus);
  }

  methodInfoGetBettingPlacesBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingPlacesBoResponse,
    (request: proto_user_user_pb.GetBettingPlacesBoRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingPlacesBoResponse.deserializeBinary
  );

  getBettingPlacesBO(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingPlacesBoResponse>;

  getBettingPlacesBO(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlacesBoResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingPlacesBoResponse>;

  getBettingPlacesBO(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlacesBoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingPlacesBO',
        request,
        metadata || {},
        this.methodInfoGetBettingPlacesBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingPlacesBO',
    request,
    metadata || {},
    this.methodInfoGetBettingPlacesBO);
  }

  methodInfoGetAllBettingPlacesBO = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingPlacesBoResponse,
    (request: proto_user_user_pb.GetBettingPlacesBoRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingPlacesBoResponse.deserializeBinary
  );

  getAllBettingPlacesBO(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingPlacesBoResponse>;

  getAllBettingPlacesBO(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlacesBoResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingPlacesBoResponse>;

  getAllBettingPlacesBO(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlacesBoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetAllBettingPlacesBO',
        request,
        metadata || {},
        this.methodInfoGetAllBettingPlacesBO,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetAllBettingPlacesBO',
    request,
    metadata || {},
    this.methodInfoGetAllBettingPlacesBO);
  }

  methodInfoGetBettingPlaceInfoByDeviceCode = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingPlaceInfoByDeviceCodeResponse,
    (request: proto_user_user_pb.DeviceCode) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingPlaceInfoByDeviceCodeResponse.deserializeBinary
  );

  getBettingPlaceInfoByDeviceCode(
    request: proto_user_user_pb.DeviceCode,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingPlaceInfoByDeviceCodeResponse>;

  getBettingPlaceInfoByDeviceCode(
    request: proto_user_user_pb.DeviceCode,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlaceInfoByDeviceCodeResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingPlaceInfoByDeviceCodeResponse>;

  getBettingPlaceInfoByDeviceCode(
    request: proto_user_user_pb.DeviceCode,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlaceInfoByDeviceCodeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingPlaceInfoByDeviceCode',
        request,
        metadata || {},
        this.methodInfoGetBettingPlaceInfoByDeviceCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingPlaceInfoByDeviceCode',
    request,
    metadata || {},
    this.methodInfoGetBettingPlaceInfoByDeviceCode);
  }

  methodInfoGetBettingPlaceInfoByIsonisPlaceCode = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingPlaceInfoByIsonisPlaceCodeResponse,
    (request: proto_user_user_pb.DeviceCode) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingPlaceInfoByIsonisPlaceCodeResponse.deserializeBinary
  );

  getBettingPlaceInfoByIsonisPlaceCode(
    request: proto_user_user_pb.DeviceCode,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingPlaceInfoByIsonisPlaceCodeResponse>;

  getBettingPlaceInfoByIsonisPlaceCode(
    request: proto_user_user_pb.DeviceCode,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlaceInfoByIsonisPlaceCodeResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingPlaceInfoByIsonisPlaceCodeResponse>;

  getBettingPlaceInfoByIsonisPlaceCode(
    request: proto_user_user_pb.DeviceCode,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlaceInfoByIsonisPlaceCodeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingPlaceInfoByIsonisPlaceCode',
        request,
        metadata || {},
        this.methodInfoGetBettingPlaceInfoByIsonisPlaceCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingPlaceInfoByIsonisPlaceCode',
    request,
    metadata || {},
    this.methodInfoGetBettingPlaceInfoByIsonisPlaceCode);
  }

  methodInfoReportBettingPlaceStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  reportBettingPlaceStatus(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  reportBettingPlaceStatus(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  reportBettingPlaceStatus(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ReportBettingPlaceStatus',
        request,
        metadata || {},
        this.methodInfoReportBettingPlaceStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ReportBettingPlaceStatus',
    request,
    metadata || {},
    this.methodInfoReportBettingPlaceStatus);
  }

  methodInfoGetValidationPriorityObjects = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ValidationPriorityObjects,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ValidationPriorityObjects.deserializeBinary
  );

  getValidationPriorityObjects(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ValidationPriorityObjects>;

  getValidationPriorityObjects(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ValidationPriorityObjects) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ValidationPriorityObjects>;

  getValidationPriorityObjects(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ValidationPriorityObjects) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetValidationPriorityObjects',
        request,
        metadata || {},
        this.methodInfoGetValidationPriorityObjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetValidationPriorityObjects',
    request,
    metadata || {},
    this.methodInfoGetValidationPriorityObjects);
  }

  methodInfoGetBettingPlacesByMunicipality = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingPlacesByMunicipalityResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingPlacesByMunicipalityResponse.deserializeBinary
  );

  getBettingPlacesByMunicipality(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingPlacesByMunicipalityResponse>;

  getBettingPlacesByMunicipality(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlacesByMunicipalityResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingPlacesByMunicipalityResponse>;

  getBettingPlacesByMunicipality(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingPlacesByMunicipalityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingPlacesByMunicipality',
        request,
        metadata || {},
        this.methodInfoGetBettingPlacesByMunicipality,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingPlacesByMunicipality',
    request,
    metadata || {},
    this.methodInfoGetBettingPlacesByMunicipality);
  }

  methodInfoDoublePrint = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BettingPlaceDevice) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  doublePrint(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  doublePrint(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  doublePrint(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DoublePrint',
        request,
        metadata || {},
        this.methodInfoDoublePrint,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DoublePrint',
    request,
    metadata || {},
    this.methodInfoDoublePrint);
  }

  methodInfoBlankSlip = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BettingPlaceDevice) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  blankSlip(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  blankSlip(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  blankSlip(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/BlankSlip',
        request,
        metadata || {},
        this.methodInfoBlankSlip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/BlankSlip',
    request,
    metadata || {},
    this.methodInfoBlankSlip);
  }

  methodInfoNotReporting = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BettingPlaceDevice) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  notReporting(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  notReporting(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  notReporting(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/NotReporting',
        request,
        metadata || {},
        this.methodInfoNotReporting,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/NotReporting',
    request,
    metadata || {},
    this.methodInfoNotReporting);
  }

  methodInfoIsNotReporting = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingPlaceDevice,
    (request: proto_user_user_pb.BettingPlaceDevice) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingPlaceDevice.deserializeBinary
  );

  isNotReporting(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingPlaceDevice>;

  isNotReporting(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingPlaceDevice) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingPlaceDevice>;

  isNotReporting(
    request: proto_user_user_pb.BettingPlaceDevice,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingPlaceDevice) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/IsNotReporting',
        request,
        metadata || {},
        this.methodInfoIsNotReporting,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/IsNotReporting',
    request,
    metadata || {},
    this.methodInfoIsNotReporting);
  }

  methodInfoGetActiveBettingPlaceCodes = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.ActiveBettingPlaceCodes,
    (request: proto_user_user_pb.GetBettingPlacesBoRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.ActiveBettingPlaceCodes.deserializeBinary
  );

  getActiveBettingPlaceCodes(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.ActiveBettingPlaceCodes>;

  getActiveBettingPlaceCodes(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.ActiveBettingPlaceCodes) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.ActiveBettingPlaceCodes>;

  getActiveBettingPlaceCodes(
    request: proto_user_user_pb.GetBettingPlacesBoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.ActiveBettingPlaceCodes) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetActiveBettingPlaceCodes',
        request,
        metadata || {},
        this.methodInfoGetActiveBettingPlaceCodes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetActiveBettingPlaceCodes',
    request,
    metadata || {},
    this.methodInfoGetActiveBettingPlaceCodes);
  }

  methodInfoGetCrediteeList = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.CrediteeList,
    (request: proto_user_user_pb.BettingPlace) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.CrediteeList.deserializeBinary
  );

  getCrediteeList(
    request: proto_user_user_pb.BettingPlace,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.CrediteeList>;

  getCrediteeList(
    request: proto_user_user_pb.BettingPlace,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.CrediteeList) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.CrediteeList>;

  getCrediteeList(
    request: proto_user_user_pb.BettingPlace,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.CrediteeList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetCrediteeList',
        request,
        metadata || {},
        this.methodInfoGetCrediteeList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetCrediteeList',
    request,
    metadata || {},
    this.methodInfoGetCrediteeList);
  }

  methodInfoSetTestBettingPlace = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.IsTestValues) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  setTestBettingPlace(
    request: proto_user_user_pb.IsTestValues,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  setTestBettingPlace(
    request: proto_user_user_pb.IsTestValues,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  setTestBettingPlace(
    request: proto_user_user_pb.IsTestValues,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetTestBettingPlace',
        request,
        metadata || {},
        this.methodInfoSetTestBettingPlace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetTestBettingPlace',
    request,
    metadata || {},
    this.methodInfoSetTestBettingPlace);
  }

  methodInfoGetTestBettingPlace = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetTestBettingPlaceResponse,
    (request: proto_user_user_pb.TestBettingPlaceRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetTestBettingPlaceResponse.deserializeBinary
  );

  getTestBettingPlace(
    request: proto_user_user_pb.TestBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetTestBettingPlaceResponse>;

  getTestBettingPlace(
    request: proto_user_user_pb.TestBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetTestBettingPlaceResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetTestBettingPlaceResponse>;

  getTestBettingPlace(
    request: proto_user_user_pb.TestBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetTestBettingPlaceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetTestBettingPlace',
        request,
        metadata || {},
        this.methodInfoGetTestBettingPlace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetTestBettingPlace',
    request,
    metadata || {},
    this.methodInfoGetTestBettingPlace);
  }

  methodInfoGetTestBettingPlaces = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.TestBettingPlacesResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.TestBettingPlacesResponse.deserializeBinary
  );

  getTestBettingPlaces(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.TestBettingPlacesResponse>;

  getTestBettingPlaces(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.TestBettingPlacesResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.TestBettingPlacesResponse>;

  getTestBettingPlaces(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.TestBettingPlacesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetTestBettingPlaces',
        request,
        metadata || {},
        this.methodInfoGetTestBettingPlaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetTestBettingPlaces',
    request,
    metadata || {},
    this.methodInfoGetTestBettingPlaces);
  }

  methodInfoSetAllowDeposit = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.SetAllowDepositRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  setAllowDeposit(
    request: proto_user_user_pb.SetAllowDepositRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  setAllowDeposit(
    request: proto_user_user_pb.SetAllowDepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  setAllowDeposit(
    request: proto_user_user_pb.SetAllowDepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetAllowDeposit',
        request,
        metadata || {},
        this.methodInfoSetAllowDeposit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetAllowDeposit',
    request,
    metadata || {},
    this.methodInfoSetAllowDeposit);
  }

  methodInfoGetAllowDeposit = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetAllowDepositResponse,
    (request: proto_user_user_pb.GetAllowDepositRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetAllowDepositResponse.deserializeBinary
  );

  getAllowDeposit(
    request: proto_user_user_pb.GetAllowDepositRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetAllowDepositResponse>;

  getAllowDeposit(
    request: proto_user_user_pb.GetAllowDepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAllowDepositResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetAllowDepositResponse>;

  getAllowDeposit(
    request: proto_user_user_pb.GetAllowDepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAllowDepositResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetAllowDeposit',
        request,
        metadata || {},
        this.methodInfoGetAllowDeposit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetAllowDeposit',
    request,
    metadata || {},
    this.methodInfoGetAllowDeposit);
  }

  methodInfoCreateNewBettingPlace = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.NewBettingPlace,
    (request: proto_user_user_pb.NewBettingPlaceRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.NewBettingPlace.deserializeBinary
  );

  createNewBettingPlace(
    request: proto_user_user_pb.NewBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.NewBettingPlace>;

  createNewBettingPlace(
    request: proto_user_user_pb.NewBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.NewBettingPlace) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.NewBettingPlace>;

  createNewBettingPlace(
    request: proto_user_user_pb.NewBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.NewBettingPlace) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateNewBettingPlace',
        request,
        metadata || {},
        this.methodInfoCreateNewBettingPlace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateNewBettingPlace',
    request,
    metadata || {},
    this.methodInfoCreateNewBettingPlace);
  }

  methodInfoEditBettingPlace = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.NewBettingPlace,
    (request: proto_user_user_pb.EditBettingPlaceRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.NewBettingPlace.deserializeBinary
  );

  editBettingPlace(
    request: proto_user_user_pb.EditBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.NewBettingPlace>;

  editBettingPlace(
    request: proto_user_user_pb.EditBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.NewBettingPlace) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.NewBettingPlace>;

  editBettingPlace(
    request: proto_user_user_pb.EditBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.NewBettingPlace) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EditBettingPlace',
        request,
        metadata || {},
        this.methodInfoEditBettingPlace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EditBettingPlace',
    request,
    metadata || {},
    this.methodInfoEditBettingPlace);
  }

  methodInfoCreateNewBettingPlaceDevice = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingPlaceDevice,
    (request: proto_user_user_pb.BettingPlaceDeviceRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingPlaceDevice.deserializeBinary
  );

  createNewBettingPlaceDevice(
    request: proto_user_user_pb.BettingPlaceDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingPlaceDevice>;

  createNewBettingPlaceDevice(
    request: proto_user_user_pb.BettingPlaceDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingPlaceDevice) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingPlaceDevice>;

  createNewBettingPlaceDevice(
    request: proto_user_user_pb.BettingPlaceDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingPlaceDevice) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateNewBettingPlaceDevice',
        request,
        metadata || {},
        this.methodInfoCreateNewBettingPlaceDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateNewBettingPlaceDevice',
    request,
    metadata || {},
    this.methodInfoCreateNewBettingPlaceDevice);
  }

  methodInfoEditBettingPlaceDevice = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingPlaceDevice,
    (request: proto_user_user_pb.BettingPlaceDeviceRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingPlaceDevice.deserializeBinary
  );

  editBettingPlaceDevice(
    request: proto_user_user_pb.BettingPlaceDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingPlaceDevice>;

  editBettingPlaceDevice(
    request: proto_user_user_pb.BettingPlaceDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingPlaceDevice) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingPlaceDevice>;

  editBettingPlaceDevice(
    request: proto_user_user_pb.BettingPlaceDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingPlaceDevice) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EditBettingPlaceDevice',
        request,
        metadata || {},
        this.methodInfoEditBettingPlaceDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EditBettingPlaceDevice',
    request,
    metadata || {},
    this.methodInfoEditBettingPlaceDevice);
  }

  methodInfoCheckSameMunicipalityBPS = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.CheckSameMunicipalityBPSResponse,
    (request: proto_user_user_pb.CheckSameMunicipalityBPSRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.CheckSameMunicipalityBPSResponse.deserializeBinary
  );

  checkSameMunicipalityBPS(
    request: proto_user_user_pb.CheckSameMunicipalityBPSRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.CheckSameMunicipalityBPSResponse>;

  checkSameMunicipalityBPS(
    request: proto_user_user_pb.CheckSameMunicipalityBPSRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.CheckSameMunicipalityBPSResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.CheckSameMunicipalityBPSResponse>;

  checkSameMunicipalityBPS(
    request: proto_user_user_pb.CheckSameMunicipalityBPSRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.CheckSameMunicipalityBPSResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CheckSameMunicipalityBPS',
        request,
        metadata || {},
        this.methodInfoCheckSameMunicipalityBPS,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CheckSameMunicipalityBPS',
    request,
    metadata || {},
    this.methodInfoCheckSameMunicipalityBPS);
  }

  methodInfoGetBPBettingMachines = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBPBettingMachinesResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBPBettingMachinesResponse.deserializeBinary
  );

  getBPBettingMachines(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBPBettingMachinesResponse>;

  getBPBettingMachines(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBPBettingMachinesResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBPBettingMachinesResponse>;

  getBPBettingMachines(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBPBettingMachinesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBPBettingMachines',
        request,
        metadata || {},
        this.methodInfoGetBPBettingMachines,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBPBettingMachines',
    request,
    metadata || {},
    this.methodInfoGetBPBettingMachines);
  }

  methodInfoSetBettingPlaceMinimumLimits = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BettingPlaceLimitsRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  setBettingPlaceMinimumLimits(
    request: proto_user_user_pb.BettingPlaceLimitsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  setBettingPlaceMinimumLimits(
    request: proto_user_user_pb.BettingPlaceLimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  setBettingPlaceMinimumLimits(
    request: proto_user_user_pb.BettingPlaceLimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetBettingPlaceMinimumLimits',
        request,
        metadata || {},
        this.methodInfoSetBettingPlaceMinimumLimits,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetBettingPlaceMinimumLimits',
    request,
    metadata || {},
    this.methodInfoSetBettingPlaceMinimumLimits);
  }

  methodInfoRequestNewBettingPlaceLimits = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BettingPlaceLimitsRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  requestNewBettingPlaceLimits(
    request: proto_user_user_pb.BettingPlaceLimitsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  requestNewBettingPlaceLimits(
    request: proto_user_user_pb.BettingPlaceLimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  requestNewBettingPlaceLimits(
    request: proto_user_user_pb.BettingPlaceLimitsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/RequestNewBettingPlaceLimits',
        request,
        metadata || {},
        this.methodInfoRequestNewBettingPlaceLimits,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/RequestNewBettingPlaceLimits',
    request,
    metadata || {},
    this.methodInfoRequestNewBettingPlaceLimits);
  }

  methodInfoSetActiveBettingPlace = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.SetActiveBettingPlaceRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  setActiveBettingPlace(
    request: proto_user_user_pb.SetActiveBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  setActiveBettingPlace(
    request: proto_user_user_pb.SetActiveBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  setActiveBettingPlace(
    request: proto_user_user_pb.SetActiveBettingPlaceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetActiveBettingPlace',
        request,
        metadata || {},
        this.methodInfoSetActiveBettingPlace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetActiveBettingPlace',
    request,
    metadata || {},
    this.methodInfoSetActiveBettingPlace);
  }

  methodInfoGetEmployees = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetEmployeesResponse,
    (request: proto_user_user_pb.GetEmployeesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetEmployeesResponse.deserializeBinary
  );

  getEmployees(
    request: proto_user_user_pb.GetEmployeesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetEmployeesResponse>;

  getEmployees(
    request: proto_user_user_pb.GetEmployeesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetEmployeesResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetEmployeesResponse>;

  getEmployees(
    request: proto_user_user_pb.GetEmployeesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetEmployeesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetEmployees',
        request,
        metadata || {},
        this.methodInfoGetEmployees,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetEmployees',
    request,
    metadata || {},
    this.methodInfoGetEmployees);
  }

  methodInfoCreateEmployee = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Employee,
    (request: proto_user_user_pb.Employee) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Employee.deserializeBinary
  );

  createEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Employee>;

  createEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Employee>;

  createEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateEmployee',
        request,
        metadata || {},
        this.methodInfoCreateEmployee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateEmployee',
    request,
    metadata || {},
    this.methodInfoCreateEmployee);
  }

  methodInfoEditEmployee = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Employee,
    (request: proto_user_user_pb.Employee) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Employee.deserializeBinary
  );

  editEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Employee>;

  editEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Employee>;

  editEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EditEmployee',
        request,
        metadata || {},
        this.methodInfoEditEmployee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EditEmployee',
    request,
    metadata || {},
    this.methodInfoEditEmployee);
  }

  methodInfoResetEmployeePassword = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Employee,
    (request: proto_user_user_pb.Employee) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Employee.deserializeBinary
  );

  resetEmployeePassword(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Employee>;

  resetEmployeePassword(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Employee>;

  resetEmployeePassword(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ResetEmployeePassword',
        request,
        metadata || {},
        this.methodInfoResetEmployeePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ResetEmployeePassword',
    request,
    metadata || {},
    this.methodInfoResetEmployeePassword);
  }

  methodInfoChangeEmployeePassword = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Employee,
    (request: proto_user_user_pb.Employee) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Employee.deserializeBinary
  );

  changeEmployeePassword(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Employee>;

  changeEmployeePassword(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Employee>;

  changeEmployeePassword(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ChangeEmployeePassword',
        request,
        metadata || {},
        this.methodInfoChangeEmployeePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ChangeEmployeePassword',
    request,
    metadata || {},
    this.methodInfoChangeEmployeePassword);
  }

  methodInfoDeleteEmployee = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Employee,
    (request: proto_user_user_pb.Employee) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Employee.deserializeBinary
  );

  deleteEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Employee>;

  deleteEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Employee>;

  deleteEmployee(
    request: proto_user_user_pb.Employee,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Employee) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DeleteEmployee',
        request,
        metadata || {},
        this.methodInfoDeleteEmployee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DeleteEmployee',
    request,
    metadata || {},
    this.methodInfoDeleteEmployee);
  }

  methodInfoGetBackOfficeUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBackOfficeUsersResponse,
    (request: proto_user_user_pb.GetBackOfficeUsersRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBackOfficeUsersResponse.deserializeBinary
  );

  getBackOfficeUsers(
    request: proto_user_user_pb.GetBackOfficeUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBackOfficeUsersResponse>;

  getBackOfficeUsers(
    request: proto_user_user_pb.GetBackOfficeUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBackOfficeUsersResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBackOfficeUsersResponse>;

  getBackOfficeUsers(
    request: proto_user_user_pb.GetBackOfficeUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBackOfficeUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBackOfficeUsers',
        request,
        metadata || {},
        this.methodInfoGetBackOfficeUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBackOfficeUsers',
    request,
    metadata || {},
    this.methodInfoGetBackOfficeUsers);
  }

  methodInfoGetBackOfficePermissionMap = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BoPermissionMap,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BoPermissionMap.deserializeBinary
  );

  getBackOfficePermissionMap(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BoPermissionMap>;

  getBackOfficePermissionMap(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BoPermissionMap) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BoPermissionMap>;

  getBackOfficePermissionMap(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BoPermissionMap) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBackOfficePermissionMap',
        request,
        metadata || {},
        this.methodInfoGetBackOfficePermissionMap,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBackOfficePermissionMap',
    request,
    metadata || {},
    this.methodInfoGetBackOfficePermissionMap);
  }

  methodInfoUpdateBackOfficeUserPermission = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BackOfficeUser) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateBackOfficeUserPermission(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateBackOfficeUserPermission(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateBackOfficeUserPermission(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateBackOfficeUserPermission',
        request,
        metadata || {},
        this.methodInfoUpdateBackOfficeUserPermission,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateBackOfficeUserPermission',
    request,
    metadata || {},
    this.methodInfoUpdateBackOfficeUserPermission);
  }

  methodInfoCreateEditBackOfficeUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BackOfficeUser,
    (request: proto_user_user_pb.BackOfficeUser) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BackOfficeUser.deserializeBinary
  );

  createEditBackOfficeUser(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BackOfficeUser>;

  createEditBackOfficeUser(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BackOfficeUser) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BackOfficeUser>;

  createEditBackOfficeUser(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BackOfficeUser) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateEditBackOfficeUser',
        request,
        metadata || {},
        this.methodInfoCreateEditBackOfficeUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateEditBackOfficeUser',
    request,
    metadata || {},
    this.methodInfoCreateEditBackOfficeUser);
  }

  methodInfoDeleteBackOfficeUser = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BackOfficeUser,
    (request: proto_user_user_pb.BackOfficeUser) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BackOfficeUser.deserializeBinary
  );

  deleteBackOfficeUser(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BackOfficeUser>;

  deleteBackOfficeUser(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BackOfficeUser) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BackOfficeUser>;

  deleteBackOfficeUser(
    request: proto_user_user_pb.BackOfficeUser,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BackOfficeUser) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DeleteBackOfficeUser',
        request,
        metadata || {},
        this.methodInfoDeleteBackOfficeUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DeleteBackOfficeUser',
    request,
    metadata || {},
    this.methodInfoDeleteBackOfficeUser);
  }

  methodInfoGetAgents = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetAgentsResponse,
    (request: proto_user_user_pb.GetAgentsRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetAgentsResponse.deserializeBinary
  );

  getAgents(
    request: proto_user_user_pb.GetAgentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetAgentsResponse>;

  getAgents(
    request: proto_user_user_pb.GetAgentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAgentsResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetAgentsResponse>;

  getAgents(
    request: proto_user_user_pb.GetAgentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAgentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetAgents',
        request,
        metadata || {},
        this.methodInfoGetAgents,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetAgents',
    request,
    metadata || {},
    this.methodInfoGetAgents);
  }

  methodInfoCreateEditAgent = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Agent,
    (request: proto_user_user_pb.Agent) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Agent.deserializeBinary
  );

  createEditAgent(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Agent>;

  createEditAgent(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Agent) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Agent>;

  createEditAgent(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Agent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateEditAgent',
        request,
        metadata || {},
        this.methodInfoCreateEditAgent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateEditAgent',
    request,
    metadata || {},
    this.methodInfoCreateEditAgent);
  }

  methodInfoDeleteAgent = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.Agent,
    (request: proto_user_user_pb.Agent) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.Agent.deserializeBinary
  );

  deleteAgent(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.Agent>;

  deleteAgent(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.Agent) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.Agent>;

  deleteAgent(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.Agent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DeleteAgent',
        request,
        metadata || {},
        this.methodInfoDeleteAgent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DeleteAgent',
    request,
    metadata || {},
    this.methodInfoDeleteAgent);
  }

  methodInfoGetAgentCommissionAndLimit = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetAgentCommisionAndLimitResponse,
    (request: proto_user_user_pb.Agent) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetAgentCommisionAndLimitResponse.deserializeBinary
  );

  getAgentCommissionAndLimit(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetAgentCommisionAndLimitResponse>;

  getAgentCommissionAndLimit(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAgentCommisionAndLimitResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetAgentCommisionAndLimitResponse>;

  getAgentCommissionAndLimit(
    request: proto_user_user_pb.Agent,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAgentCommisionAndLimitResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetAgentCommissionAndLimit',
        request,
        metadata || {},
        this.methodInfoGetAgentCommissionAndLimit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetAgentCommissionAndLimit',
    request,
    metadata || {},
    this.methodInfoGetAgentCommissionAndLimit);
  }

  methodInfoAgentPayin = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetAgentCommisionAndLimitResponse,
    (request: proto_user_user_pb.AgentPayinRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetAgentCommisionAndLimitResponse.deserializeBinary
  );

  agentPayin(
    request: proto_user_user_pb.AgentPayinRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetAgentCommisionAndLimitResponse>;

  agentPayin(
    request: proto_user_user_pb.AgentPayinRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAgentCommisionAndLimitResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetAgentCommisionAndLimitResponse>;

  agentPayin(
    request: proto_user_user_pb.AgentPayinRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetAgentCommisionAndLimitResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/AgentPayin',
        request,
        metadata || {},
        this.methodInfoAgentPayin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/AgentPayin',
    request,
    metadata || {},
    this.methodInfoAgentPayin);
  }

  methodInfoGetBettingMachines = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingMachinesResponse,
    (request: proto_user_user_pb.GetBettingMachinesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingMachinesResponse.deserializeBinary
  );

  getBettingMachines(
    request: proto_user_user_pb.GetBettingMachinesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingMachinesResponse>;

  getBettingMachines(
    request: proto_user_user_pb.GetBettingMachinesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingMachinesResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingMachinesResponse>;

  getBettingMachines(
    request: proto_user_user_pb.GetBettingMachinesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingMachinesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingMachines',
        request,
        metadata || {},
        this.methodInfoGetBettingMachines,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingMachines',
    request,
    metadata || {},
    this.methodInfoGetBettingMachines);
  }

  methodInfoCreateBettingMachine = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachine,
    (request: proto_user_user_pb.BettingMachine) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachine.deserializeBinary
  );

  createBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachine>;

  createBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachine) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachine>;

  createBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachine) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateBettingMachine',
        request,
        metadata || {},
        this.methodInfoCreateBettingMachine,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateBettingMachine',
    request,
    metadata || {},
    this.methodInfoCreateBettingMachine);
  }

  methodInfoEditBettingMachine = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachine,
    (request: proto_user_user_pb.BettingMachine) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachine.deserializeBinary
  );

  editBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachine>;

  editBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachine) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachine>;

  editBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachine) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EditBettingMachine',
        request,
        metadata || {},
        this.methodInfoEditBettingMachine,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EditBettingMachine',
    request,
    metadata || {},
    this.methodInfoEditBettingMachine);
  }

  methodInfoDeleteBettingMachine = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachine,
    (request: proto_user_user_pb.BettingMachine) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachine.deserializeBinary
  );

  deleteBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachine>;

  deleteBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachine) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachine>;

  deleteBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachine) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DeleteBettingMachine',
        request,
        metadata || {},
        this.methodInfoDeleteBettingMachine,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DeleteBettingMachine',
    request,
    metadata || {},
    this.methodInfoDeleteBettingMachine);
  }

  methodInfoGetBettingMachineByUsernameAndToken = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingMachineByUsernameAndTokenResponse,
    (request: proto_user_user_pb.GetBettingMachineByUsernameAndTokenRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingMachineByUsernameAndTokenResponse.deserializeBinary
  );

  getBettingMachineByUsernameAndToken(
    request: proto_user_user_pb.GetBettingMachineByUsernameAndTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingMachineByUsernameAndTokenResponse>;

  getBettingMachineByUsernameAndToken(
    request: proto_user_user_pb.GetBettingMachineByUsernameAndTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingMachineByUsernameAndTokenResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingMachineByUsernameAndTokenResponse>;

  getBettingMachineByUsernameAndToken(
    request: proto_user_user_pb.GetBettingMachineByUsernameAndTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingMachineByUsernameAndTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingMachineByUsernameAndToken',
        request,
        metadata || {},
        this.methodInfoGetBettingMachineByUsernameAndToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingMachineByUsernameAndToken',
    request,
    metadata || {},
    this.methodInfoGetBettingMachineByUsernameAndToken);
  }

  methodInfoGetAllBettingMachinesForBettingPlace = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetBettingMachinesResponse,
    (request: proto_user_user_pb.BettingPlaceId) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetBettingMachinesResponse.deserializeBinary
  );

  getAllBettingMachinesForBettingPlace(
    request: proto_user_user_pb.BettingPlaceId,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetBettingMachinesResponse>;

  getAllBettingMachinesForBettingPlace(
    request: proto_user_user_pb.BettingPlaceId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingMachinesResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetBettingMachinesResponse>;

  getAllBettingMachinesForBettingPlace(
    request: proto_user_user_pb.BettingPlaceId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetBettingMachinesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetAllBettingMachinesForBettingPlace',
        request,
        metadata || {},
        this.methodInfoGetAllBettingMachinesForBettingPlace,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetAllBettingMachinesForBettingPlace',
    request,
    metadata || {},
    this.methodInfoGetAllBettingMachinesForBettingPlace);
  }

  methodInfoGetLiveBettingMachines = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.GetLiveBettingMachinesResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.GetLiveBettingMachinesResponse.deserializeBinary
  );

  getLiveBettingMachines(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.GetLiveBettingMachinesResponse>;

  getLiveBettingMachines(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetLiveBettingMachinesResponse) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.GetLiveBettingMachinesResponse>;

  getLiveBettingMachines(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.GetLiveBettingMachinesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetLiveBettingMachines',
        request,
        metadata || {},
        this.methodInfoGetLiveBettingMachines,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetLiveBettingMachines',
    request,
    metadata || {},
    this.methodInfoGetLiveBettingMachines);
  }

  methodInfoEmptyBettingMachine = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BettingMachine) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  emptyBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  emptyBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  emptyBettingMachine(
    request: proto_user_user_pb.BettingMachine,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EmptyBettingMachine',
        request,
        metadata || {},
        this.methodInfoEmptyBettingMachine,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EmptyBettingMachine',
    request,
    metadata || {},
    this.methodInfoEmptyBettingMachine);
  }

  methodInfoCreateBettingMachineExternalLink = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachineExternalLink,
    (request: proto_user_user_pb.BettingMachineExternalLink) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachineExternalLink.deserializeBinary
  );

  createBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachineExternalLink>;

  createBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLink) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachineExternalLink>;

  createBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLink) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateBettingMachineExternalLink',
        request,
        metadata || {},
        this.methodInfoCreateBettingMachineExternalLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateBettingMachineExternalLink',
    request,
    metadata || {},
    this.methodInfoCreateBettingMachineExternalLink);
  }

  methodInfoDeleteBettingMachineExternalLink = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachineExternalLink,
    (request: proto_user_user_pb.BettingMachineExternalLink) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachineExternalLink.deserializeBinary
  );

  deleteBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachineExternalLink>;

  deleteBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLink) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachineExternalLink>;

  deleteBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLink) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DeleteBettingMachineExternalLink',
        request,
        metadata || {},
        this.methodInfoDeleteBettingMachineExternalLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DeleteBettingMachineExternalLink',
    request,
    metadata || {},
    this.methodInfoDeleteBettingMachineExternalLink);
  }

  methodInfoEditBettingMachineExternalLink = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachineExternalLink,
    (request: proto_user_user_pb.BettingMachineExternalLink) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachineExternalLink.deserializeBinary
  );

  editBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachineExternalLink>;

  editBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLink) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachineExternalLink>;

  editBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLink) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EditBettingMachineExternalLink',
        request,
        metadata || {},
        this.methodInfoEditBettingMachineExternalLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EditBettingMachineExternalLink',
    request,
    metadata || {},
    this.methodInfoEditBettingMachineExternalLink);
  }

  methodInfoGetBettingMachineExternalLinks = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BettingMachineExternalLinks,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BettingMachineExternalLinks.deserializeBinary
  );

  getBettingMachineExternalLinks(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BettingMachineExternalLinks>;

  getBettingMachineExternalLinks(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLinks) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BettingMachineExternalLinks>;

  getBettingMachineExternalLinks(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BettingMachineExternalLinks) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBettingMachineExternalLinks',
        request,
        metadata || {},
        this.methodInfoGetBettingMachineExternalLinks,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBettingMachineExternalLinks',
    request,
    metadata || {},
    this.methodInfoGetBettingMachineExternalLinks);
  }

  methodInfoSetBettingMachineExternalLink = new grpcWeb.AbstractClientBase.MethodInfo(
    google_protobuf_empty_pb.Empty,
    (request: proto_user_user_pb.BettingMachineExternalLink) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  setBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  setBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  setBettingMachineExternalLink(
    request: proto_user_user_pb.BettingMachineExternalLink,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetBettingMachineExternalLink',
        request,
        metadata || {},
        this.methodInfoSetBettingMachineExternalLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetBettingMachineExternalLink',
    request,
    metadata || {},
    this.methodInfoSetBettingMachineExternalLink);
  }

  methodInfoCreateNewBMMenuItem = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BMMenuItem,
    (request: proto_user_user_pb.NewBMMenuItem) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BMMenuItem.deserializeBinary
  );

  createNewBMMenuItem(
    request: proto_user_user_pb.NewBMMenuItem,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BMMenuItem>;

  createNewBMMenuItem(
    request: proto_user_user_pb.NewBMMenuItem,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BMMenuItem) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BMMenuItem>;

  createNewBMMenuItem(
    request: proto_user_user_pb.NewBMMenuItem,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BMMenuItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/CreateNewBMMenuItem',
        request,
        metadata || {},
        this.methodInfoCreateNewBMMenuItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/CreateNewBMMenuItem',
    request,
    metadata || {},
    this.methodInfoCreateNewBMMenuItem);
  }

  methodInfoEditBMMenuItem = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BMMenuItem,
    (request: proto_user_user_pb.EditBMMenuItemRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BMMenuItem.deserializeBinary
  );

  editBMMenuItem(
    request: proto_user_user_pb.EditBMMenuItemRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BMMenuItem>;

  editBMMenuItem(
    request: proto_user_user_pb.EditBMMenuItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BMMenuItem) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BMMenuItem>;

  editBMMenuItem(
    request: proto_user_user_pb.EditBMMenuItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BMMenuItem) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/EditBMMenuItem',
        request,
        metadata || {},
        this.methodInfoEditBMMenuItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/EditBMMenuItem',
    request,
    metadata || {},
    this.methodInfoEditBMMenuItem);
  }

  methodInfoGetAllBMMenuItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getAllBMMenuItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getAllBMMenuItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getAllBMMenuItems(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetAllBMMenuItems',
        request,
        metadata || {},
        this.methodInfoGetAllBMMenuItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetAllBMMenuItems',
    request,
    metadata || {},
    this.methodInfoGetAllBMMenuItems);
  }

  methodInfoUpdateGlobalActiveMenu = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.AllBMMenuItems) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateGlobalActiveMenu(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateGlobalActiveMenu(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateGlobalActiveMenu(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateGlobalActiveMenu',
        request,
        metadata || {},
        this.methodInfoUpdateGlobalActiveMenu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateGlobalActiveMenu',
    request,
    metadata || {},
    this.methodInfoUpdateGlobalActiveMenu);
  }

  methodInfoUpdateActiveMenuForIndependentBM = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.IndependentBMMenuItem) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateActiveMenuForIndependentBM(
    request: proto_user_user_pb.IndependentBMMenuItem,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateActiveMenuForIndependentBM(
    request: proto_user_user_pb.IndependentBMMenuItem,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateActiveMenuForIndependentBM(
    request: proto_user_user_pb.IndependentBMMenuItem,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateActiveMenuForIndependentBM',
        request,
        metadata || {},
        this.methodInfoUpdateActiveMenuForIndependentBM,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateActiveMenuForIndependentBM',
    request,
    metadata || {},
    this.methodInfoUpdateActiveMenuForIndependentBM);
  }

  methodInfoUpdateGlobalAllActiveMenu = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.AllBMMenuItems) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateGlobalAllActiveMenu(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateGlobalAllActiveMenu(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateGlobalAllActiveMenu(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateGlobalAllActiveMenu',
        request,
        metadata || {},
        this.methodInfoUpdateGlobalAllActiveMenu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateGlobalAllActiveMenu',
    request,
    metadata || {},
    this.methodInfoUpdateGlobalAllActiveMenu);
  }

  methodInfoGetGlobalActiveMenu = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getGlobalActiveMenu(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getGlobalActiveMenu(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getGlobalActiveMenu(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetGlobalActiveMenu',
        request,
        metadata || {},
        this.methodInfoGetGlobalActiveMenu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetGlobalActiveMenu',
    request,
    metadata || {},
    this.methodInfoGetGlobalActiveMenu);
  }

  methodInfoGetIndependentActiveMenu = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: proto_user_user_pb.GetBettingMachineDevicesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getIndependentActiveMenu(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getIndependentActiveMenu(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getIndependentActiveMenu(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetIndependentActiveMenu',
        request,
        metadata || {},
        this.methodInfoGetIndependentActiveMenu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetIndependentActiveMenu',
    request,
    metadata || {},
    this.methodInfoGetIndependentActiveMenu);
  }

  methodInfoDeleteBMMenuItem = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.AllBMMenuItems) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  deleteBMMenuItem(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  deleteBMMenuItem(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  deleteBMMenuItem(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/DeleteBMMenuItem',
        request,
        metadata || {},
        this.methodInfoDeleteBMMenuItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/DeleteBMMenuItem',
    request,
    metadata || {},
    this.methodInfoDeleteBMMenuItem);
  }

  methodInfoGetBaseActiveMenu = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: proto_user_user_pb.GetBettingMachineDevicesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getBaseActiveMenu(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getBaseActiveMenu(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getBaseActiveMenu(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBaseActiveMenu',
        request,
        metadata || {},
        this.methodInfoGetBaseActiveMenu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBaseActiveMenu',
    request,
    metadata || {},
    this.methodInfoGetBaseActiveMenu);
  }

  methodInfoUpdateHeaderItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.AllBMMenuItems) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateHeaderItems(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateHeaderItems(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateHeaderItems(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateHeaderItems',
        request,
        metadata || {},
        this.methodInfoUpdateHeaderItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateHeaderItems',
    request,
    metadata || {},
    this.methodInfoUpdateHeaderItems);
  }

  methodInfoUpdateHeaderItemsBP = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.AllBMMenuItems) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateHeaderItemsBP(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateHeaderItemsBP(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateHeaderItemsBP(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateHeaderItemsBP',
        request,
        metadata || {},
        this.methodInfoUpdateHeaderItemsBP,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateHeaderItemsBP',
    request,
    metadata || {},
    this.methodInfoUpdateHeaderItemsBP);
  }

  methodInfoUpdateHeaderItemsBM = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.AllBMMenuItems) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  updateHeaderItemsBM(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  updateHeaderItemsBM(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  updateHeaderItemsBM(
    request: proto_user_user_pb.AllBMMenuItems,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/UpdateHeaderItemsBM',
        request,
        metadata || {},
        this.methodInfoUpdateHeaderItemsBM,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/UpdateHeaderItemsBM',
    request,
    metadata || {},
    this.methodInfoUpdateHeaderItemsBM);
  }

  methodInfoGetHeaderItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: proto_user_user_pb.GetBettingMachineDevicesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetHeaderItems',
        request,
        metadata || {},
        this.methodInfoGetHeaderItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetHeaderItems',
    request,
    metadata || {},
    this.methodInfoGetHeaderItems);
  }

  methodInfoGetBPHeaderItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: proto_user_user_pb.GetBettingMachineDevicesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getBPHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getBPHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getBPHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBPHeaderItems',
        request,
        metadata || {},
        this.methodInfoGetBPHeaderItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBPHeaderItems',
    request,
    metadata || {},
    this.methodInfoGetBPHeaderItems);
  }

  methodInfoGetBMHeaderItems = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.AllBMMenuItems,
    (request: proto_user_user_pb.GetBettingMachineDevicesRequest) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.AllBMMenuItems.deserializeBinary
  );

  getBMHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.AllBMMenuItems>;

  getBMHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.AllBMMenuItems>;

  getBMHeaderItems(
    request: proto_user_user_pb.GetBettingMachineDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.AllBMMenuItems) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetBMHeaderItems',
        request,
        metadata || {},
        this.methodInfoGetBMHeaderItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetBMHeaderItems',
    request,
    metadata || {},
    this.methodInfoGetBMHeaderItems);
  }

  methodInfoExecuteCommand = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BMCommandRequest) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  executeCommand(
    request: proto_user_user_pb.BMCommandRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  executeCommand(
    request: proto_user_user_pb.BMCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  executeCommand(
    request: proto_user_user_pb.BMCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ExecuteCommand',
        request,
        metadata || {},
        this.methodInfoExecuteCommand,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ExecuteCommand',
    request,
    metadata || {},
    this.methodInfoExecuteCommand);
  }

  methodInfoSetSecondaryBMWindow = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_common_common_pb.EmptyError,
    (request: proto_user_user_pb.BMSecondaryWindow) => {
      return request.serializeBinary();
    },
    proto_common_common_pb.EmptyError.deserializeBinary
  );

  setSecondaryBMWindow(
    request: proto_user_user_pb.BMSecondaryWindow,
    metadata: grpcWeb.Metadata | null): Promise<proto_common_common_pb.EmptyError>;

  setSecondaryBMWindow(
    request: proto_user_user_pb.BMSecondaryWindow,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void): grpcWeb.ClientReadableStream<proto_common_common_pb.EmptyError>;

  setSecondaryBMWindow(
    request: proto_user_user_pb.BMSecondaryWindow,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_common_common_pb.EmptyError) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetSecondaryBMWindow',
        request,
        metadata || {},
        this.methodInfoSetSecondaryBMWindow,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetSecondaryBMWindow',
    request,
    metadata || {},
    this.methodInfoSetSecondaryBMWindow);
  }

  methodInfoGetSecondaryBMWindow = new grpcWeb.AbstractClientBase.MethodInfo(
    proto_user_user_pb.BMSecondaryWindow,
    (request: proto_user_user_pb.BMSecondaryWindow) => {
      return request.serializeBinary();
    },
    proto_user_user_pb.BMSecondaryWindow.deserializeBinary
  );

  getSecondaryBMWindow(
    request: proto_user_user_pb.BMSecondaryWindow,
    metadata: grpcWeb.Metadata | null): Promise<proto_user_user_pb.BMSecondaryWindow>;

  getSecondaryBMWindow(
    request: proto_user_user_pb.BMSecondaryWindow,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: proto_user_user_pb.BMSecondaryWindow) => void): grpcWeb.ClientReadableStream<proto_user_user_pb.BMSecondaryWindow>;

  getSecondaryBMWindow(
    request: proto_user_user_pb.BMSecondaryWindow,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: proto_user_user_pb.BMSecondaryWindow) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/GetSecondaryBMWindow',
        request,
        metadata || {},
        this.methodInfoGetSecondaryBMWindow,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/GetSecondaryBMWindow',
    request,
    metadata || {},
    this.methodInfoGetSecondaryBMWindow);
  }

}

