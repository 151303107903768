import { FeedMessageType } from 'modules/feed/feed.api';
import feedStore from 'modules/feed/feed.store';

interface ConnectedOdd {
  id: number;
  handlers: any;
}

class OddFeedService {
  /** Store all odds received from websocket */
  feedOdds = new Map();

  connectedOdds = new Map<string, ConnectedOdd[]>();

  constructor() {
    feedStore.registerHandler(FeedMessageType.EVENT, this.onEventUpdate);
  }

  onEventUpdate = (data: any) => {
    if (data.odds && data.odds.length) {
      this.handleOddsUpdate(data);
    }
  };

  saveInFeed = (event: string, odds: any) => {
    if (!this.feedOdds.has(event)) {
      this.feedOdds.set(event, new Map());
    }

    const feedEvent = this.feedOdds.get(event);

    odds.forEach((odd: any) => {
      feedEvent.set(odd.id, odd.v ? parseFloat(odd.v) : null);
    });
  };

  getFromFeed = (eventId: string, oddId: number): number | null => {
    const event = this.feedOdds.get(eventId);
    if (!event) return null;
    return event.get(oddId) || null;
  };

  update = (event: string, odds: ConnectedOdd[]) => {
    this.connectedOdds.set(event, odds);
  };

  isConnected = (event: string) => {
    return this.connectedOdds.has(event);
  };

  disconnectOdd = (event: string, oddId: number) => {
    const eventOdds = this.connectedOdds.get(event);
    if (!eventOdds) return;
    this.connectedOdds.set(
      event,
      eventOdds.filter((o: ConnectedOdd) => o.id !== oddId)
    );

    if (this.connectedOdds.get(event)?.length === 0) {
      this.connectedOdds.delete(event);
    }
  };

  connectOdd = (eventId: string, oddId: number, handlers: any) => {
    const odd = { id: oddId, handlers };

    if (this.isConnected(eventId)) {
      const connectedOdds = this.connectedOdds.get(eventId) || [];
      this.update(eventId, [...connectedOdds, odd]);
    } else {
      this.update(eventId, [odd]);
    }
  };

  handleOddsUpdate = (data: any) => {
    const { e_id, odds, e_n } = data;
    this.saveInFeed(e_id, odds);

    if (this.isConnected(e_id)) {
      const connectedOdds = this.connectedOdds.get(e_id);
      if (connectedOdds) {
        this.updateOdds(connectedOdds, odds);
      }
    }
  };

  updateOdds = (connectedOdds: ConnectedOdd[], odds: any) => {
    odds.forEach((odd: any) => {
      const connectedOdd = connectedOdds.find((o) => o.id === odd.id);

      if (connectedOdd) {
        connectedOdd.handlers.onValueUpdate(
          odd.v ? parseFloat(odd.v) : null,
          odd.d,
          odd.s
        );
      }
    });
  };
}

export default new OddFeedService();
