export const initMonriForm = (
  el: any,
  data: any,
  cb?: undefined | (() => void)
) => {
  if (!el) {
    console.error('Monri Form: wrapper element does not exist.');
    return;
  }

  if (!data) {
    console.error('Monri Form: data attributes must be provided.');
    return;
  }

  const {
    authenticity_token,
    amount,
    currency,
    order_number,
    order_info,
    digest,
    transaction_type,
    ch_zip,
    ch_phone,
    ch_email,
    ch_address,
    ch_city,
    ch_country,
    ch_full_name,
    language,
    tokenize_pan_offered,
    supported_payment_methods,
  } = data;

  const script = document.createElement('script');
  // @ts-ignore
  script.src = process.env.REACT_APP_MONRI_URL;
  script.classList.add('lightbox-button');
  script.setAttribute('data-authenticity-token', authenticity_token);
  script.setAttribute('data-amount', amount);
  script.setAttribute('data-currency', currency);
  script.setAttribute('data-order-number', order_number);
  script.setAttribute('data-order-info', order_info);
  script.setAttribute('data-digest', digest);
  script.setAttribute('data-transaction-type', transaction_type);
  script.setAttribute('data-ch-full-name', ch_full_name);
  script.setAttribute('data-ch-zip', ch_zip);
  script.setAttribute('data-ch-phone', ch_phone);
  script.setAttribute('data-ch-email', ch_email);
  script.setAttribute('data-ch-address', ch_address);
  script.setAttribute('data-ch-city', ch_city);
  script.setAttribute('data-ch-country', ch_country);
  script.setAttribute('data-language', language);
  script.setAttribute('data-tokenize-pan-offered', tokenize_pan_offered);
  script.setAttribute(
    'data-supported_payment_methods',
    supported_payment_methods
  );
  if (cb) script.onload = cb;

  el.appendChild(script);
};
