const de = {
  translation: {
    casino: {
      'all-games': 'ALLE SPIELE',
      award: 'Vergabe',
      back: 'ZURÜCK',
      'biggest-wins': 'GRÖSSTE GEWINNE',
      'casino-brands': 'CASINO BRANDS',
      categories: 'KATEGORIEN',
      category: 'KATEGORIE',
      close: 'Schließen',
      demo: 'VERSUCH ES',
      'favourite-games': 'LIEBLINGSSPIELE',
      'game-of-week': 'SPIEL DER WOCHE',
      'last-played': 'ZULETZT GESPIELT',
      'most-played': 'MEIST GESPIELT',
      play: 'SPIEL',
      points: 'Punkte',
      provider: 'BREND',
      sections: 'SEKTIONEN',
      'see-all': 'SIEHE ALLES',
      'use-free-casino': 'Benutze Free Spin',
      user: 'Benutzer',
    },
    countries: {
      albania: 'Albanien',
      'bosnia-and-herzegovina': 'Bosnten und Herzegovina',
      croatia: 'Kroatien',
      kosovo: 'Kosovo',
      montenegro: 'Montenegro',
      'republic-of-north-macedonia': 'Nordmazedonien',
      russia: 'Russland',
      serbia: 'Serbien',
      turkey: 'Türkei',
      ukraine: 'Ukraine',
    },
    errorPage: {
      'reload-page': 'Bitte aktualisieren Sie die Seite.',
      'something-went-wrong': 'Ein Fehler ist aufgetreten.',
    },
    errors: {
      'activation-link-exp':
        'Um die Registrierung abzuschließen, müssen Sie Ihr Konto aktivieren. Der Aktivierungslink wurde an Ihre E-Mail-Adresse gesendet.',
      'another-order-exists': 'Es existiert bereits eine andere Bestellung',
      'bad-password': 'Falsches Passwort',
      'bet-not-possible': 'Wetten auf dieses Event sind derzeit nicht möglich',
      'betting-blocked': 'Das Wetten ist gesperrt.',
      'can-not-find-user': 'Benutzer konnte nicht gefunden werden',
      'cancel-funds': 'Geplante Zahlung nicht gefunden',
      'cannot-add-more-than-one-digital-item':
        'Sie können Ihrem Warenkorb nicht mehr als ein digitales Produkt hinzufügen',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Sie können keinen Casino-Bonus kaufen, wenn Sie bereits einen haben',
      'cannot-cancel-order': 'Sie können die Bestellung nicht stornieren',
      'cannot-order-mixed-items':
        'Es ist nicht möglich, verschiedene Produkttypen zu bestellen',
      'cannot-order-more-than-one-digital-item':
        'Sie können nicht mehr als ein digitales Produkt bestellen',
      'choose-betting-place': 'Wählen Sie einen Abholort',
      'connection-offline': 'Internetverbindung unterbrochen!',
      'credentials-error':
        'Benutzername oder Passwort nicht korrekt eingegeben.',
      'data-error-reading':
        'Fehler beim Speichern der Daten. Bitte versuchen Sie es erneut.',
      'email-exists':
        'Ein Benutzer mit dieser E-Mail-Adresse existiert bereits.',
      'email-invalid': 'Die E-Mail-Adresse ist ungültig',
      'err-cash-drop-already-collected':
        'Jemand war schneller als Sie. CashDrop wurde verwendet und ist nicht mehr verfügbar.',
      'err-cash-drop-already-collected-by-user':
        'CashDrop wurde bereits abgeholt',
      'err-cash-drop-group-already-collected-by-user':
        'Sie können von dieser Gruppe keinen CashDrop abholen',
      'err-cash-drop-not-active':
        'CashDrop ist abgelaufen und nicht mehr verfügbar.',
      'err-cash-drop-not-found': 'CashDrop nicht gefunden',
      'err-cash-drop-user-min-balance':
        'Sie müssen mindestens {{a}} EUR haben, um den CashDrop abzuholen',
      'err-cash-drop-user-min-bet':
        'Sie müssen in den letzten {{b}} Stunden mindestens {{a}} EUR ausgegeben haben, um CashDrop in Anspruch nehmen zu können',
      ERR_BETTING_MACHINE_BLOCKED: 'Diese Aktion wurde blockiert',
      ERR_CANT_PAYOUT_BETTING_PLACE:
        'Der Gutschein ist an dieser Zahlungsstelle nicht einlösbar',
      ERR_CANT_PAYOUT_MUNICIPALITY:
        'In dieser Gemeinde ist der Gutschein nicht einlösbar',
      ERR_CASINO_BLOCKED: 'Casinospiele sind gesperrt',
      ERR_DEPOSIT_BLOCKED: 'Die Zahlung des Benutzers wurde blockiert',
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE:
        'Die Einzahlung an dem Wettautomat wurde gesperrt',
      ERR_INVALID_GAME: 'Das Spiel ist nicht gültig',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        'Ticket ist nicht mehr zur Zahlung gültig. Bitte wenden Sie sich an den Betreiber.',
      ERR_USER_BLOCKED_ALREADY: 'Ihr Konto wurde bereits gesperrt.',
      ERR_USER_NOT_VALIDATED: 'Benutzer nicht verifiziert',
      ERR_VIRTUAL_GAMES_BLOCKED: 'Virtuelle Spiele werden blockiert',
      ERR_VOUCHER_CONFIG_NOT_FOUND:
        'Die Gutscheinkonfiguration ist nicht vorhanden',
      ERR_VOUCHER_EXPIRED: 'Der Gutschein ist abgelaufen',
      ERR_VOUCHER_NOT_FOUND: 'Der Gutschein ist nicht vorhanden',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT:
        'Der Gutschein ist nicht zur Auszahlung gültig',
      'error-starting-game': 'Das Spiel kann nicht gestartet werden',
      'error-unknown':
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      'field-invalid': 'Der Eintrag ist ungültig',
      'field-required': 'Dieses Feld ist erforderlich',
      'fields-obligated':
        'Felder, die mit (*) markiert sind, sind Pflichtfelder.',
      'invalid-system-slip': 'Das Systemticket ist ungültig',
      'invalid-user': 'Der Benutzer ist ungültig',
      'item-not-available': 'Das Produkt ist nicht verfügbar',
      'item-not-found': 'Produkt nicht gefunden',
      'jmbg-max': 'Personalcode darf nicht mehr als 13 Zeichen enthalten.',
      'jmbg-min': 'Personalcode muss mindestens 13 Zeichen enthalten.',
      'min-password': 'Das Passwort muss mindestens 6 Zeichen enthalten.',
      'no-betting-place-id': 'Wählen Sie eine Filiale aus',
      'no-events-for-copy': 'Es gibt keine Events, die kopiert werden können',
      'no-items-in-order': 'Es sind keine Produkte in der Bestellung vorhanden',
      'not-enough-points': 'Sie haben nicht genügend Punkte',
      'order-does-not-exist': 'Die Bestellung ist nicht vorhanden',
      'passwords-dont-match': 'Passwort stimmt nicht überein.',
      'payout-error':
        'Beim Versuch, eine Zahlung vorzunehmen, ist ein Fehler aufgetreten.',
      'place-bet-error':
        'Fehler bei der Ticketeinzahlung. Bitte versuchen Sie es erneut.',
      'poker-not-responding': 'Pokerserver ist derzeit nicht verfügbar',
      'poker-success-false': 'Pokerserver-Aktion war nicht erfolgreich',
      'print-error':
        'Beim Druckversuch ist ein Fehler aufgetreten. Wenn das Ticket nicht ausgedruckt ist, wenden Sie sich an den Betreiber.',
      'print-not-operational': 'DER DRUCKER FUNKTIONIERT NICHT',
      'promo-code-invalid': 'Der Promo-Code ist ungültig',
      'reservation-locked': 'Reservierte Mittel sind gesperrt',
      'session-expired':
        'Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.',
      'system-error':
        'Es ist ein Systemfehler aufgetreten. Bitte versuchen Sie es erneut.',
      TEST_USER_CANNOT_DEPOSIT_MONEY_WITH_CARD:
        'Testbenutzer können mit der Karte kein Geld einzahlen.',
      TEST_USERS_CANNOT_WITHDRAW_MONEY: 'Testnutzer können kein Geld abheben.',
      'ticket-changed': 'Ihr Ticket wurde geändert.',
      'ticket-denied': 'Ihr Ticket wurde abgelehnt.',
      'ticket-error-reading': 'Fehler beim Lesen des Tickets.',
      TICKET_NOT_FOUND: 'Ticket nicht gefunden',
      TICKET_NOT_VALID_FOR_PAYOUT:
        'Ticket ist nicht mehr zur Zahlung gültig. Bitte wenden Sie sich an den Betreiber.',
      'token-expired': 'Der Token ist abgelaufen',
      'user-blocked-error':
        'Ihr Konto wurde gesperrt. Kontaktieren Sie Support.',
      'user-exists': 'Benutzer existiert bereits.',
      'user-not-active':
        'Benutzer wurde nicht aktiviert. Bitte aktivieren Sie Ihr Konto.',
      'user-update-error':
        'Fehler beim Speichern der Daten. Bitte versuchen Sie es erneut.',
      'username-exists':
        'Ein Benutzer mit diesem Benutzernamen existiert bereits.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Antepost-Events können nicht auf einem Ticket kombiniert werden',
      'withdrawal-canceled-or-paid':
        'Auszahlung storniert oder ausbezahlt. Bitte aktualisieren Sie die Seite.',
      'withdrawal-max-amount-exceeded':
        'Die maximal zulässige Auszahlung beträgt {{a}}€',
      'withdrawal-min-amount-exceeded':
        'Die zulässige Mindestauszahlung beträgt {{a}}€',
      'zero-item-quantity': 'Es sind keine Produkte vorrätig',
    },
    footer: {
      'about-us': 'Über uns',
      'account-id': 'KONTO-ID',
      'all-rights-reserved': 'Alle Rechte vorbehalten',
      'available-for-payment': 'Zur Einzahlung verfügbar',
      aviator: 'Aviator',
      betting: 'Wetten',
      bonuses: 'Boni',
      casino: 'Casino',
      'casino-live': 'Live-Casino',
      contact: 'Kontakt',
      cookies: 'Cookies',
      description:
        'SBbet organisiert seit 1997 Glücksspiele und bietet seinen Kunden Dienstleistungen auf höchstem Niveau. Mit einem Netzwerk von über 100 Zahlungsstellen in Montenegro sind wir als Marktführer positioniert, was uns zusätzlich dazu motiviert, unser Geschäft auf die Schaffung erstklassiger Unterhaltung im Bereich Glücksspiele auszurichten. Die SBbet-Website ist so konzipiert, dass Sie mit der besten Benutzererfahrung wetten können. Ein riesiges Sport- und Wettbewerbsangebot, attraktive Quoten, Boni, Freebetts und die Möglichkeit, durch unsere Jackpots große Geldbeträge zu gewinnen, sind nur einige der Gründe, dem ältesten Wettanbieter Montenegros mit über 20 Jahren Erfahrung zu vertrauen. Versuchen Sie Ihr Glück bei einem der vielen Casinospiele, unserem Bingo, virtuellen Sportevents oder an einem echten Casinotisch. SBbet.me ist Teil der Sporting Group doo, Mediteranska 21, Budva, Montenegro, unterliegt den Gesetzen Montenegros und ist Inhaber der von der Verwaltung für Glücksspiele ausgestellten Lizenz: 02/01-702/4. Das SBbet-Team wünscht Ihnen viel Glück - Sicher ...',
      'download-list': 'Lade die Liste herunter',
      'follow-us': 'Folgen Sie uns',
      'frequently-questions': 'Häufig gestellte Fragen',
      'game-rules': 'Spielregeln',
      informations: 'Informationen',
      'lucky-6': 'Lucky6',
      'pay-all': 'ALLES EINZAHLEN',
      'payment-to-web': 'Zahlung im WEB',
      'play-responsibly': 'Spiele verantwortungsvoll',
      poker: 'Poker',
      privacy: 'Privatsphäre',
      'promo-code': 'Promo-Code',
      promotions: 'Sonderangebote',
      'site-map': 'Seitenübersicht',
      sport: 'Sport',
      'ticket-check': 'Ticketüberprüfung',
      transactions: 'Transaktionen',
      'using-conditions': 'Nutzungsbedingungen',
    },
    globals: {
      accept: 'AKZEPTIEREN',
      'accept-cookies':
        'Diese Website verwendet Cookies, um die Dienste zu verbessern und Ihnen relevante Inhalte anzuzeigen. Wenn Sie auf der Website weitersurfen, gehen wir davon aus, dass Sie der Verwendung von Cookies zustimmen.',
      account: 'Konto',
      'account-type': 'Konto Typ',
      activation: 'AKTIVIERUNG',
      'add-to-cart': 'In den Warenkorb legen',
      all: 'Alles',
      'amount-caps': 'BETRAG',
      'app-idle-msg':
        'Sie waren eine Zeit lang nicht aktiv. Klicken Sie auf „Aktualisieren“, um ein aktuelles Angebot zu erhalten.',
      approved: 'Genehmigt',
      'betting-history': 'Wettverlauf',
      'booking-an-order': 'Bestellreservierung',
      braon: 'Braun',
      cancel: 'GIB AUF',
      canceled: 'Abgelehnt',
      cart: 'Warenkorb',
      'cart-is-empty': 'Der Warenkorb ist leer',
      Cet: 'Chat',
      'choose-a-betting-place': 'Wählen Sie eine Filiale aus',
      'choose-a-city': 'Wählen Sie eine Stadt aus',
      'choose-order-place':
        'Wählen Sie den Lieferort in der ausgewählten SBbet-Filiale aus',
      'click-for-more-info': 'Klick hier, um weitere Informationen zu kriegen',
      close: 'SCHLIESSEN',
      'code-caps': 'CODE',
      collect: 'Abholen',
      copy: 'Kopieren',
      'copy-ticket': 'Kopiere das Ticket',
      'customer-support': 'Kundensupport',
      date: 'Datum',
      'date-caps': 'DATUM',
      delivered: 'Geliefert',
      'filter-by-date': 'Nach Datum filtern',
      free: 'Kostenlos',
      'id-caps': 'ID',
      immediately: 'Sofort',
      'installation-guide': 'Installationsanleitung',
      loading: 'Wird geladen...',
      'mark-all-as-read': 'Alles als gelesen markieren',
      'my-account': 'Mein Konto',
      'necessary-reload': 'Es ist notwendig, das Angebot zu synchronisieren',
      Ned: 'Sonntag',
      nepar: 'Ungerade',
      no: 'NEIN',
      'no-info-about-event': 'Es gibt keine Informationen über das Event',
      notifications: 'Benachrichtigungen',
      numbers: 'ZAHLEN',
      'order-history': 'Bestellverlauf',
      par: 'Gerade',
      pending: 'In Wartestellung',
      Pet: 'Freitag',
      'pick-date': 'Wählen Sie ein Datum',
      'place-order': 'Produkte bestellen',
      Pon: 'Montag',
      proceed: 'Aktualisieren',
      profile: 'Profil',
      'promo-code': 'Promo-Code',
      register: 'REGISTRIERUNG',
      'remove-from-cart': 'Aus dem Korb nehmen',
      save: 'Speichern',
      'sbbet-promo-code': 'SBbet PROMO-CODE',
      search: 'Durchsuchen',
      'search-1': 'Suche',
      'search-events': 'Events durchsuchen',
      send: 'WEITERLEITEN',
      'send-1': 'SENDEN',
      Sre: 'Mitwoch',
      statistics: 'STATISTIK',
      'status-caps': 'STATUS',
      step: 'Schritt',
      Sub: 'Samstag',
      submit: 'BESTÄTIGEN',
      success: 'Übernommen',
      'successfully-collected-cash-drop':
        'Sie haben erfolgreich Cash Drop abgeholt',
      'ticket-copied': 'Ticket kopiert',
      time: 'Zeit',
      'transaction-history': 'Transaktionsverlauf',
      type: 'TYP',
      'type-caps': 'TYP',
      'user-id-caps': 'BENUTZER-ID',
      Uto: 'Dienstag',
      wallets: 'Konten',
      welcome: 'Willkommen!',
      yes: 'JA',
      zelena: 'Grün',
      žuta: 'Gelb',
    },
    header: {
      betting: 'Wetten',
      casino: 'Kasino',
      'casino-live': 'Kasino live',
      live: 'Live',
      'quick-games': 'Schnelle Spiele',
    },
    home: {
      '100-spins': '100 SPINS',
      'be-in-the-game-with-app': 'SEIEN SIE MIT DER SBBET-APP IM SPIEL',
      betting: 'WETTEN',
      'betting-description':
        'Es erwarten Sie höchste Quoten und das vielfältigste Spiel- und Special-Angebot.',
      'call-center': 'CALL CENTER',
      'casino-description':
        'Die beliebtesten Casinospiele bringen große Jackpots',
      'follow-us': 'FOLGEN SIE UNS',
      'live-betting': 'LIVE-WETTEN',
      'live-betting-description':
        'Live-Wetten sind jetzt doppelt so gut, denn bei SBbet erwartet Sie die größte Auswahl an Live-Sportarten und ein breites Spielangebot!',
      'place-bet': 'WETTEN SIE',
      'promotions-description':
        'Erfahren Sie alles über neue Aktionen und Neuigkeiten.',
      'quick-games-description':
        'Die größte virtuelle Auswahl: Zahlen, Fußball, Rennen und vieles mehr!',
      register: 'Melden Sie sich an',
      'up-to-250': 'BIS 250',
      'we-double-your-first-deposit': 'WIR VERDOPPELN IHRE ERSTE EINZAHLUNG',
    },
    language: {
      english: 'Englisch',
      language: 'Sprache',
      montenegrin: 'Montenegrinisch',
      'select-language': 'Sprache auswählen',
    },
    login: {
      'forgot-password': 'Passwort vergessen?',
      'forgot-password-caps': 'PASSWORT VERGESSEN?',
      'log-in': 'MELDEN SIE SICH AN',
      'log-out': 'Ausloggen',
      login: 'EINLOGGEN',
      logout: 'AUSLOGGEN',
      'logout-1': 'AUSLOGGEN',
      'logout-question': 'Sind Sie sicher, dass Sie sich ausloggen möchten?',
      'sign-in': 'EINLOGGEN',
    },
    navigation: {
      aviator: 'AVIATOR',
      betting: 'WETTEN',
      casino: 'KASINO',
      liveCasino: 'LIVE-CASINO',
      lucky6: 'LUCKY6',
      numbers: 'ZAHLEN',
      offer: 'ANGEBOT',
      poker: 'POKER',
      promotions: 'SONDERANGEBOT',
      quickGames: 'SCHNELLE SPIELE',
      sport: 'SPORT',
    },
    payments: {
      'account-id-confirm': 'Bitte bestätigen Sie, dass Ihre KONTO-ID lautet:',
      amount: 'Betrag',
      'available-for-payment': 'Zur Auszahlung verfügbar',
      balance: 'Balance',
      'balance-after': 'Betrag nachher',
      'balance-before': 'Betrag vorher',
      'bonus-balance': 'Bonusstand',
      'cancel-withdrawal': 'Auszahlung stornieren',
      'cancel-withdrawal-question':
        'Möchten Sie die Auszahlung wirklich stornieren?',
      'cancel-withdrawal-success':
        'Sie haben die Auszahlung erfolgreich storniert.',
      'card-expired':
        'Die Transaktion wurde abgelehnt. Die Karte ist abgelaufen.',
      'card-not-found': 'Karte nicht gefunden. Bitte versuchen Sie es erneut.',
      'card-suspended':
        'Die Transaktion wurde abgelehnt. Die Karte wurde gesperrt.',
      commission: 'Provision',
      credit: 'Auszahlung',
      'credit-card': 'Kreditkarte',
      debit: 'Einzahlung',
      'e-wallet': 'E-Geldbeutel',
      'funds-available-for-payment': 'Zur Auszahlung verfügbare Mittel',
      'funds-that-can-be-raised': 'MITTEL, DIE ABGEHOBEN WERDEN KÖNNEN',
      'in-the-bank': 'BEI DER BANK',
      'in-the-bank-note':
        'Sollten Ihr Vor- und Nachname nicht korrekt sein, wenden Sie sich bitte an den Support',
      'in-the-office': 'IN DER FILIALE',
      'invalid-amount':
        'Die Transaktion wurde abgelehnt. Der Betrag ist ungültig.',
      'invalid-card-number':
        'Die Transaktion wurde abgelehnt. Die Kartennummer ist ungültig.',
      'maximum-credit': 'Maximale Auszahlung',
      'maximum-debit': 'Maximale Einzahlung',
      'minimum-credit': 'minimale Auszahlung',
      'minimum-debit': 'Mindesteinzahlung',
      pay: 'Zahle ein ',
      'payment-default-error':
        'Die Transaktion wurde abgelehnt. Bitte versuchen Sie es erneut.',
      'payment-destination': 'Zahlungsort',
      'payment-success': 'Die Einzahlung wurde erfolgreich durchgeführt.',
      'physical-payment': 'Barzahlung',
      'poker-withdrawal-success': 'Die Zahlung wurde erfolgreich getätigt.',
      'potential-payout': 'Potenzielle Auszahlung:',
      'proceed-with-payment':
        'Setzen Sie die Zahlung fort, indem Sie auf den Knopf klicken',
      skrill: 'Skrill',
      tabaccopress: {
        'deposit-step-1':
          'Bitten Sie den Verkäufer in einer der Filialen von Tobacco S Press, über Zahlungsterminals eine Einzahlung auf Ihr Sbbet-Konto vorzunehmen',
        'deposit-step-2':
          'Es ist notwendig, dem Verkäufer die ID-Nummer Ihres SBBet-Kontos und den Betrag, den Sie einzahlen möchten, mitzuteilen.',
        'deposit-step-3':
          'Der von Ihnen gezahlte Betrag steht Ihnen in der Regel innerhalb weniger Minuten nach der Zahlung auf Ihrem SBBet-Konto zur Verfügung.',
        'deposit-steps':
          'Wie bezahle ich eine Anzahlung über Zahlungsterminals?',
        'instruction-1':
          'Für die Zahlung über Zahlungsterminals ist lediglich die Benutzernummer Ihres SBbet-Kontos (ID-Nummer) erforderlich.',
        'instruction-2':
          'Sie können die Zahlung über Zahlungsterminals an einer der über 300 Verkaufsstellen von Tobacco S Press durchführen',
        'instruction-3':
          'Der Mindestzahlungsbetrag über Zahlungsterminals beträgt 1,00 EUR, der maximale Zahlungsbetrag pro Transaktion beträgt 500,00 EUR.',
        'instruction-4':
          'Im Fehlerfall beträgt die Frist für die Rückabwicklung der Transaktion an der Verkaufsstelle 10 Minuten (sofern das Geld vom Konto nicht ausgegeben wurde).',
        'instruction-5':
          'Sehen Sie sich die Einrichtungen von TOBACCO SPRESS an, in denen Sie unter dem folgenden Link eine Kaution hinterlegen können.',
      },
      terminals: {
        note: 'HINWEIS: Wenn das Geld aus irgendeinem Grund nicht auf Ihr SBBet-Konto eingezahlt wird, kontaktieren Sie bitte den J&A-Service unter 067/788-588 von 9.00 bis 17.00 Uhr und nach 17.00 bis 21.00 Uhr sowie am Wochenende unter 068/488-588.',
        'step-1': 'Wählen Sie am J&A-Terminal das SBbet-Logo aus',
        'step-2':
          'Geben Sie Ihre Benutzerkonto-ID ein und klicken Sie auf die Schaltfläche „ZAHLEN“.',
        'step-3':
          'Geben Sie den gewünschten Geldbetrag in das Terminal ein und das Gerät liest und zeigt an, wie viel Geld Sie eingegeben haben.',
        'step-4':
          'Durch Klicken auf die Schaltfläche „BESTÄTIGEN“ führen Sie die Zahlung erfolgreich durch und erhalten eine gedruckte Quittung für Ihre Transaktion.',
      },
      'transaction-date': 'Transaktionsdatum',
      'transaction-deposit': 'Einzahlung',
      'transaction-id': 'Transaktions-ID',
      'transaction-type': 'Transaktionsart',
      'transaction-web-bet': 'Web-Wette',
      'transaction-web-bet-rollback': 'Web-Wetten-Rollback',
      'transaction-web-casino-credit': 'Casino-Guthaben',
      'transaction-web-casino-debit': 'Casino-Lastschrift',
      'transaction-web-casino-rollback': 'Casino-Rollback',
      'transaction-web-game-bet': 'Web-Spielwette',
      'transaction-web-game-rollback': 'Rollback von Webspielen',
      'transaction-web-game-win': 'Web-Spiel gewinnen',
      'transaction-web-win': 'Web Win',
      'transaction-withdraw': 'Auszahlung',
      transactions: 'Transaktionen',
      withdraw: 'AUSZAHLEN',
      'withdraw-on-the-bank': 'AUSZAHLUNG DURCH DIE BANK',
      'withdraw-on-the-betting-place': 'AUSZAHLUNG IN DER FILIALE',
      'withdrawal-pending': 'Auf Genehmigung',
      'withdrawal-ready': 'Bereit zum Heben',
      'withdrawal-reserved': 'Angeforderte Auszahlung',
      'withdrawal-success':
        'Sie haben erfolgreich eine Auszahlungsanforderung erstellt.',
      'your-transaction-has-been-confirmed': 'Ihre Transaktion wurde bestätigt',
    },
    promoBanner: {
      aviator: 'Aviator',
      betting: 'Wetten',
      blackjack: 'Blackjack',
      cashout: 'Cashout',
      'casino-tournament': 'Casino-Turnier',
      'defeat-goalkeeper-for-bonuses':
        'Besiege den Torwart, um BONI zu erhalten',
      'double-first-payin': 'Wir verdoppeln jede erste Zahlung',
      'download-app': 'Lade die App herunter',
      'fly-to-win': 'Fliege zum Sieg',
      'happy-monday-bonus': 'Happy Monday Bonus',
      'lucky-6': 'Lucky 6',
      'netent-casino-games': 'NetEnt-Casinospiele',
      new: 'Neu',
      'pay-the-ticket-at-any-time': 'Zahle das Ticket jederzeit aus',
      popular: 'Beliebt',
      promotion: 'Promotion',
      roulette: 'Roulette',
      'win-5000-jackpot': 'Gewinne einen Jackpot von 5.000 Euro',
      'win-apartment': 'Gewinne eine Wohnung in Podgorica',
      'win-iphone-13': 'Gewinne ein iPhone 13',
      'win-jackpot-up-to-5000': 'Gewinne einen Jackpot von bis zu 5000 Euro',
    },
    slips: {
      'accept-all-changes': 'Ich akzeptiere alle Änderungen',
      'accept-cashout': 'CASHOUT AKZEPTIEREN',
      'accept-changes': ' Änderungen akzeptieren',
      'accept-changes-after-place-bet':
        'Änderungen nach der Zahlung akzeptieren',
      active: 'Aktiv',
      'actual-winning-amount': 'Auszahlung',
      'all-tickets': 'ALLE TICKETS',
      approving: 'Wartet auf Genehmigung',
      bet: 'Eine Wette',
      'bet-type': 'Tipi und Bastit',
      bonus: 'Bonus:',
      'cancel-cashout': 'Cashout abbrechen',
      cashout: 'CASHOUT',
      'cashout-accepted': 'Cashout akzeptiert',
      'cashout-denied': 'Sie haben Cashout erfolgreich abgelehnt  ',
      'cashout-denied-timer':
        'Cashout wurde aufgrund einer Zeitüberschreitung bei der Annahme abgelehnt',
      changed: 'Geändert',
      'confirm-ticket': 'BESTÄTIGEN SIE DAS TICKET',
      'delete-all': 'Alles löschen',
      'delete-ticket': 'Ticket löschen',
      'delete-ticket-info': 'Möchten Sie dieses Ticket löschen?',
      denied: 'Abgelehnt',
      'deny-cashout': 'CASHOUT ABLEHNEN',
      'erase-multiple-odds':
        'Durch diese Aktion werden Integrale gelöscht, sodass nur die erste Quote in jedem Spiel übrig bleibt.',
      failed: 'Verloren',
      fix: 'FIX',
      'group-number': 'Gruppe-Nr.',
      id: 'Code',
      'max-pot-payout': 'Max. potentielle Zahlung',
      'max-pot-win': 'Max. Gewinn',
      'max-potential-bonus': 'Max. potentielles Bonus',
      'min-max-bonus': 'Min/Max Bonus',
      'min-max-odd': 'Min/Max Quote',
      'min-max-pot-win': 'Min/Max potentielles Gewinn',
      'min-pot-win': 'Min. Gewinn',
      'min-potential-bonus': 'Min. potentielles Bonus',
      'min-winning-total': 'Min. Potentielle Einzahlung',
      multiplier: 'Quote',
      'my-tickets': 'MEINE TICKETS',
      'no-slip-events': 'Es gibt keine Ereignisse im Slip',
      'no-tickets': 'Es gibt keine Tickets.',
      'number-of-combinations': 'Anzahl der Kombinationen:',
      'number-of-matches': 'Paaranzahl:',
      'number-of-pairs': 'Paar Nr.:',
      'number-of-tickets': 'Ticketnummer:',
      odd: 'Quote:',
      paid: 'Bezahlt',
      passed: 'Gewonnen',
      payin: 'Einzahlung:',
      'payin-time': 'Zahlungszeitpunkt:',
      'payment-time': 'Zeitpunkt der Einzahlung',
      payout: 'Auszahlung:',
      'place-bet': 'Bezahle das Ticket',
      'possible-winning': 'Möglicher Gewinn',
      'pot-winning-amount': 'Potenzielle Auszahlung',
      'pot-winning-amount-short': 'Pot. Auszahlung',
      'potential-bonus': 'Potenzieller Bonus',
      'potential-gain': 'Potentieller Gewinn',
      'quick-ticket-code': 'CODE FÜR SCHNELLTICKET',
      'quick-ticket-live':
        'Es ist nicht möglich, bei Live-Spielen ein Schnellticket zu spielen',
      'quick-ticket-pay':
        'Sie können ein Schnellticket an jeder SBBet-Zahlungsstelle unter dem Code bezahlen:',
      'quick-ticket-terms':
        'Die Konditionen und eventuellen Einschränkungen werden bei der Bezahlung des Tickets an unseren Zahlungsstellen geprüft.',
      'quick-tickets': 'Schnelle Tickets',
      regular: 'REGULÄR',
      'regular-ticket': 'REGULÄRES  TICKET',
      'remove-match': 'Match entfernen',
      'remove-odd': 'Quote entfernen',
      round: 'Runde',
      'save-games': 'Spiele speichern',
      'share-ticket-success': 'Ticketlink erfolgreich kopiert',
      'sign-in-status': 'Melden Sie sich an, um den Ticketstatus anzuzeigen.',
      'slip-not-eligible-for-cashout': 'Das Ticket ist nicht auszahlbar.',
      'slip-payout': 'Auszahlung des Gewinntickets',
      stake: 'Einlage',
      'stake-per-combination': 'Zahlung pro Kombination:',
      status: 'Status',
      'status-capitalize': 'Status:',
      'switch-ticket':
        'Möchten Sie das Ticket in ein reguläres Ticket umwandeln?',
      system: 'SYSTEMISCH',
      'system-bonus-calculation':
        'Die Berechnung des Bonus auf das Systemticket erfolgt nach Durchführung aller Veranstaltungen.',
      ticket: 'TICKET',
      'ticket-date': 'Scheindatum',
      'ticket-details': 'TICKETDETAILS',
      'ticket-id': 'Ticket-ID',
      'ticket-id-search': 'Ticket-ID',
      'ticket-overview': 'Ticketübersicht',
      'ticket-status': 'Ticketstatus',
      'ticket-type': 'Scheintyp',
      tickets: 'TICKETS',
      'top-tickets': 'TOP-TICKETS',
      'total-odd': 'Gesamtquote:',
      unconfirmed: 'Unbestätigt',
      win: 'Gewinn:',
      'winning-amount-caps': 'GEWINN',
    },
    sports: {
      'americki fudbal': 'American Football',
      atletika: 'Athletik',
      badminton: 'Badminton',
      bejzbol: 'Baseball',
      biciklizam: 'Radfahren',
      boks: 'Boxen',
      efudbal: 'E-Fußball',
      esport: 'E-Sport',
      evrovizija: 'Eurovision',
      fudbal: 'Fußball',
      futsal: 'Futsal',
      golf: 'Golf',
      hokej: 'Eishockey',
      košarka: 'Basketball',
      mma: 'MMA',
      odbojka: 'Volleyball',
      olimpijada: 'Olympiade',
      oskar: 'Oskar',
      pikado: 'Darts',
      ragbi: 'Rugby',
      'ragbi liga': 'Rugby League',
      rukomet: 'Handball',
      snuker: 'Snooker',
      'stoni tenis': 'Tischtennis',
      tenis: 'Tennis',
      trke: 'Rennsport',
      'trke konja': 'Pferdrennen',
      vaterpolo: 'Wasserball',
      'virtuelni fudbal': 'Virtueller Fußball',
      'zimski sportovi': 'Wintersport',
    },
    sportsPage: {
      '1d': '1d',
      '1h': '1h',
      '3d': '3d',
      '3h': '3h',
      all: 'ALLES',
      antepost: 'ANTEPOST',
      competition: 'Liga',
      'configure-offer': 'Angebot konfigurieren',
      event: 'Event',
      limit: 'GR',
      live: 'Event',
      'live-match-tracker': ' Das Spiel live verfolgen',
      'no-events': 'Es gibt keine Events',
      'no-odds': 'Derzeit liegen keine Angebote vor',
      odds: 'QUOTEN',
      player: 'SPIELER',
      'sort-by-competition': 'Nach Ligen',
      'sort-by-time': 'Nach Termin',
      special: 'SPEZIAL',
      upcoming: 'NÄCHSTE',
    },
    userData: {
      address: 'Adresse',
      adress: 'Adresse',
      'bank-account-number': 'Kontonummer',
      'bank-name': 'Bankname',
      'betting-win-loss': 'Betting win/loss',
      'casino-and-virtuals-win-loss': 'Casino und virtueller winn/loss',
      'change-password': 'Kennwort ändern',
      'changes-active-in-24-hrs': 'Ihre Änderungen werden in 24 Stunden aktiv',
      city: 'Stadt',
      code: 'Code',
      'confirm-password': 'Bestätige das Passwort',
      country: 'Land',
      'current-password': 'Aktuelles Passwort',
      'current-spending': 'Aktueller Verbrauch',
      'daily-limits': 'Tageslimits',
      'date-of-birth': 'Geburtsdatum',
      deposit: 'Kaution',
      'document-id': 'Reisepass-ID',
      documents: 'Dokumente',
      'driver-license': 'Führerschein',
      'email-address': 'E-Mail-Adresse',
      'email-adress': 'E-Mail-Adresse',
      female: 'Weiblich',
      'first-name': 'Name',
      gender: 'Geschlecht',
      'i-have-jmbg': 'Ich habe Personalcode (nur für Bürger Montenegros)',
      jmbg: 'Personalcode',
      'last-name': 'Nachname',
      male: 'Männlich',
      'monthly-limits': 'Monatliche Limits',
      'new-limit-value-is': 'Der neue Grenzwert beträgt',
      'new-limits-take-effect-in':
        'Die neuen Beschränkungen treten in Kraft in',
      'new-password': 'Neues Passwort',
      passport: 'Reisepass',
      password: 'Code',
      'pause-gambling-confirmation':
        'Dadurch werden Sportwetten, Casino und virtuelle Wetten bis zum ausgewählten Datum deaktiviert. Sind Sie sicher, dass Sie fortfahren möchten?',
      'pause-gambling-until': 'Pausieren des Wettens bis',
      'personal-data': 'Personenbezogene Daten',
      'personal-id': 'Personalausweis',
      phone: 'Telefon',
      place: 'Ort',
      'responsible-gambling': 'Verantwortungsvolles Wetten',
      save: 'Speichern',
      'street-and-number': 'Straße und Nummer',
      telephone: 'Telefon',
      'user-details': 'Personenbezogene Daten',
      username: 'Benutzername',
      'weekly-limits': 'Wöchentliche Limits',
    },
    userMarket: {
      cancel: 'Stornieren',
      code: 'Code',
      doctor: 'Doktor',
      expert: 'Experte',
      items: 'Artikel',
      order: 'Bestellung',
      'order-success': 'Erfolgreiche Bestellung',
      place: 'Ort',
      professor: 'Professor',
      'sbbet-points': 'SBBet-Punkte',
      'successfuly-canceled-order':
        'Sie haben Ihre Bestellung erfolgreich storniert',
    },
    validations: {
      'amount-greater-than-available':
        'Der eingegebene Betrag ist größer als verfügbar',
      'cannot-mix-group-events':
        'Es ist nicht möglich, mit demselben Ticket Gruppenevents und Events aus der Gruppe zu spielen',
      'event-disabled': 'Wetten auf das Event: {{a}} sind nicht möglich.',
      'event-starts-in-less-than':
        'Event {{a}} beginnt in weniger als {{b}} Sekunden',
      'free-bet-error':
        'Die Mindestanzahl an Events auf einem Free Bet Tuicket beträgt {{minEvents}}. Die Mindestgesamtquote beträgt {{minTotalOdd}}.',
      'free-bet-unvalidated':
        'Bitte verifizieren Sie Ihr Konto, um die Aktionsgelder nutzen zu können.',
      'live-odds-change': 'Bitte akzeptieren Sie alle Änderungen.',
      'max-bet-amount': 'Maksimalna uplata je {{a}}',
      'max-credit': 'Die maximale Auszahlung beträgt {{max}} €',
      'max-debit': 'Die maximale Einzahlung beträgt {{max}}',
      'max-number-of-combinations':
        'Die maximale Anzahl an Kombinationen beträgt {{a}}',
      'max-number-of-events': 'Die maximale Anzahl von Events beträgt {{a}}',
      'max-number-of-same-events':
        'Die maximale Anzahl an Integralen beträgt {{a}}',
      'max-same-slips': 'Sie haben {{a}} desselben Tickets gespielt.',
      'min-amount-per-combination':
        'Die Mindestzahlung pro Kombination beträgt {{a}}',
      'min-credit': 'Die Mindestauszahlung beträgt {{min}} €',
      'min-debit': 'Die Mindesteinzahlung beträgt {{min}}',
      'min-payment-for-number-of-matches':
        'Die Mindestauszahlung für eine Anzahl von Events unter {{b}} beträgt {{a}}',
      'no-space': 'Es sind keine Leerzeichen erlaubt',
      'odd-disabled': 'Wetten auf die Quote {{a}} ist nicht möglich.',
      'odd-higher-than-one': 'Alle Quoten müssen größer als 1 sein.',
      'only-letters-and-spaces-allowed':
        'Nur Buchstaben und Leerzeichen sind erlaubt',
      'only-numbers-allowed': 'Nur Zahlen sind erlaubt',
      'repeat-password': 'Wiederholen Sie das Passwort',
      'validation-antepost-system':
        'Das Spielen von Antepost-Events ist mit einem Systemticket nicht möglich.',
      'validation-balance': 'Das Guthaben auf Ihrem Konto reicht nicht aus.',
      'validation-max-payment':
        'Die Einzahlung ist höher als die festgelegte Höchsteinzahlung.',
      'validation-max-systems':
        'Die maximale Anzahl von Systemen beträgt {{a}}',
      'validation-max-win': 'Der maximale potenzielle Gewinn beträgt {{a}}',
      'validation-min-payment':
        'Die Mindesteinzahlung beträgt {{minAmount}} EUR',
      'validation-mix-long-short-term':
        'Es ist nicht möglich, das Antepost-Event {{a}} und das Kurzzeit-Event {{b}} mit demselben Ticket zu spielen.',
      'validation-mixed-event-types':
        'Es ist nicht möglich, Antepost-Events in Kombination mit anderen Event-Typen zu spielen',
      'validation-mixed-markets':
        'Es ist nicht möglich, Antepost Events {{a}} mit anderen Events-Typen {{b}} mit demselben Ticket zu spielen.',
      'validation-not-validated':
        'Ein nicht verifizierter Benutzer kann keine Auszahlung vornehmen.',
      'validation-only-singles': 'Event {{a}} kann nur Single gespielt werden.',
      'validation-single-choice':
        'Event {{a}} darf nicht mehre Zeichen enthalten.',
    },
    verification: {
      'accept-terms': 'Ich akzeptiere die Nutzungsbedingungen',
      'account-activated': 'Das Konto wurde erfolgreich aktiviert',
      'account-success': 'Sie haben Ihr Konto erfolgreich aktiviert!',
      'agree-email-notif': 'Ich möchte Benachrichtigungen per E-Mail erhalten',
      'agree-sms-notif': 'Ich möchte Benachrichtigungen per SMS erhalten',
      confirm: 'BESTÄTIGE',
      'connection-online': 'Internetverbindung hergestellt!',
      'data-saved': 'Daten erfolgreich gespeichert',
      'deny-changes': 'Sie haben Änderungen am Ticket erfolgreich abgelehnt.',
      'deny-changes-timer':
        'Änderungen wurden aufgrund einer Zeitüberschreitung beim Akzeptieren von Änderungen abgelehnt.',
      'email-verified-success':
        'Sie haben Ihr E-Mail-Konto erfolgreich verifiziert',
      'enter-recovery-email':
        'Geben Sie die E-Mail-Adresse ein, mit der Sie das Konto erstellt haben.',
      instruction:
        'Senden Sie eine SMS-Nachricht an die Nummer 1818 im Format: SbbIDnumberEinzahlungsbetrag',
      'login-activation': 'Melden Sie sich an, um die App zu nutzen',
      'promo-code-activated':
        'Sie haben den Promo-Code erfolgreich aktiviert. Sie haben {{amount}} {{currency}} auf Ihrem Promo-Konto erhalten.',
      'promo-code-activated-multiple-wallets':
        'Sie haben den Promo-Code erfolgreich aktiviert. Sie haben  auf Ihrem Gratiswetten-Konto {{amount}} {{currency}} und auf Ihrem Gratis-Spin-Konto {{amount_fc}} {{currency}} erhalten.',
      'promo-code-already-activated': 'Der Promo-Code wurde bereits aktiviert',
      'promo-code-not-for-existing-users':
        'Der Promo-Code ist nicht für alte Benutzer gedacht',
      'promo-code-not-for-new-users':
        'Der Promo-Code ist nicht für neue Benutzer gedacht',
      'promo-code-used-up': 'Der Promo-Code wurde bereits verwendet',
      'recovery-email-sent':
        'Wir haben Ihnen eine E-Mail an Ihre E-Mail-Adresse gesendet',
      'successfully-changed-password':
        'Sie haben Ihr Passwort erfolgreich geändert',
      'ticket-approved':
        'hr Ticket wurde genehmigt. Klicken Sie hier, um das Ticket anzuzeigen.',
      'ticket-paid': 'Das Ticket wurde erfolgreich bezahlt.',
      'ticket-waiting-approval': 'Ihr Ticket wird genehmigt.',
      'upload-document': {
        'choose-document': 'Wählen Sie ein Dokument aus',
        'document-type': 'Art des Validierungsdokuments:',
        'first-page': 'Vorderseite/erste Seite des Dokuments:',
        'second-page': 'Rückseite/zweite Seite des Dokuments:',
        submit: 'Senden',
        title: 'Dokument wird geladen',
        'upload-first-page':
          'Laden Sie die Vorderseite/erste Seite des Dokuments',
        'upload-second-page':
          'Legen Sie die Rückseite/zweite Seite des Dokuments ein',
        'user-update-success': 'Daten erfolgreich gespeichert',
      },
      'validation-18-year': 'Sie sind noch keine 18 Jahre alt.',
      'verification-complete':
        'Bestätigung der Bestellung wurde erfolgreich abgeschlossen',
      'verification-in-progress': 'Bestätigun läuft',
      'verification-modal': {
        activate: 'Bestetigung der Bestellung',
        desc: 'Sie können Ihre Bestellung jederzeit auf Ihrer Kontoseite im Bereich Mein Konto > KONTO > Dokumente bestätigen',
        'doc-uploaded': 'Bestätigun läuft',
        Name: 'Bezeichnung',
        proceed: 'Ohne Bestätigung fortfahren',
        validate: 'Benutzerbestätigung',
      },
    },
    wallet: {
      'activate-bonus-info':
        'Aktivieren Sie einen Promo-Bonus in Höhe von {{bonus}} EUR',
      'casino-bonus-info':
        'Sie müssen weitere {{amountToUnlock}} EUR ausgeben, um den Casino-Bonus freizuschalten',
      'casino-to-unlock': 'Casino-Bonus beim Freischalten',
      'free-bet': 'Free Bet',
      'free-bet-bonus': 'Free bet bonus',
      'free-bet-bonus-info':
        'Sie müssen weitere {{amountToUnlock}} EUR ausgeben, um Free Bet freizuschalten',
      'free-bet-bonus-to-unlock': 'Free Bonus beim Freischalten',
      'free-bet-bonus-unlock-message':
        'Sie müssen weitere {{amountToUnlock}} EUR ausgeben, um den Gratiswettbonus freizuschalten',
      'free-spin': 'Free Spin',
      'free-spin-to-unlock': 'Free Spin beim Freischalten',
      'free-spin-unlock-message':
        'Sie müssen weitere {{amountToUnlock}} EUR ausgeben, um Free Spin freizuschalten',
      'games-ticket-payout-info': 'Möchten Sie das nächste Ticket auszahlen?',
      'payout-success': 'Die Auszahlung wurde erfolgreich getätigt.',
      'poker-balance': 'Poker-Kontostand',
      'promo-bill': 'Promo-Konto',
      'promo-bonus': 'Promo-Bonus',
      'promo-to-unlock': 'Promo beim Freischalten',
      'reserved-funds': 'Reservierte Mittel',
      'standard-account': 'Standardkonto',
      'standard-bill': 'Kontostand',
      'submit-for-print-success': 'Erfolgreich zum Drucken eingereicht',
      'ticket-successfully-paid': 'Das Ticket wurde erfolgreich eingezahlt',
      'valid-to': 'Gültig bis {{validTo}}',
      'voucher-payout-info': 'Möchten Sie den nächsten GUTSCHEIN auszahlen?',
      'voucher-print': 'GUTSCHEIN AUSDRUCKEN',
      'voucher-print-confirm': 'Möchten Sie weiter machen?',
      'voucher-print-info':
        'Alle Gelder in der Bilanz werden entfernt und ein GUTSCHEIN im gleichen Wert wird ausgedruckt.',
      wallet: 'Konto',
      'wallet-locked': 'Das Konto ist gesperrt',
      'wallets-refresh': 'Aktualisiere Geldbeutel-Informationen',
    },
  },
};

export default de;
