// MARKETS AND OUTCOMES
interface Outcome {
  shortDesc: string;
  id: number;
  name: string;
  sport: any;
  exeLimit: string;
  outcomeTic: string;
  outcomeGroup: string;
}

interface MarketBase {
  id: number;
  name: string;
  englishName: string;
  albanianName: string;
  turkishName: string;
  russianName: string;
  ukrainianName: string;
  italianName: string;
  germanName: string;
}

interface Market extends MarketBase {
  sportId: number;
  outcomesList: Outcome[];
}

interface MarketGroup extends MarketBase {
  order: number;
  marketsList: Market[];
  oddsByMarket?: OddsByMarket[];
}

interface OddsByMarket {
  market: string;
  odds: Odd[];
}

interface Odd {
  oddName: string;
  odd: string;
  outcomeId: number;
  market: string;
  group: string;
  isDisabled: boolean;
  limit?: number;
}

// SPORTS
interface Competition {
  id: number;
  name: string;
  shortName: string;
  isFavorite: boolean;
  competitionOrder: number;
  locationOrder: number;
}

interface Location {
  competitionsList: Competition[];
  competitionMap: Map<number, Competition>;
  id: number;
  name: string;
}

interface Sport {
  id: number;
  locationsList: Location[];
  locationMap: Map<number, Location>;
  name: string;
}

enum OddStatuses {
  NOT_RESOLVED = 1,
  FAILED = 2,
  PASSED = 3,
  CANCELED = 4,
  PASSED_HALF = 5,
  FAILED_HALF = 6,
  REVERT_BY_PROVIDER = 7,
}

enum OddStringStatuses {
  NOT_RESOLVED = '__NOT_RESOLVED__',
  FAILED = '__FAILED__',
  PASSED = '__PASSED__',
}

type OddStatus =
  | OddStatuses.NOT_RESOLVED
  | OddStatuses.PASSED
  | OddStatuses.PASSED_HALF
  | OddStatuses.FAILED
  | OddStatuses.FAILED_HALF
  | OddStatuses.CANCELED
  | OddStatuses.REVERT_BY_PROVIDER;

// EVENTS AND ODDS
interface EventOdd {
  id: number;
  outcomeId: number;
  value: number;
  isDisabled: boolean;
  frameNumber: number;
  livePlayer: string;
  status: OddStatus;
  limit?: number;
}

interface EventStart {
  nanos: number;
  seconds: number;
}
interface Event {
  competitionId: number;
  id: string;
  locationId: number;
  name: string;
  oddsList: EventOdd[];
  sportId: number;
  sportName: string;
  locationName: string;
  start: EventStart;
  type: string;
  teamShortName: string;
  eventCode: number;
  result: any;
  detailedResult: any;
  isDisabled: boolean;
  exefeedId: string;
  betradarId: string;
  profile: number;
  competition: any;
}

enum EVENT_TYPE {
  LIVE = 'live',
  UPCOMING = 'upcoming',
  ANTEPOST = 'antepost',
  PLAYER = 'player',
  SPECIAL = 'special',
}

type EventType =
  | EVENT_TYPE.LIVE
  | EVENT_TYPE.UPCOMING
  | EVENT_TYPE.ANTEPOST
  | EVENT_TYPE.PLAYER
  | EVENT_TYPE.SPECIAL;

export { OddStatuses, OddStringStatuses, EVENT_TYPE };
export type {
  Outcome,
  Market,
  MarketGroup,
  OddsByMarket,
  Odd,
  Competition,
  Location,
  Sport,
  OddStatus,
  EventOdd,
  EventStart,
  Event,
  EventType,
};
