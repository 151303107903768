/* React modules */
import { useEffect, useState, useCallback } from 'react';

/* Our modules */
import { PaymentMethods } from 'modules/payment/payment.types';
import useStores from 'common/hooks/useStores';
import PaymentService from 'modules/payment/payment.service';
import PaymentResponse from 'modules/payment/payment-response.service';
import { initMonriForm } from 'modules/payment/ui/monri';
import useQuery from 'libs/useQuery';
import { parseJSONString } from 'libs/common-helpers';
import './PaymentMonriPlaceholder.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const PaymentMonriPlaceholder = observer(() => {
  const { t } = useTranslation();
  const { authStore } = useStores();
  const [error, setError] = useState('');
  const [responseText, setResponseText] = useState('');

  const paymentService = new PaymentService();
  let queryParams = useQuery();

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  const handleBadParams = () => {
    setError(t('system-error'));
  };

  const forwardButton = () => {
    // @ts-ignore
    const btn: HTMLButtonElement = document.getElementsByClassName(
      'monri-lightbox-button-el'
    )[0];
    if (btn) {
      btn.click();
      btn.style.visibility = 'hidden';
    }
  };

  const init = async (token: string | null, amount: string) => {
    if (token) {
      authStore.setToken(token);
    }

    const depositData = await paymentService.initDeposit(
      +amount,
      PaymentMethods.MONRI
    );

    initMonriForm(
      document.getElementById('monri-payment-form-mobile'),
      depositData,
      forwardButton
    );
  };

  const getResponseData = useCallback(() => {
    const transactionResponse = queryParams.get('transaction_response');

    if (transactionResponse) {
      return parseJSONString(transactionResponse);
    }
  }, [queryParams]);

  useEffect(() => {
    if (queryParams) {
      const responseData = getResponseData();
      if (responseData) {
        const responseHandler = new PaymentResponse(
          PaymentMethods.MONRI,
          responseData
        );
        responseHandler.handle(
          (messageKey: string) => {
            setResponseText(t(messageKey));
          },
          (messageKey: string) => setResponseText(t(messageKey))
        );
        setError('');
      }
    }
  }, [queryParams, getResponseData, t]);

  useEffect(() => {
    const token = getParam('token') || '';
    const amount = getParam('amount');

    if (amount && token) {
      init(token, amount);
    } else {
      handleBadParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="payment-monri-placeholder">
      <form id="monri-payment-form-mobile"></form>

      {responseText && (
        <div className="payment-monri-placeholder__response-text">
          {responseText}
        </div>
      )}

      {error && <div className="payment-monri-placeholder__error">{error}</div>}
    </div>
  );
});

export default PaymentMonriPlaceholder;
