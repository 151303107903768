/* React modules */

/* Our modules */
import {
  MONRI_DEFAULT_ERROR,
  MONRI_RESPONSE_MESSAGES,
  MONRI_SUCCESS_STATUSES,
} from 'modules/payment/payment.constants';
import { PaymentMethod, PaymentMethods } from 'modules/payment/payment.types';

/* 3rd Party modules */

enum ResponseTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}

type ResponseType = ResponseTypes.ERROR | ResponseTypes.SUCCESS;

type ResponseHandler = (message: string) => void;

class PaymentResponse {
  private method: PaymentMethod = PaymentMethods.MONRI;
  private type: ResponseType | null = null;
  private message: string = '';

  constructor(method: PaymentMethod, data: any) {
    this.method = method;
    this.setResponseData(data);
  }

  private setResponseData(data: any) {
    if (this.method === PaymentMethods.MONRI) {
      this.setMonriResponseData(data);
    }

    console.error('Payment method does not exists.');
  }

  setMonriResponseData(data: any) {
    const { response_code } = data;

    if (!response_code) {
      this.message = MONRI_DEFAULT_ERROR;
      this.type = ResponseTypes.ERROR;
    }

    this.type = MONRI_SUCCESS_STATUSES.includes(response_code)
      ? ResponseTypes.SUCCESS
      : ResponseTypes.ERROR;

    this.message =
      MONRI_RESPONSE_MESSAGES[response_code] || MONRI_DEFAULT_ERROR;
  }

  handle(onSuccess: ResponseHandler, onError: ResponseHandler) {
    if (this.type === ResponseTypes.SUCCESS) {
      onSuccess(this.message);
    }

    if (this.type === ResponseTypes.ERROR) {
      onError(this.message);
    }
  }
}

export default PaymentResponse;
