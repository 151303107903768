/* React modules */
import { ReactChildren } from 'react';

/* Our modules */
import * as icons from 'components/Icon/Icons';
import './Icon.scss';

/* 3rd Party modules */
import Tooltip from 'rc-tooltip';

const ICONS: any = {
  check: icons.Check,
  times: icons.Times,
  cart: icons.Cart,
  cartGreen: icons.CartGreen,
  user: icons.User,
  userFill: icons.UserFill,
  info: icons.Info,
  minus: icons.Minus,
  caretDown: icons.CaretDown,
  caretDownFill: icons.CaretDownFill,
  caretUp: icons.CaretUp,
  caretRight: icons.CaretRight,
  caretLeft: icons.CaretLeft,
  bolt: icons.Bolt,
  cancel: icons.Cancel,
  bars: icons.Bars,
  magnifyingGlassDark: icons.MagnifyingGlassDark,
  mailFill: icons.MailFill,
  caretRightCasino: icons.CaretRightCasino,
  caretLeftCasino: icons.CaretLeftCasino,
  caretUpCasino: icons.CaretUpCasino,
  caretDownCasino: icons.CaretDownCasino,
  arrowRight: icons.ArrowRight,
  arrowLeft: icons.ArrowLeft,
  angleRight: icons.AngleRight,
  angleLeft: icons.AngleLeft,
  circleRight: icons.CircleRight,
  circleLeft: icons.CircleLeft,
  signIn: icons.SignIn,
  bell: icons.Bell,
  sort: icons.Sort,
  trophy: icons.Trophy,
  removeEvent: icons.RemoveEvent,
  stats: icons.Stats,
  clock: icons.Clock,
  print: icons.Print,
  lock: icons.Lock,
  lockSmall: icons.LockSmall,
  phone: icons.Phone,
  lockFill: icons.LockFill,
  magnifyingGlass: icons.MagnifyingGlass,
  logout: icons.Logout,
  calendar: icons.Calendar,
  calendarFill: icons.CalendarFill,
  timeZone: icons.TimeZone,
  live: icons.Live,
  liveGreen: icons.LiveGreen,
  animatedLiveGreen: icons.AnimatedLiveGreen,
  trashCan: icons.TrashCan,
  quickTicket: icons.QuickTicket,
  fastForward: icons.FastForward,
  creditDebit: icons.CreditDebit,
  activation: icons.Activation,
  promoCode: icons.PromoCode,
  promoCodeFill: icons.PromoCodeFill,
  userSettings: icons.UserSettings,
  ticketOverview: icons.TicketOverview,
  ticket: icons.Ticket,
  calendarMobile: icons.CalendarMobile,
  configuration: icons.Configuration,
  transactions: icons.Transactions,
  wallet: icons.Wallet,
  walletSkrill: icons.WalletSkrill,
  formatOdds: icons.FormatOdds,
  signup: icons.Signup,
  pen: icons.Pen,
  search: icons.Search,
  searchGreen: icons.SearchGreen,
  baseball: icons.Baseball,
  basketball: icons.Basketball,
  badminton: icons.Badminton,
  handball: icons.Handball,
  waterpolo: icons.Waterpolo,
  hockey: icons.Hockey,
  race: icons.Race,
  rugby: icons.Rugby,
  golf: icons.Golf,
  football: icons.Football,
  eFootball: icons.EFootbal,
  voleyball: icons.Voleyball,
  snooker: icons.Snooker,
  tennis: icons.Tennis,
  tableTennis: icons.TableTennis,
  pikado: icons.Pikado,
  esports: icons.Esports,
  sports: icons.Sports,
  mobileSports: icons.MobileSports,
  mobileCasino: icons.MobileCasino,
  mobileCasinoLive: icons.MobileCasinoLive,
  mobileNumbers: icons.MobileNumbers,
  mobileTicket: icons.MobileTicket,
  americanFootball: icons.AmericanFootball,
  threeOnThree: icons.ThreeOnThree,
  atp: icons.ATP,
  formula: icons.Formula,
  horseRaces: icons.HorseRaces,
  olympicGames: icons.OlypmicGames,
  oscars: icons.Oscars,
  cycling: icons.Cycling,
  mma: icons.MMA,
  winterSports: icons.WinterSports,
  wta: icons.WTA,
  externalLink: icons.ExternalLink,
  angleDoubleRight: icons.AngleDoubleRight,
  facebook: icons.Facebook,
  instagram: icons.Instagram,
  twitter: icons.Twitter,
  information: icons.Information,
  city: icons.City,
  building: icons.Building,
  boxing: icons.Boxing,
  athletics: icons.Athletics,
  futsal: icons.Futsal,
  eurovision: icons.Eurovision,
  reload: icons.Reload,
  offer: icons.Offer,
  badgeProfessor: icons.BadgeProfessor,
  badgeExpert: icons.BadgeExpert,
  badgeDoctor: icons.BadgeDoctor,
  starSelected: icons.StarSelected,
  starUnselected: icons.StarUnselected,
  gifts: icons.Gifts,
  phoneLocation: icons.PhoneLocation,
  eye: icons.Eye,
  instagram2: icons.Instagram2,
  facebook2: icons.Facebook2,
  linkedIn: icons.LinkedIn,
  youTube: icons.YouTube,
  callCenter: icons.CallCenter,
  share: icons.Share,
};

export const SPORT_ICONS: any = {
  košarka: 'basketball',
  fudbal: 'football',
  efudbal: 'eFootball',
  tenis: 'tennis',
  rukomet: 'handball',
  hokej: 'hockey',
  'zimski sportovi': 'winterSports',
  odbojka: 'voleyball',
  'stoni tenis': 'tableTennis',
  'americki fudbal': 'americanFootball',
  snuker: 'snooker',
  'speciali basket': 'basketball',
  'virtuelni fudbal': 'football',
  badminton: 'badminton',
  vaterpolo: 'waterpolo',
  pikado: 'pikado',
  esport: 'esports',
  golf: 'golf',
  'ragbi liga': 'rugby',
  ragbi: 'rugby',
  bejzbol: 'baseball',
  biciklizam: 'cycling',
  oskar: 'oscars',
  'trke konja': 'horseRaces',
  mma: 'mma',
  boks: 'boxing',
  atletika: 'athletics',
  futsal: 'futsal',
  evrovizija: 'eurovision',
  trke: 'race',
};

enum ICONS_ENUM {
  check = 'check',
  times = 'times',
  cart = 'cart',
  cartGreen = 'cartGreen',
  user = 'user',
  userFill = 'userFill',
  minus = 'minus',
  info = 'info',
  caretDown = 'caretDown',
  caretDownFill = 'caretDownFill',
  caretUp = 'caretUp',
  caretLeft = 'caretLeft',
  caretRight = 'caretRight',
  circleRight = 'circleRight',
  bolt = 'bolt',
  caretRightCasino = 'caretRightCasino',
  caretLeftCasino = 'caretLeftCasino',
  caretUpCasino = 'caretUpCasino',
  caretDownCasino = 'caretDownCasino',
  cancel = 'cancel',
  phone = 'phone',
  bars = 'bars',
  calendarFill = 'calendarFill',
  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft',
  angleRight = 'angleRight',
  angleLeft = 'angleLeft',
  signIn = 'signIn',
  bell = 'bell',
  formatOdds = 'formatOdds',
  trophy = 'trophy',
  stats = 'stats',
  magnifyingGlassDark = 'magnifyingGlassDark',
  clock = 'clock',
  sort = 'sort',
  print = 'print',
  lock = 'lock',
  lockSmall = 'lockSmall',
  lockFill = 'lockFill',
  fastForward = 'fastForward',
  logout = 'logout',
  calendar = 'calendar',
  timeZone = 'timeZone',
  magnifyingGlass = 'magnifyingGlass',
  live = 'live',
  liveGreen = 'liveGreen',
  animatedLiveGreen = 'animatedLiveGreen',
  removeEvent = 'removeEvent',
  activation = 'activation',
  wallet = 'wallet',
  walletSkrill = 'walletSkrill',
  signup = 'signup',
  ticketOverview = 'ticketOverview',
  ticket = 'ticket',
  calendarMobile = 'calendarMobile',
  configuration = 'configuration',
  mobileSports = 'mobileSports',
  mobileCasino = 'mobileCasino',
  mobileCasinoLive = 'mobileCasinoLive',
  mobileNumbers = 'mobileNumbers',
  mobileTicket = 'mobileTicket',
  promoCode = 'promoCode',
  promoCodeFill = 'promoCodeFill',
  userSettings = 'userSettings',
  quickTicket = 'quickTicket',
  mailFill = 'mailFill',
  creditDebit = 'creditDebit',
  transactions = 'transactions',
  pen = 'pen',
  search = 'search',
  searchGreen = 'searchGreen',
  baseball = 'baseball',
  basketball = 'basketball',
  futsal = 'futsal',
  badminton = 'badminton',
  handball = 'handball',
  hockey = 'hockey',
  cycling = 'cycling',
  mma = 'mma',
  golf = 'golf',
  trashCan = 'trashCan',
  football = 'football',
  eFootbal = 'eFootball',
  waterpolo = 'waterpolo',
  snooker = 'snooker',
  tennis = 'tennis',
  tableTennis = 'tableTenis',
  pikado = 'pikado',
  esports = 'esports',
  sports = 'sports',
  americanFootball = 'americanFootball',
  threeOnThree = 'threeOnThree',
  atp = 'atp',
  formula = 'formula',
  horseRaces = 'horseRaces',
  trke = 'race',
  rugby = 'rugby',
  olympicGames = 'olympicGames',
  oscars = 'oscars',
  boxing = 'boxing',
  athletics = 'athletics',
  winterSports = 'winterSports',
  wta = 'wta',
  externalLink = 'externalLink',
  angleDoubleRight = 'angleDoubleRight',
  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter',
  information = 'information',
  city = 'city',
  building = 'building',
  reload = 'reload',
  offer = 'offer',
  badgeProfessor = 'badgeProfessor',
  badgeExpert = 'badgeExpert',
  badgeDoctor = 'badgeDoctor',
  starSelected = 'starSelected',
  starUnselected = 'starUnselected',
  gifts = 'gifts',
  phoneLocation = 'phoneLocation',
  eye = 'eye',
  instagram2 = 'instagram2',
  facebook2 = 'facebook2',
  linkedIn = 'linkedIn',
  youTube = 'youTube',
  callCenter = 'callCenter',
  share = 'share',
}

export type IconName = keyof typeof ICONS_ENUM;

export type IconVariant =
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark'
  | 'light'
  | 'circle-gray'
  | 'grey'
  | 'lightgrey'
  | 'silver'
  | 'sbgreen'
  | 'promo'
  | 'gambling'
  | 'lightgreen'
  | 'sbgreen-800'
  | 'sbgrey';

interface IconContainerProps {
  children: ReactChildren;
  container: IconVariant;
  name: IconName;
}

export interface IconProps {
  name: IconName;
  size:
    | 'regular'
    | 'medium'
    | 'small'
    | 'extra-small'
    | 'large'
    | 'extra-large'
    | '2-extra-large';
  container?: IconVariant;
  variant?: IconVariant;
  onClick?: () => void;
  className?: string;
  tooltipPosition?: string;
  tooltip?: string;
  isDisabled?: boolean;
  style?: any;
}

const IconContainer = ({ children, container, name }: IconContainerProps) => (
  <div
    data-testid={`icon-container-${name}`}
    className={`icon-container bg-${container}`}
  >
    {children}
  </div>
);

const Icon = ({
  onClick,
  container,
  name,
  size,
  variant,
  className,
  tooltip,
  tooltipPosition,
  isDisabled,
  style,
}: IconProps) =>
  tooltip ? (
    <Tooltip
      placement={tooltipPosition}
      overlayClassName="tooltip"
      overlay={tooltip}
    >
      <div
        onClick={isDisabled ? undefined : onClick}
        data-testid={name}
        className={`icon icon-${size} icon-${variant} ${className} ${
          isDisabled ? 'disabled' : ''
        }`}
        style={style}
      >
        {container ? (
          <IconContainer container={container} name={name as IconName}>
            {ICONS[name]}
          </IconContainer>
        ) : (
          ICONS[name]
        )}
      </div>
    </Tooltip>
  ) : (
    <div
      onClick={isDisabled ? undefined : onClick}
      data-testid={name}
      className={`icon icon-${size} icon-${variant} ${className} ${
        isDisabled ? 'disabled' : ''
      }`}
      style={style}
    >
      {container ? (
        <IconContainer container={container} name={name as IconName}>
          {ICONS[name]}
        </IconContainer>
      ) : (
        ICONS[name]
      )}
    </div>
  );

Icon.defaultProps = {
  size: 'regular',
  variant: 'light',
  className: '',
  tooltipPosition: 'bottom',
  isDisabled: false,
  style: {},
};

export { Icon };
